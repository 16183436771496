import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';

import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles';
import { StyleType } from '@/uiKit/StyleTypes';

type FilePreviewType = {
  name: string;
  idx: number;
  onClick: (i: number) => void;
  deletable?: boolean;
  className?: string;
};

export const FilePreview = ({
  name,
  idx,
  onClick,
  className,
}: FilePreviewType & StyleType) => {
  const styles = useStyles(makeStyles);

  const handleRemove = () => {
    onClick(idx);
  };

  return (
    <div className={cx(styles.container, className)}>
      <Icons.trash onClick={handleRemove} className={styles.icon}></Icons.trash>
      <Typography variant="body" className={styles.name}>
        {name}
      </Typography>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: inline-flex;
    padding-left: ${theme.spacing(4)};
    align-items: center;
  `,
  name: css`
    padding-left: ${theme.spacing(4)};
    white-space: nowrap;
    word-break: break-all;
    overflow: hidden;
    text-overflow: clip;
  `,
  icon: css`
    cursor: pointer;
  `,
});

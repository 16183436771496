import { stringUnion } from '@/utils/decoderUtils';

export type Profession = 'doctor' | 'endocrinologist' | 'nurse';

export const professionsList: Profession[] = [
  'doctor',
  'endocrinologist',
  'nurse',
];

export const professionDecoder = stringUnion<Profession>(...professionsList);

export const ProfessionsTranslations = {
  doctor: 'profile.doctor',
  endocrinologist: 'profile.diabetologist',
  nurse: 'profile.nurse',
} as const satisfies Record<Profession, string>;

import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';
import { CommentType } from '@/models/BGMLogbook';

import { ActivityComment } from './ActivityComment';
import { EventComment } from './EventComment';
import { MealComment } from './MealComment';

export const PrettyComment = ({ comment }: { comment: CommentType }) => {
  const styles = useStyles(makeStyles);
  return (
    <div className={styles.container}>
      {comment.food.map((foodDetail, i) => (
        <MealComment key={i} mealComment={foodDetail} />
      ))}
      {comment.activity.map((activity, i) => (
        <ActivityComment key={i} activityComment={activity}></ActivityComment>
      ))}
      {comment.event.map((event, i) => (
        <EventComment key={i} eventComment={event}></EventComment>
      ))}
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    padding-left: ${theme.spacing(4)};
    align-items: flex-start;
  `,
});

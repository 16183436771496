import {
  DecoderFunction,
  array,
  boolean,
  field,
  intersection,
  number,
  record,
  union,
} from 'typescript-json-decoder';
import { fieldDecoder } from 'typescript-json-decoder/dist/literal-decoders';
import { Decoder, decodeType } from 'typescript-json-decoder/dist/types';
import { tag } from 'typescript-json-decoder/dist/utils';

type intersectUnion<U> = (U extends unknown ? (_: U) => void : never) extends (
  _: infer I,
) => void
  ? I
  : never;
type asObject<T extends unknown[]> = {
  [K in Exclude<keyof T, keyof []>]: {
    _: decodeType<T[K]>;
  };
};
type values<T> = T[keyof T];
type fromObject<T> = T extends {
  _: infer V;
}
  ? V
  : never;
type getProductOfDecoderArray<arr extends Decoder<unknown>[]> =
  fromObject<intersectUnion<values<asObject<arr>>>> extends infer P
    ? {
        [K in keyof P]: P[K];
      }
    : never;

export const stringUnion = <T extends string = ''>(...values: T[]) =>
  union(...values) as unknown as DecoderFunction<T>;

export const stringMapping =
  <T extends string, U extends string>(mapping: Record<T, U>) =>
  (value: unknown): U => {
    const unionValues = Object.keys(mapping);
    const key: T = stringUnion(...unionValues)(value);
    return mapping[key];
  };

export const stringUnionMapUndef =
  <T extends string = ''>(undefinedValue: string, ...values: T[]) =>
  (value: unknown) => {
    if (typeof value === 'string' && value === undefinedValue) {
      return undefined;
    } else {
      return stringUnion(...values)(value);
    }
  };

export const withDefaultValue =
  <T>(decoder: DecoderFunction<T>, defaultValue: T) =>
  (value: unknown) => {
    if (value === undefined || value === null) {
      return defaultValue;
    }
    return decoder(value);
  };

export const nullOrUndef =
  <T>(decoder: DecoderFunction<T>): DecoderFunction<T | undefined> =>
  (value: unknown) => {
    if (value === null || value === undefined) {
      return undefined;
    }
    return decoder(value);
  };

export const decodeId: DecoderFunction<string> = (value: unknown) =>
  number(value).toString(10);

export const deepField = <T>(
  key: string,
  decoder: DecoderFunction<T>,
): DecoderFunction<T> => {
  const newDecoder = (value: unknown): T => {
    const keys = key.split('.');
    // Finds the value at path a.b.c in the object { a: { b: { c: value } } }
    const parsedValue = keys.reduce((acc, key) => {
      // Get the value at the current key with no decoding
      return field(key, v => v)(acc);
    }, value);

    return decoder(parsedValue);
  };
  tag(newDecoder, fieldDecoder);
  return newDecoder;
};

export const forcedBoolean = (value: unknown) =>
  value !== undefined && value !== null && boolean(value);

export const recordWithContext =
  <
    schema extends {
      [key: string]: Decoder<unknown>;
    },
  >(
    context: string,
    s: schema,
  ): DecoderFunction<decodeType<schema>> =>
  (value: unknown) => {
    try {
      return record(s)(value);
    } catch (e) {
      if (typeof e === 'string') {
        e.replace(/\\t+/g, '$&\t');
        throw `While decoding ${context} : \n\t${e}`;
      } else {
        throw e;
      }
    }
  };

export const intersectionWithContext =
  <decoders extends Decoder<unknown>[]>(
    context: string,
    ...decoders: decoders
  ): DecoderFunction<getProductOfDecoderArray<decoders>> =>
  (value: unknown) => {
    try {
      return intersection(...decoders)(value);
    } catch (e) {
      if (typeof e === 'string') {
        e.replace(/\\t+/g, '$&\t');
        throw `While decoding ${context} : \n\t${e}`;
      } else {
        throw e;
      }
    }
  };

export const forcedArray =
  <T>(decoder: DecoderFunction<T>): DecoderFunction<T[]> =>
  (value: unknown): T[] => {
    if (value === undefined || value === null) {
      return [];
    }
    const data = array(v => v)(value);

    return data.reduce((acc: T[], item: unknown) => {
      try {
        acc.push(decoder(item));
      } catch (e) {
        console.warn(e);
      }
      return acc;
    }, []);
  };

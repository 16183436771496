import {
  DecoderFunction,
  array,
  field,
  record,
  string,
} from 'typescript-json-decoder';

import { Gender, genderDecoder } from '@/models/GenderModel';
import {
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils';

export type IdentityStatus =
  | 'notValidated'
  | 'validated'
  | 'rejected'
  | 'notEntered';
export type ValidateOrRejectIdentity = 'validate' | 'reject';

export type InseeTownship = {
  town: string;
  post_code: string;
};

export type FrenchIdentity = {
  identityStatus: IdentityStatus;

  identityLastname?: string;
  identityFirstnames: string[];
  gender?: Gender;
  birthDate?: string;
  ins?: string;
  oid?: string;
  socialSecurityNumber?: string;
  birthLocation?: InseeTownship;
  birthCountry?: string;
};

export const frenchIdentityDecoder: DecoderFunction<FrenchIdentity> =
  recordWithContext('FrenchIdentity', {
    identityLastname: field('identityLastname', nullOrUndef(string)),
    identityFirstnames: field('identityFirstnames', array(string)),
    gender: genderDecoder,
    identityStatus: field(
      'identityStatus',
      stringUnion('notValidated', 'validated', 'rejected', 'notEntered'),
    ),
    birthLocation: field(
      'birthLocation',
      nullOrUndef(
        record({
          town: string,
          post_code: string,
        }),
      ),
    ),
    birthCountry: field('birthCountry', nullOrUndef(string)),
    birthDate: field('birthDate', nullOrUndef(string)),
    ins: field('franceINS', nullOrUndef(string)),
    oid: field('franceOID', nullOrUndef(string)),
    socialSecurityNumber: field('socialSecurityNumber', nullOrUndef(string)),
  });

export const inseeTownshipToString = (inseeTownship: InseeTownship) => {
  return `${inseeTownship.town} (${inseeTownship.post_code.slice(0, 2)})`;
};

import { DecoderFunction, record, string } from 'typescript-json-decoder';

import { User, userDecoder } from '@/models/UserModel';
import { deepField } from '@/utils/decoderUtils';

/*********************************
 *         Self Patient          *
 *********************************/

export type SelfPatient = {
  id: string;
  user: User;
  givenName: string;
  familyName: string;
};

export const selfPatientDecoder: DecoderFunction<SelfPatient> = record({
  id: deepField('user.id', string),
  user: userDecoder,
  givenName: string,
  familyName: string,
});

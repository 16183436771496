import React, { useLayoutEffect } from 'react';

export const useComponentSize = (
  componentRef: React.RefObject<HTMLElement>,
) => {
  const [componentSize, setComponentSize] = React.useState<{
    width?: number;
    height?: number;
  }>({
    width: undefined,
    height: undefined,
  });

  useLayoutEffect(() => {
    const component = componentRef.current;

    const handleResize = () => {
      if (component) {
        setComponentSize({
          width: component.clientWidth,
          height: component.clientHeight,
        });
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [componentRef]);

  return componentSize;
};

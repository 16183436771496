import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { PatientList } from '@/models/PatientModel';

import { DoctorNameCell } from '../cells/DoctorNameCell';

const columnHelper = createColumnHelper<PatientList>();

export const doctorNameColumn = columnHelper.accessor('doctor', {
  cell: info => <DoctorNameCell doctor={info.getValue()} />,
  header: () => {
    const { t } = useTranslation();
    return <Typography>{t('profile.doctor')}</Typography>;
  },
  size: 13,
  maxSize: 13,
});

import React, { PropsWithChildren } from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';
import { ScrollableDiv } from '@/uiKit/ScrollableDiv';

export type SidePeekBodyProps = PropsWithChildren<{
  className?: string;
}>;

export const SidePeekBody: React.FC<SidePeekBodyProps> = ({ children }) => {
  const styles = useStyles(makeStyles);
  return <ScrollableDiv className={styles.container}>{children}</ScrollableDiv>;
};

const makeStyles = (theme: Theme) => ({
  container: css`
    height: 70vh;
    width: 100%;
    padding: ${theme.spacing(12)} ${theme.spacing(12)};
  `,
});

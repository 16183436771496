import { ApiErrorResponse } from '@/models/ApiErrorResponse';
import { Condition, conditionerDecoder } from '@/models/ConditionModel';
import { Result } from '@/utils/Result';

import { ApiDataSourceSpec, apiDataSource } from '../datasource/ApiDatasource';

export class ConditionRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  getCondition = (
    patientId: string,
  ): Promise<Result<Condition, ApiErrorResponse>> => {
    return this.datasource.get(
      `/practitioners/patients/${patientId}/conditions/`,
      conditionerDecoder,
    );
  };
}

import React from 'react';

import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AlertCategoriesTranslations } from '@/components/notifications/ActiveNotifications.tsx';
import { TextToast } from '@/components/toast/TextToast.tsx';
import { useStyles } from '@/hooks/useStyles';
import {
  AlertConfig,
  HyperglycemiaAlertParameters,
  MeasurementAlertParameters,
} from '@/models/AlertModel.ts';
import { HappenedInTheLastXDays } from '@/pages/patient-settings/alert-settings/inputs/HappenedInTheLastXDays.tsx';
import { Queries } from '@/queries/Queries.ts';

export type AlertMeasurementSettingsProps = {
  patientId: string;
  alertConfig: AlertConfig;
  parameters: MeasurementAlertParameters;
};

export const AlertMeasurementSettings: React.FC<
  AlertMeasurementSettingsProps
> = ({ patientId, alertConfig, parameters }) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const editAlertConfig = Queries.alerts.useEditAlertConfig();
  const updateParameters = (
    value: unknown,
    key: keyof HyperglycemiaAlertParameters,
  ) => {
    editAlertConfig.mutate(
      {
        patientId,
        alertConfig: {
          id: alertConfig.id,
          parameters: { ...parameters, [key]: value },
        },
      },
      {
        onSuccess: () =>
          TextToast.success(
            t('alertConfig.conditionsSuccess', {
              alert: t(AlertCategoriesTranslations.measurement),
            }),
          ),
        onError: () =>
          TextToast.error(
            t('alertConfig.conditionsError', {
              alert: t(AlertCategoriesTranslations.measurement),
            }),
          ),
      },
    );
  };

  return (
    <div className={styles.container}>
      <Typography variant="body">
        {t('alertConfig.capillaryMeasures')}
      </Typography>
      <Typography variant="body">
        {` - ${t('alertConfig.monoInjection')} ${parameters.min_nb_of_measurements_mono} ${t('alertConfig.measuresPerDay')}`}
      </Typography>
      <Typography variant="body">
        {` - ${t('alertConfig.multiInjection')} ${parameters.min_nb_of_measurements_multi} ${t('alertConfig.measuresPerDay')}`}
      </Typography>
      <Typography variant="body">
        {t('alertConfig.interstitialMeasures')}
      </Typography>
      <HappenedInTheLastXDays
        onDaysChange={days => updateParameters(days, 'n_days')}
        minDays={1}
        maxDays={7}
        beforeText={` - ${parameters.min_nb_scan} ${t('alertConfig.scansPerDay')} `}
        days={parameters.n_days}
      />
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `,
  row: css`
    display: flex;
    flex-direction: row;
  `,
});

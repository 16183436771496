import React, { useRef } from 'react';

import { useTheme } from '@mui/material';
import { Group } from '@visx/group';
import Pie from '@visx/shape/lib/shapes/Pie';

import { useComponentSize } from '@/hooks/useComponentSize';
import { GlobalStats } from '@/models/GlobalStatsModel';
import { largestRemainderRound } from '@/utils/math';

function getDataAsRatios(insulin: GlobalStats['insulin']) {
  const {
    daily_mean_long_insulin,
    daily_mean_short_insulin,
    daily_mean_basal_insulin,
    daily_mean_bolus_insulin,
  } = insulin ?? {};
  const [ratioBasal, ratioBolus] = largestRemainderRound([
    daily_mean_basal_insulin ?? 0,
    daily_mean_bolus_insulin ?? 0,
  ]);
  const [ratioSlow, ratioFast] = largestRemainderRound([
    daily_mean_long_insulin,
    daily_mean_short_insulin,
  ]);
  const hasBasalOrBolus = daily_mean_basal_insulin || daily_mean_bolus_insulin;

  return hasBasalOrBolus
    ? [
        {
          label: 'basal',
          ratio: ratioBasal,
        },
        {
          label: 'bolus',
          ratio: ratioBolus,
        },
      ]
    : [
        {
          label: 'basal',
          ratio: ratioSlow,
        },
        {
          label: 'bolus',
          ratio: ratioFast,
        },
      ];
}

type InsulinChartProps = {
  insulin: GlobalStats['insulin'];
  className?: string;
};

type PieArcData = {
  label: string;
  ratio: number;
};

export const InsulinChart: React.FC<InsulinChartProps> = ({
  insulin,
  className,
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const componentSize = useComponentSize(divRef);
  const theme = useTheme();
  const width = componentSize.width ?? 0;
  const height = componentSize.height ?? 0;
  const size = Math.min(width, height);

  const radius = (Math.min(width, height) / 2) * 0.95;
  const centerY = size / 2;
  const centerX = size / 2;
  const donutThickness = radius * 0.2;
  const dataAsRatios = getDataAsRatios(insulin);

  // color scales
  const getFillColor = (data: string) =>
    data === 'basal'
      ? theme.palette.insulin.basal.contrastText
      : theme.palette.insulin.bolus.contrastText;

  const getStrokeColor = (data: string) =>
    data === 'basal'
      ? theme.palette.insulin.basal.main
      : theme.palette.insulin.bolus.main;

  return (
    <div ref={divRef} className={className}>
      {size > 0 && (
        <svg height={size} width={size}>
          <Group top={centerY} left={centerX}>
            <Pie<PieArcData>
              outerRadius={radius}
              innerRadius={radius - donutThickness}
              data={dataAsRatios}
              pieValue={data => data.ratio}
              cornerRadius={3}
              padAngle={0.005}
            >
              {pie =>
                pie.arcs.map(arc => (
                  <g key={arc.data.label}>
                    <path
                      d={
                        pie.path({
                          ...arc,
                        }) ?? undefined
                      }
                      fill={getFillColor(arc.data.label)}
                      stroke={getStrokeColor(arc.data.label)}
                    />
                  </g>
                ))
              }
            </Pie>
          </Group>
        </svg>
      )}
    </div>
  );
};

import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { Col, Row } from '@/components/layout/Flex';
import { NavItem } from '@/components/navigation/NavItem';
import { SideBar } from '@/components/navigation/SideBar';
import { useStyles } from '@/hooks/useStyles';
import { LayoutProps, RenderLayoutChildren } from '@/pages/layout/LayoutCommon';

type HealthProLayoutPageProps = LayoutProps;

export const HealthProLayout: React.FC<HealthProLayoutPageProps> = props => {
  const styles = useStyles(makeStyles);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  return (
    <Row lang={i18n.language} className={styles.container}>
      <SideBar home="/patients">
        <NavItem
          to="/patients"
          isCurrent={
            location.pathname === '/' ||
            location.pathname.startsWith('/patients')
          }
          tooltip={t('navbar.myPatients')}
        />
      </SideBar>
      <Col className={styles.content} Component="main">
        <RenderLayoutChildren {...props} />
      </Col>
    </Row>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    align-items: stretch;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    background-color: ${theme.palette.background.default};
  `,
  content: css`
    padding: 4vh 4vw;
    margin-left: ${theme.spacing(30)};
    flex-shrink: 1;
    flex-grow: 1;
    overflow-x: clip;
  `,
});

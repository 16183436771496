import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import {
  ArrowOptions,
  FlipOptions,
  FloatingContext,
  FloatingPortal,
  Middleware,
  Placement,
  ShiftOptions,
  UseClickProps,
  UseClientPointProps,
  UseDismissProps,
  UseHoverProps,
  UseRoleProps,
  UseTransitionStylesProps,
  arrow,
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useClick,
  useClientPoint,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import { Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';

export type HoverTooltipType = 'hover' | ({ type: 'hover' } & UseHoverProps);

export type ClickTooltipType = 'click' | ({ type: 'click' } & UseClickProps);

export type AllTooltipType =
  | 'all'
  | { type: 'all'; click: UseClickProps; hover: UseHoverProps };

export type TooltipType = HoverTooltipType | ClickTooltipType | AllTooltipType;

export type BaseTooltipProps = PropsWithChildren<{
  type: TooltipType;
  placement: Placement;
  role: UseRoleProps['role'];
  autoOpen?: boolean;
  closeOnInteract?: boolean;
  dismiss?: UseDismissProps | boolean;
  offset?: number;
  flip?: FlipOptions | boolean;
  shift?: ShiftOptions | boolean;
  arrow?: ArrowOptions;
  clientPoint?: UseClientPointProps;
  relativeClientPoint?: boolean;
  animation?: UseTransitionStylesProps;
  className?: string;
  wrapperClassName?: string;
  floaterClassName?: string;
  content: React.ReactNode;
  svg?: boolean;
  onMouseMove?: (e: MouseEvent) => void;
}>;

export const BaseTooltip: React.FC<BaseTooltipProps> = ({
  type,
  placement,
  role,
  autoOpen = false,
  closeOnInteract = true,
  dismiss: dismissValue,
  offset: offsetValue = 10,
  flip: flipValue,
  shift: shiftValue,
  arrow: arrowValue,
  clientPoint,
  relativeClientPoint = false,
  svg = false,
  animation = {
    duration: 150,
    initial: { transform: 'scale(0.2)' },
    common: ({ placement }) => ({
      transformOrigin: {
        top: 'bottom',
        bottom: 'top',
        left: 'right',
        right: 'left',
        'top-start': 'bottom left',
        'bottom-start': 'top left',
        'left-start': 'top right',
        'right-start': 'top left',
        'top-end': 'bottom right',
        'bottom-end': 'top right',
        'left-end': 'bottom right',
        'right-end': 'bottom left',
      }[placement],
    }),
  },
  className,
  floaterClassName,
  wrapperClassName,
  content,
  children,
  onMouseMove,
}) => {
  const styles = useStyles(makeStyles);
  // Automatically change placement if popup is about to leave the screen
  const flipMiddleware = useMiddleware(true, flip, flipValue);
  // Shift the popup position if it's about to leave the screen
  const shiftMiddleware = useMiddleware(true, shift, shiftValue);
  // Allow to add an arrow to the popup, directed to the parent element
  const arrowMiddleware = useOptMiddleware(arrow, arrowValue);

  const [isOpen, setIsOpen] = useState(autoOpen);

  const { refs, floatingStyles, context } = useFloating<HTMLDivElement>({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(offsetValue),
      ...flipMiddleware,
      ...shiftMiddleware,
      ...arrowMiddleware,
    ],
  });

  const interactions = useTooltipInteraction(
    type,
    context,
    role,
    dismissValue,
    { ...clientPoint, relativeClientPoint },
  );

  // Animate the popup
  const { isMounted, styles: animationStyle } = useTransitionStyles(
    context,
    animation,
  );

  // Support placement inside an SVG element (could be generalized to any html element)
  const Component = svg ? 'g' : 'div';

  // Listens to the mouse movement in the parent and provides updates (usually used with clientPoint)
  useEffect(() => {
    const listener = (e: MouseEvent) => {
      onMouseMove?.(e);
    };
    const divElement = refs.domReference.current;

    if (onMouseMove) {
      divElement?.addEventListener('mousemove', listener);
    }

    return () => {
      divElement?.removeEventListener('mousemove', listener);
    };
  }, [refs, onMouseMove]);

  return (
    <Component className={cx(styles.container, className, 'TooltipRoot')}>
      {/*The parent element that triggers the popup and wraps the provided child*/}
      <Component
        ref={refs.setReference}
        className={cx(styles.wrapper, wrapperClassName, 'TooltipWrapper')}
        {...interactions.getReferenceProps()}
      >
        {children}
      </Component>
      {/*The floating element */}
      {isMounted ? (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            {...interactions.getFloatingProps()}
            className={cx(styles.floatManager, {
              [styles.clientPointDisable]: clientPoint?.enabled,
            })}
          >
            <div
              onClick={() => closeOnInteract && setIsOpen(state => !state)}
              style={animationStyle}
              className={cx(styles.floater, floaterClassName)}
            >
              {content}
            </div>
          </div>
        </FloatingPortal>
      ) : null}
    </Component>
  );
};

/**
 * Create a @floating-ui middleware
 *
 * @param defaultEnabled If set to true: undefined => create middleware
 * @param middleware The middleware to create
 * @param value The value to determine how and if to create the middleware
 */
const useMiddleware = <T,>(
  defaultEnabled: boolean,
  middleware: (opt?: T) => Middleware,
  value: undefined | boolean | T,
): [Middleware] | [] => {
  return useMemo(() => {
    if (value === undefined) {
      return defaultEnabled ? [middleware()] : [];
    } else if (typeof value === 'boolean') {
      return value ? [middleware()] : [];
    } else {
      return [middleware(value)];
    }
  }, [defaultEnabled, middleware, value]);
};
/**
 * Create a @floating-ui middleware with required options
 *
 * @param middleware The middleware to create
 * @param value The value to determine how and if to create the middleware
 */
const useOptMiddleware = <T,>(
  middleware: (opt: T) => Middleware,
  value: undefined | T,
): [Middleware] | [] => {
  return useMemo(() => {
    if (value === undefined) {
      return [];
    } else {
      return [middleware(value)];
    }
  }, [middleware, value]);
};

const useTooltipInteraction = (
  type: TooltipType,
  context: FloatingContext<HTMLDivElement>,
  roleValue: UseRoleProps['role'],
  dismissProps?: UseDismissProps | boolean,
  clientPointProps?: UseClientPointProps & { relativeClientPoint: boolean },
) => {
  const opt = typeof type !== 'string' ? type : undefined;
  const action = typeof type === 'string' ? type : type.type;

  // Open popup on hover
  const hover = useHover(context, {
    enabled: action === 'hover' || action === 'all',
    // Set a polygon between the hovered element and the popup that won't close the popup
    handleClose: safePolygon({ requireIntent: false }),
    ...opt,
    // Disabled safePolygon if clientPoint is enabled (generates a bug where the popup follows the mouse)
    ...(clientPointProps?.enabled ? { handleClose: null } : {}),
  });

  // Open popup on click
  const click = useClick(context, {
    enabled: action === 'click' || action === 'all',
    ...opt,
  });

  // Setup aria
  const role = useRole(context, { role: roleValue });

  // Keyboard support (Esc) to close the popup
  const dismiss = useDismiss(
    context,
    typeof dismissProps === 'boolean'
      ? {
          enabled: dismissProps,
        }
      : dismissProps,
  );

  const rootPosition = (
    context.refs.domReference.current as HTMLDivElement | null
  )?.getBoundingClientRect();

  // Follow the mouse instead of placement based on the parent
  const clientPoint = useClientPoint(
    context,
    clientPointProps && clientPointProps.enabled
      ? {
          ...clientPointProps,
          x:
            clientPointProps.relativeClientPoint &&
            rootPosition &&
            clientPointProps.x !== undefined &&
            clientPointProps.x !== null
              ? rootPosition.x + clientPointProps.x
              : clientPointProps.x,
          y:
            clientPointProps.relativeClientPoint &&
            rootPosition &&
            clientPointProps.y !== undefined &&
            clientPointProps.y !== null
              ? rootPosition.y + clientPointProps.y
              : clientPointProps.y,
        }
      : { enabled: false },
  );

  return useInteractions([hover, click, dismiss, role, clientPoint]);
};

const makeStyles = (theme: Theme) => ({
  container: css``,
  wrapper: css``,
  floatManager: css`
    z-index: ${theme.zIndex.tooltip};
  `,
  floater: css`
    background-color: ${theme.palette.common.white};
    border-radius: ${theme.spacing(4)};
    padding: ${theme.spacing(8)};
    box-shadow: 1px 1px 2px 0.5px lightgrey;
    overflow: clip;
  `,
  clientPointDisable: css`
    pointer-events: none;
  `,
});

import React from 'react';

import { useLocation } from 'react-router';

export function useQueryString() {
  const { search } = useLocation();
  const [qs, setQs] = React.useState<Record<string, string>>(
    Object.fromEntries(new URLSearchParams(search).entries()),
  );

  React.useEffect(() => {
    setQs(Object.fromEntries(new URLSearchParams(search).entries()));
  }, [search]);

  return qs;
}

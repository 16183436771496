import React, { useEffect, useMemo } from 'react';

import { css } from '@emotion/css';
import { Input, Theme, Typography } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';
import {
  inputStyle,
  textStyle,
} from '@/pages/patient-settings/alert-settings/inputs/commonStyles.ts';

export type NumberOfOccurrencesProps = {
  textBefore: string;
  textAfter: string;
  onCountChange: (count: number) => void;
  minCount: number;
  maxCount: number;
  count: number;
};

export const NumberOfOccurrences: React.FC<NumberOfOccurrencesProps> = ({
  textBefore,
  textAfter,
  onCountChange,
  minCount,
  maxCount,
  count,
}) => {
  const styles = useStyles(makeStyles);
  const [localCount, setLocalCount] = React.useState(count.toString());
  const commonInputStyles = useStyles(inputStyle, localCount);
  const textStyles = useStyles(textStyle);

  useEffect(() => {
    setLocalCount(count.toString());
  }, [count]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const value = parseInt(localCount);
      if (
        !isNaN(value) &&
        value >= minCount &&
        (!maxCount || value <= maxCount) &&
        value !== count
      ) {
        onCountChange(value);
      }
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [localCount, minCount, maxCount, count, onCountChange]);

  const isError = useMemo(() => {
    const value = parseInt(localCount);
    return isNaN(value) || value < minCount || (!!maxCount && value > maxCount);
  }, [localCount, minCount, maxCount]);

  return (
    <div className={styles.container}>
      <Typography variant="body" className={textStyles}>
        {textBefore}
      </Typography>
      <Input
        className={commonInputStyles}
        onChange={e => setLocalCount(e.target.value)}
        error={isError}
        value={localCount}
      />
      <Typography variant="body" className={textStyles}>
        {textAfter}
      </Typography>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.spacing(4)};
  `,
});

import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { PatientList } from '@/models/PatientModel';

import { PatientForDoctor } from '../PatientDoctorTable';
import { MessageCellNurse } from '../cells/MessageCellNurse';

const columnHelper = createColumnHelper<PatientForDoctor | PatientList>();

export const messageColumnNurse = columnHelper.accessor('message', {
  cell: info => <MessageCellNurse {...info.getValue()} />,
  header: () => {
    const { t } = useTranslation();
    return (
      <div>
        <Typography>{t('pages.patients.messages')}</Typography>
      </div>
    );
  },
  size: 13,
  maxSize: 13,
});

import { DecoderFunction, array, string } from 'typescript-json-decoder';

import { recordWithContext } from '@/utils/decoderUtils';

export type Phone = {
  number: string;
  description: string;
};

export const phoneDecoder: DecoderFunction<Phone> = recordWithContext('Phone', {
  number: string,
  description: string,
});

export const multiplePhoneDecoder: DecoderFunction<Phone | undefined> = (
  value: unknown,
) => {
  const phones = array(phoneDecoder)(value);

  if (phones.length < 1 || !phones[0].number) {
    return undefined;
  } else {
    return phones[0];
  }
};

export const flatPhoneDecoder: DecoderFunction<string | undefined> = (
  value: unknown,
) => {
  const phone = multiplePhoneDecoder(value);
  return phone?.number;
};

import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  Method,
} from 'axios';
import { DecoderFunction } from 'typescript-json-decoder';

import {
  ApiErrorResponse,
  apiErrorResponseDecoder,
} from '@/models/ApiErrorResponse';
import { Err, Ok, Result } from '@/utils/Result';

const AXIOS_NOT_INITIALIZED_ERROR = 'AXIOS_NOT_INITIALIZED';
const RESPONSE_VALIDATION_ERROR = 'RESPONSE_VALIDATION_FAILED';
const UNKNOWN_AXIOS_ERROR = 'UNKNOWN_AXIOS_ERROR';

class ApiDatasource {
  axiosInstance: AxiosInstance | undefined;

  createInstance = (token: string) => {
    this.axiosInstance = axios.create({
      baseURL: import.meta.env.VITE_APP_API_URL || '/api/v1',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Version: 2, // this is an API-specific value
      },
    });
  };

  request = async <T>(
    method: Method,
    url: string,
    decoder: DecoderFunction<T>,
    config?: AxiosRequestConfig,
  ): Promise<Result<T, ApiErrorResponse>> => {
    if (!this.axiosInstance) {
      return Err({ statusCode: 500, error: AXIOS_NOT_INITIALIZED_ERROR });
    } else {
      try {
        const result = await this.axiosInstance.request({
          ...config,
          method,
          url,
        });
        try {
          const data = decoder(result.data);
          return Ok(data);
        } catch (e) {
          // TODO Log error for debug
          console.warn(e);
          return Err({ statusCode: 400, error: RESPONSE_VALIDATION_ERROR });
        }
      } catch (e) {
        const error = e as AxiosError;
        if (error.response) {
          try {
            const errorResponse = apiErrorResponseDecoder(
              error.response.status,
            )(error.response.data);
            return Err(errorResponse);
          } catch (_) {
            // TODO Log error for debug
            return Err({
              statusCode: error.response.status,
              error: RESPONSE_VALIDATION_ERROR,
            });
          }
        } else {
          // TODO Log error for debug
          return Err({ statusCode: 500, error: UNKNOWN_AXIOS_ERROR });
        }
      }
    }
  };

  get = <T>(
    url: string,
    schema: DecoderFunction<T>,
    config?: AxiosRequestConfig,
  ): Promise<Result<T, ApiErrorResponse>> =>
    this.request('get', url, schema, config);

  post = <T>(
    url: string,
    schema: DecoderFunction<T>,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Promise<Result<T, ApiErrorResponse>> =>
    this.request('post', url, schema, {
      ...config,
      data,
    });

  put = <T>(
    url: string,
    schema: DecoderFunction<T>,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Promise<Result<T, ApiErrorResponse>> =>
    this.request('put', url, schema, {
      ...config,
      data,
    });

  patch = <T>(
    url: string,
    schema: DecoderFunction<T>,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Promise<Result<T, ApiErrorResponse>> =>
    this.request('patch', url, schema, {
      ...config,
      data,
    });

  delete = <T>(
    url: string,
    schema: DecoderFunction<T>,
    config?: AxiosRequestConfig,
  ): Promise<Result<T, ApiErrorResponse>> =>
    this.request('delete', url, schema, config);
}

export type ApiDataSourceSpec = {
  get: <T>(
    url: string,
    schema: DecoderFunction<T>,
    config?: AxiosRequestConfig,
  ) => Promise<Result<T, ApiErrorResponse>>;
  post: <T>(
    url: string,
    schema: DecoderFunction<T>,
    data?: unknown,
    config?: AxiosRequestConfig,
  ) => Promise<Result<T, ApiErrorResponse>>;
  put: <T>(
    url: string,
    schema: DecoderFunction<T>,
    data?: unknown,
    config?: AxiosRequestConfig,
  ) => Promise<Result<T, ApiErrorResponse>>;
  patch: <T>(
    url: string,
    schema: DecoderFunction<T>,
    data?: unknown,
    config?: AxiosRequestConfig,
  ) => Promise<Result<T, ApiErrorResponse>>;
  delete: <T>(
    url: string,
    schema: DecoderFunction<T>,
    config?: AxiosRequestConfig,
  ) => Promise<Result<T, ApiErrorResponse>>;
};

export const apiDataSource = new ApiDatasource();

import React, { Dispatch, SetStateAction } from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SearchBar } from '@/components/search-bar/SearchBar';
import { useStyles } from '@/hooks/useStyles';

export type PatientsSearchProps = {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
};

export const PatientsSearch: React.FC<PatientsSearchProps> = ({
  search,
  setSearch,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  return (
    <SearchBar
      value={search}
      onChange={setSearch}
      placeholder={t('search.searchPatient')}
      className={styles.search}
    />
  );
};

const makeStyles = (theme: Theme) => ({
  search: css`
    margin: 0 ${theme.spacing(16)};
  `,
});

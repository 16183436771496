import { AlertsRepository } from '@/io/repository/AlertsRepository';
import { CreateAlertConfig, EditAlertConfig } from '@/models/AlertModel';
import { PractitionerQueries } from '@/queries/PractitionerQueries';
import { stripQueryResult } from '@/queries/Queries';
import { createMutation } from '@/queries/utils/CreateMutation';
import { createQuery } from '@/queries/utils/CreateQuery';

export class AlertsQueries {
  constructor(
    private readonly practitioners: PractitionerQueries = new PractitionerQueries(),
    private readonly diabetes: AlertsRepository = new AlertsRepository(),
  ) {}

  /*******************************/
  /*********** QUERIES ***********/
  /*******************************/

  useAlertConfigList = createQuery(
    'alert-config-list',
    variables => [variables],
    async (patientId: string) =>
      stripQueryResult(await this.diabetes.getAlertConfigList(patientId)),
  );

  /*******************************/
  /********** MUTATIONS **********/
  /*******************************/

  useCreateAlertConfig = createMutation(
    'create-alert-config',
    async (data: { patientId: string; alertConfig: CreateAlertConfig }) =>
      stripQueryResult(
        await this.diabetes.createAlertConfig(data.patientId, data.alertConfig),
      ),
    {
      onSuccess: (_, variables, { queryClient }) => {
        this.useAlertConfigList.invalidate(queryClient, variables.patientId);
      },
    },
  );

  useEditAlertConfig = createMutation(
    'edit-alert-config',
    async (data: { patientId: string; alertConfig: EditAlertConfig }) =>
      stripQueryResult(
        await this.diabetes.editAlertConfig(data.patientId, data.alertConfig),
      ),
    {
      onSuccess: (_, variables, { queryClient }) => {
        this.useAlertConfigList.invalidate(queryClient, variables.patientId);
      },
    },
  );

  useDeleteAlertConfig = createMutation(
    'delete-alert-config',
    async (data: { patientId: string; alertConfigId: string }) =>
      stripQueryResult(
        await this.diabetes.deleteAlertConfig(
          data.patientId,
          data.alertConfigId,
        ),
      ),
    {
      onSuccess: (_, variables, { queryClient }) => {
        this.useAlertConfigList.invalidate(queryClient, variables.patientId);
      },
    },
  );

  useDismissAlert = createMutation(
    'dismiss-alert',
    async (data: { patientId: string; alertId: string }) =>
      stripQueryResult(
        await this.diabetes.dismissAlert(data.patientId, data.alertId),
      ),
    {
      onSuccess: (_, variables, { queryClient }) => {
        this.practitioners.useListPatientAlerts.invalidate(
          queryClient,
          variables.patientId,
        );
        this.practitioners.usePatientsList.invalidate(queryClient, {});
      },
    },
  );
}

import { DecoderFunction, number, string } from 'typescript-json-decoder';

import {
  forcedArray,
  intersectionWithContext,
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils';

import { ActivityIntensity, FoodSize } from './DiabetesDataModel';

export type BGMContentrationType = {
  concentration: number;
  date: string;
};
type BGMDayValue = {
  bloodSugarBefore: BGMContentrationType[];
  bloodSugarAfter: BGMContentrationType[];
  slowInsulin: BGMContentrationType[];
  fastInsulin: BGMContentrationType[];
};

type BGMNightValue = {
  bloodSugar: BGMContentrationType[];
  slowInsulin: BGMContentrationType[];
};

export type FoodComment = {
  comments?: string;
  meal: 'breakfast' | 'lunch' | 'dinner' | 'snack' | 'unknown';
  size: FoodSize;
  carbs?: number;
};

export type ActivityCommentType = {
  date: string;
  duration: number;
  intensity: ActivityIntensity;
};

export type EventCommentType = {
  date: string;
  category:
    | 'severeHypoglycemia'
    | 'perceivedHypoglycemia'
    | 'perceivedHyperglycemia'
    | 'techAlert'
    | 'other';
  message: string;
};

export type CommentType = {
  food: FoodComment[];
  activity: ActivityCommentType[];
  event: EventCommentType[];
};

export type BGMData = {
  date: string;
  breakfast: BGMDayValue;
  lunch: BGMDayValue;
  dinner: BGMDayValue;
  night: BGMNightValue;
  comments: CommentType;
};

const contentrationTypeDecoder: DecoderFunction<BGMContentrationType> =
  intersectionWithContext('BGMContentrationType', {
    concentration: number,
    date: string,
  });
const foodCommentDecoder: DecoderFunction<FoodComment> =
  intersectionWithContext('ActivityComment', {
    comments: nullOrUndef(string),
    meal: stringUnion('breakfast', 'lunch', 'dinner', 'snack', 'unknown'),
    size: stringUnion('light', 'medium', 'big', 'unknown'),
    carbs: nullOrUndef(number),
  });

const activityCommentDecoder: DecoderFunction<ActivityCommentType> =
  intersectionWithContext('ActivityComment', {
    date: string,
    intensity: stringUnion('low', 'medium', 'high'),
    duration: number,
  });

const eventCommentDecoder: DecoderFunction<EventCommentType> =
  intersectionWithContext('EventComment', {
    date: string,
    category: stringUnion(
      'severeHypoglycemia',
      'perceivedHypoglycemia',
      'techAlert',
      'other',
    ),
    message: string,
  });

const commentDecoder: DecoderFunction<CommentType> = intersectionWithContext(
  'CommentType',
  {
    food: forcedArray(foodCommentDecoder),
    activity: forcedArray(activityCommentDecoder),
    event: forcedArray(eventCommentDecoder),
  },
);
const bgmDayValueDecoder: DecoderFunction<BGMDayValue> =
  intersectionWithContext('BGMDayValue', {
    bloodSugarBefore: forcedArray(contentrationTypeDecoder),
    bloodSugarAfter: forcedArray(contentrationTypeDecoder),
    slowInsulin: forcedArray(contentrationTypeDecoder),
    fastInsulin: forcedArray(contentrationTypeDecoder),
  });

const bgmNightValueDecoder: DecoderFunction<BGMNightValue> =
  intersectionWithContext('BGMNightValue', {
    bloodSugar: forcedArray(contentrationTypeDecoder),
    slowInsulin: forcedArray(contentrationTypeDecoder),
  });

export const bgmDataDecoder: DecoderFunction<BGMData> = recordWithContext(
  'BGMData',
  {
    date: string,
    breakfast: bgmDayValueDecoder,
    lunch: bgmDayValueDecoder,
    dinner: bgmDayValueDecoder,
    night: bgmNightValueDecoder,
    comments: commentDecoder,
  },
);

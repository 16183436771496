import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';

import { Col } from '@/components/layout/Flex';
import { useStyles } from '@/hooks/useStyles';

export type StatItemProps = {
  title: string;
  value: number;
  precision?: number;
  unit?: string;
  variation?: number;
  className?: string;
  lowerIsBetter?: boolean;
  variationThreshold?: number;
};

export const StatItem: React.FC<StatItemProps> = ({
  title,
  value,
  precision = 2,
  unit,
  variation,
  className,
  lowerIsBetter = false,
  variationThreshold = 0,
}) => {
  const styles = useStyles(
    makeStyles,
    variation,
    lowerIsBetter,
    variationThreshold,
  );
  const variationIcon = !variation ? '=' : variation > 0 ? '▲' : '▼';
  return (
    <Col className={cx(styles.container, className)}>
      <Typography variant="body" className={styles.title}>
        {title}
      </Typography>
      <Typography
        variant="subtitleLarge"
        component="span"
        fontWeight="bold"
        className={styles.value}
      >
        {value.toFixed(precision)}
        <Typography variant="body" fontWeight="normal" component="span">
          {` ${unit ?? ''}`}
        </Typography>
      </Typography>

      <Typography
        variant="bodySmall"
        component="span"
        fontWeight="bold"
        className={styles.variation}
      >
        {`${variationIcon} ${
          variation !== undefined ? variation.toFixed(precision) : ''
        }  ${unit ?? ''}`}
      </Typography>
    </Col>
  );
};

const makeStyles = (
  theme: Theme,
  variation: number | undefined,
  lowerIsBetter: boolean,
  variationThreshold: number,
) => ({
  container: css``,
  title: css`
    flex: 1 1;
    white-space: nowrap;
    overflow: clip;
    text-overflow: ellipsis;
  `,
  value: css`
    margin-top: ${theme.spacing(8)};
    margin-bottom: ${theme.spacing(2)};
    font-weight: bold;
  `,
  variation: css`
    color: ${!variation
      ? 'transparent'
      : (!lowerIsBetter && variation > variationThreshold) ||
          (lowerIsBetter && variation < variationThreshold)
        ? theme.palette.success.main
        : theme.palette.error.main};

    visibility: ${!variation ? 'hidden' : 'shown'};
  `,
});

import { DateTime, Settings } from 'luxon';

import {
  ApiDataSourceSpec,
  apiDataSource,
} from '@/io/datasource/ApiDatasource';
import { AGPStats, agpStatsDecoder } from '@/models/AGPStatsModel';
import { ApiErrorResponse } from '@/models/ApiErrorResponse';
import { BGMData, bgmDataDecoder } from '@/models/BGMLogbook';
import { Dataviz, datavizDecoder } from '@/models/Dataviz';
import {
  BulkAddGlycemia,
  DiabetesParameters,
  EditDiabetesParameters,
  diabetesParametersDecoder,
} from '@/models/DiabetesDataModel';
import { GlobalStats, globalStatsDecoder } from '@/models/GlobalStatsModel';
import {
  MedicalDevice,
  medicalDeviceDecoder,
} from '@/models/MedicalDeviceModel';
import {
  TargetDurationStats,
  targetDurationStatsDecoder,
} from '@/models/TargetDurationStatsModel';
import { Result } from '@/utils/Result';
import { DateUtils } from '@/utils/date.ts';
import { forcedArray } from '@/utils/decoderUtils';

export class DiabetesRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  getMedicalDevices = (
    patientId: string,
  ): Promise<Result<MedicalDevice[], ApiErrorResponse>> => {
    return this.datasource.get(
      `/practitioner/devices/${patientId}/`,
      forcedArray(medicalDeviceDecoder),
    );
  };

  bulkAddGlycemia = ({
    patientId,
    ...data
  }: BulkAddGlycemia): Promise<Result<undefined, ApiErrorResponse>> =>
    this.datasource.post(
      `/diabetes/glycemia/${patientId}/bulk_create/`,
      () => undefined,
      data,
    );

  getBGMLogbook = (
    patientId: string,
    from: DateTime,
    to: DateTime,
  ): Promise<Result<BGMData[], ApiErrorResponse>> => {
    return this.datasource.get(
      `/bgm-logbook/${patientId}`,
      forcedArray(bgmDataDecoder),
      {
        params: {
          from: from.startOf('day').toISO(),
          to: to.endOf('day').toISO(),
        },
      },
    );
  };

  getDataViz = (
    patientId: string,
    from: DateTime,
    to: DateTime,
  ): Promise<Result<Dataviz[], ApiErrorResponse>> => {
    return this.datasource.get(
      `/dataviz/${patientId}/`,
      forcedArray(datavizDecoder),
      {
        params: {
          from: from.startOf('day').toISO(),
          to: to.endOf('day').toISO(),
          timezone: Settings.defaultZone.name,
        },
      },
    );
  };

  getParameters = (
    patientId: string,
  ): Promise<Result<DiabetesParameters, ApiErrorResponse>> => {
    return this.datasource.get(
      `/diabetes/parameters/${patientId}/`,
      diabetesParametersDecoder,
    );
  };

  editParameters = ({
    id,
    ...parameters
  }: EditDiabetesParameters): Promise<
    Result<DiabetesParameters, ApiErrorResponse>
  > => {
    return this.datasource.patch(
      `/diabetes/parameters/${id}/`,
      diabetesParametersDecoder,
      {
        hyperglycemia: parameters.thresholdHyperglycemia,
        hypoglycemia: parameters.thresholdHypoglycemia,
        severeHyperglycemia: parameters.thresholdHyperglycemiaSevere,
        severeHypoglycemia: parameters.thresholdHypoglycemiaSevere,
      },
    );
  };

  getTargetDurationStats = (
    patientId: string,
    from: DateTime,
    to: DateTime,
  ): Promise<Result<TargetDurationStats, ApiErrorResponse>> => {
    return this.datasource.get(
      `/stats/${patientId}/target_duration/`,
      targetDurationStatsDecoder,
      {
        params: {
          date_min: from.startOf('day').toISO(),
          date_max: to.endOf('day').toISO(),
        },
      },
    );
  };

  getGlobalStats = (
    patientId: string,
    from: DateTime,
    to: DateTime,
  ): Promise<Result<GlobalStats, ApiErrorResponse>> => {
    return this.datasource.get(
      `/stats/${patientId}/global/`,
      globalStatsDecoder,
      {
        params: {
          date_min: from.startOf('day').toISO(),
          date_max: to.plus({ day: 1 }).startOf('day').toISO(),
        },
      },
    );
  };

  getAGPStats = (
    patientId: string,
    from: DateTime,
    to: DateTime,
  ): Promise<Result<AGPStats, ApiErrorResponse>> => {
    return this.datasource.get(`/stats/${patientId}/agp/`, agpStatsDecoder, {
      params: {
        date_min: from.startOf('day').toISO(),
        date_max: to.endOf('day').toISO(),
        timezone: DateUtils.tzOffset(from) / 60,
      },
    });
  };

  uploadFile = (patientId: string, file: File, timezone?: string) => {
    const data = new FormData();
    data.append('timezone', timezone ?? Settings.defaultZone.name);
    data.append('name', file.name);
    data.append('file', file);
    return this.datasource.post(
      `/diabetes/file/${patientId}/`,
      () => undefined,
      data,
      {
        headers: {
          'Content-Disposition': `attachment; filename=${file.name}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  };
}

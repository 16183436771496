import React from 'react';

import { css } from '@emotion/css';
import { MenuItem, Select, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons.ts';
import { TextToast } from '@/components/toast/TextToast.tsx';
import { useStyles } from '@/hooks/useStyles';
import { AlertConfig, AlertCriticity } from '@/models/AlertModel.ts';
import { Queries } from '@/queries/Queries.ts';
import { CircledIcon } from '@/uiKit/CircledIcon.tsx';

export type AlertSettingsPriorityProps = {
  patientId: string;
  alertConfig: AlertConfig;
  className?: string;
};

export const AlertSettingsPriority: React.FC<AlertSettingsPriorityProps> = ({
  patientId,
  alertConfig,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const [criticity, setCriticity] = React.useState(alertConfig.criticity);
  const editAlertConfig = Queries.alerts.useEditAlertConfig();

  return (
    <Select
      value={criticity}
      classes={{ select: styles.menu }}
      variant="standard"
      onChange={e => {
        setCriticity(e.target.value as AlertCriticity);
        editAlertConfig.mutate(
          {
            patientId,
            alertConfig: {
              id: alertConfig.id,
              criticity: e.target.value as AlertCriticity,
            },
          },
          {
            onSuccess: () => {
              TextToast.success(t('alertConfig.prioritySuccess'));
            },
            onError: () => {
              setCriticity(alertConfig.criticity);
              TextToast.error(t('alertConfig.priorityError'));
            },
          },
        );
      }}
    >
      <MenuItem value="low" className={styles.menu}>
        <CircledIcon Icon={Icons.alertCircle} className={styles.iconLow} />
        <Typography>{t('alertConfig.intervention.low')}</Typography>
      </MenuItem>
      <MenuItem value="medium" className={styles.menu}>
        <CircledIcon Icon={Icons.alertTriangle} className={styles.iconMedium} />
        <Typography>{t('alertConfig.intervention.medium')}</Typography>
      </MenuItem>
      <MenuItem value="high" className={styles.menu}>
        <CircledIcon Icon={Icons.alertTriangle} className={styles.iconHigh} />
        <Typography>{t('alertConfig.intervention.high')}</Typography>
      </MenuItem>
    </Select>
  );
};

const makeStyles = (theme: Theme) => ({
  menu: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.spacing(4)};
  `,
  iconLow: css`
    color: ${theme.palette.warning.light};
    background-color: ${theme.palette.warning.contrastText};
  `,
  iconMedium: css`
    color: ${theme.palette.error.main};
    background-color: ${theme.palette.error.light};
  `,
  iconHigh: css`
    color: ${theme.palette.error.contrastText};
    background-color: ${theme.palette.error.main};
  `,
});

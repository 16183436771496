import React from 'react';

import { css } from '@emotion/css';
import { Box, TextField, TextFieldProps, Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';
import { ScrollableDiv } from '@/uiKit/ScrollableDiv';

type TextAreaProps = TextFieldProps & {
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  maxHeight?: number;
  disableUnderline?: boolean;
};

export const TextArea: React.FC<TextAreaProps> = ({
  startAdornment,
  endAdornment,
  maxHeight = 200,
  disableUnderline = false,
  ...props
}) => {
  const styles = useStyles(makeStyles, maxHeight);
  return (
    <Box
      position="relative"
      borderRadius={4}
      border={1}
      padding={1}
      overflow="clip"
    >
      {startAdornment && <Box mb={1}>{startAdornment}</Box>}
      <ScrollableDiv className={styles.scroll}>
        <TextField
          id="text-area-nestable"
          className={styles.textArea}
          multiline
          variant="standard"
          fullWidth
          {...props}
          InputProps={{
            disableUnderline: disableUnderline,
          }}
        />
      </ScrollableDiv>
      {endAdornment && <Box mt={1}>{endAdornment}</Box>}
    </Box>
  );
};

const makeStyles = (theme: Theme, maxHeight: number) => ({
  scroll: css`
    height: ${maxHeight}px;
  `,
  textArea: css`
    padding: ${theme.spacing(8)};
  `,
});

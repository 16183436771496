import { AllPhoneCountryCodes } from '@/yup/YupPhoneCountryCodes';
import yup from '@/yup/yup';

export const contactFormSchema = yup.object().shape({
  firstname: yup.string().required('form.text.firstnameRequired'),
  lastname: yup.string().required('form.text.lastnameRequired'),
  phone: yup.string().phone(AllPhoneCountryCodes).required('form.required'),
  email: yup.string().email('form.email.required').optional(),
  comment: yup.string().optional(),
  isEmergencyContact: yup.boolean().required('form.required'),
  relationshipWithPatient: yup
    .string()
    .oneOf([
      'grand_parent',
      'parent',
      'parent_in_law',
      'partner',
      'child',
      'brother',
      'cousin',
      'friend',
      'other',
    ])
    .required('form.required'),
});

export type ContactData = yup.InferType<typeof contactFormSchema>;

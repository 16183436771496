import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { Queries } from '@/queries/Queries';

import { InformationPaper } from '../atoms/InformationPaper';

export const DoctorChooseNotToRenewCard = () => {
  const { t } = useTranslation();

  const id = usePatientIdFromURL() ?? '';
  const { data: carePlanData } = Queries.practitioner.useCarePlans(id, {
    enabled: id !== '',
  });
  const { data: practitioner } = Queries.practitioner.usePractitioner({});

  if (
    practitioner?.qualification !== 'nurse' ||
    !carePlanData?.has_doctor_decided_not_to_renew
  ) {
    return null;
  }

  return (
    <InformationPaper isSquare={true} padding={12}>
      <Typography variant="bodySmall">
        {t('patientInfo.doctorDecidedNotToRenew')}
      </Typography>
    </InformationPaper>
  );
};

import { css, cx } from '@emotion/css';
import { TableBody, TableCell, TableRow, Theme } from '@mui/material';
import { Table, flexRender } from '@tanstack/react-table';

import { useStyles } from '@/hooks/useStyles';
import { BGMData } from '@/models/BGMLogbook';

export const BGMTableBody = ({ table }: { table: Table<BGMData> }) => {
  const styles = useStyles(makeStyles);

  return (
    <TableBody>
      {table.getRowModel().rows.map(row => (
        <TableRow key={row.id}>
          {row.getVisibleCells().map(cell => {
            const hasAverage = row.original.date.includes('avg');
            return (
              <TableCell
                align="center"
                className={cx({
                  [styles.td]: true,
                  [styles.borderHorizontal]: hasAverage,
                  [styles.borderDate]: hasAverage && cell.column.id == 'date',
                  [styles.borderComments]:
                    hasAverage && cell.column.id == 'comments',
                })}
                key={cell.id}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </TableBody>
  );
};

const makeStyles = (theme: Theme) => ({
  td: css`
    border-bottom-style: none !important;
    padding: ${theme.spacing(2)} !important;
    background-color: ${theme.palette.violet.contrastText};

    :nth-child(1),
    :nth-child(6),
    :nth-child(7),
    :nth-child(8),
    :nth-child(9),
    :nth-child(14),
    :nth-child(15) {
      background-color: ${theme.palette.bgm.clearColumn};
    }

    :nth-child(2),
    :nth-child(3),
    :nth-child(4),
    :nth-child(5),
    :nth-child(10),
    :nth-child(11),
    :nth-child(12),
    :nth-child(13),
    :nth-child(16) {
      background-color: ${theme.palette.bgm.darkColumn};
    }
  `,
  borderHorizontal: css`
    border-bottom: 1px solid ${theme.palette.violet.light} !important;
    border-top: 1px solid ${theme.palette.violet.light};
  `,
  borderDate: css`
    border-left: 1px solid ${theme.palette.violet.light};
    border-top-left-radius: ${theme.spacing(2)};
    border-bottom-left-radius: ${theme.spacing(2)};
  `,
  borderComments: css`
    border-right: 1px solid ${theme.palette.violet.light};
    border-top-right-radius: ${theme.spacing(2)};
    border-bottom-right-radius: ${theme.spacing(2)};
  `,
});

import {
  ApiDataSourceSpec,
  apiDataSource,
} from '@/io/datasource/ApiDatasource';
import { ApiErrorResponse } from '@/models/ApiErrorResponse';
import {
  CreateNurseNotice,
  DeleteNurseNotice,
  NurseNotice,
  nurseNoticeDecoder,
} from '@/models/NurseNoticeModel';
import { Result } from '@/utils/Result';

export class ObservationsRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  /*
  For nurse only
   */
  createNotice = (
    data: CreateNurseNotice,
  ): Promise<Result<NurseNotice, ApiErrorResponse>> => {
    return this.datasource.post(
      `nurse-notices/${data.patientId}/`,
      nurseNoticeDecoder,
      {
        detail: data.detail,
      },
    );
  };

  /*
  For nurse only
   */
  deleteNotice = (
    data: DeleteNurseNotice,
  ): Promise<Result<NurseNotice, ApiErrorResponse>> => {
    return this.datasource.delete(
      `nurse-notices/${data.patientId}/${data.noticeId}/`,
      nurseNoticeDecoder,
    );
  };

  /*
  For doctor only
  Currently same as deleteNotice. Might change in the future.
   */
  mitigateNotice = (
    data: DeleteNurseNotice,
  ): Promise<Result<NurseNotice, ApiErrorResponse>> => {
    return this.datasource.delete(
      `nurse-notices/${data.patientId}/${data.noticeId}/`,
      nurseNoticeDecoder,
    );
  };

  getNurseNotice = (
    patientId: string,
  ): Promise<Result<NurseNotice | null, ApiErrorResponse>> => {
    return this.datasource.get(
      `practitioners/patients/${patientId}/nurse-notice/`,
      data => (data ? nurseNoticeDecoder(data) : null), // this route returns nocontent if no notice
    );
  };
}

import { DecoderFunction, string } from 'typescript-json-decoder';

import {
  Activity,
  DiabetesParameters,
  Food,
  Glycemia,
  Insulin,
  Report,
  activityDecoder,
  diabetesParametersDecoder,
  foodDecoder,
  glycemiaDecoder,
  insulinDecoder,
  reportDecoder,
} from '@/models/DiabetesDataModel';
import { forcedArray, recordWithContext } from '@/utils/decoderUtils';

export type Dataviz = {
  glycemia: Glycemia[];
  insulin: Insulin[];
  food: Food[];
  activity: Activity[];
  reports: Report[];
  parameters: DiabetesParameters;
  date: string;
};

export const datavizDecoder: DecoderFunction<Dataviz> = recordWithContext(
  'DatavizModel',
  {
    glycemia: forcedArray(glycemiaDecoder),
    insulin: forcedArray(insulinDecoder),
    food: forcedArray(foodDecoder),
    activity: forcedArray(activityDecoder),
    reports: forcedArray(reportDecoder),
    parameters: diabetesParametersDecoder,
    date: string,
  },
);

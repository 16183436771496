import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles';
import { Tag } from '@/uiKit/tag/Tag';

type MessageCellType = {
  sent_by_practitioner_to_practitioner: number;
  sent_by_dg_patient: number;
};

export const MessageCellDoctor = ({
  sent_by_practitioner_to_practitioner,
  sent_by_dg_patient,
}: MessageCellType) => {
  const styles = useStyles(makeStyles);

  const messageCount =
    sent_by_practitioner_to_practitioner + sent_by_dg_patient;

  if (messageCount <= 0) {
    return null;
  }

  return (
    <div className={styles.messageTagIcon}>
      <Tag
        color="blue"
        label={
          <div className={styles.messageTagIcon}>
            <Icons.letter height="1em" width="1em" />
            <div>{messageCount}</div>
          </div>
        }
      />
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  messageTagIcon: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  `,
});

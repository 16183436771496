import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { FontSizes } from '@/assets/fonts.ts';
import { Icons } from '@/assets/icons.ts';
import { IconButton } from '@/components/button/IconButton.tsx';
import { useScroll } from '@/hooks/useScroll.ts';
import { useStyles } from '@/hooks/useStyles';

export type BackToTopProps = {
  // Empty
};

export const BackToTop: React.FC<BackToTopProps> = ({}) => {
  const styles = useStyles(makeStyles);
  const scroll = useScroll();

  return scroll.current > 1000 ? (
    <div className={styles.backToTop}>
      <IconButton
        buttonType="primary"
        icon={Icons.chevronUp}
        placement={'left'}
        className={styles.moreContentButton}
        iconClassname={styles.moreContentIcon}
        onClick={() => window.scroll({ top: 0, behavior: 'smooth' })}
      />
    </div>
  ) : null;
};

const makeStyles = (theme: Theme) => ({
  moreContentButton: css`
    width: fit-content;
    color: ${theme.palette.common.white};
  `,
  moreContentIcon: css`
    width: ${FontSizes.bodyLarge};
    height: ${FontSizes.bodyLarge};
  `,
  backToTop: css`
    position: fixed;
    bottom: ${theme.spacing(12)};
    align-self: flex-end;
    z-index: ${theme.zIndex.fab};
    stroke: ${theme.palette.grey[900]};
  `,
});

import { css } from '@emotion/css';
import { Typography } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';
import { UserIdentity } from '@/models/UserModel';

type PropsType = {
  doctor?: UserIdentity;
};

export const DoctorNameCell = ({ doctor }: PropsType) => {
  const styles = useStyles(makeStyles);
  const doctorLabel = doctor
    ? `${doctor.givenName.charAt(0)}. ${doctor.familyName}`
    : '';
  return (
    <Typography variant="body" className={styles.doctorCell}>
      {doctorLabel}
    </Typography>
  );
};

const makeStyles = () => ({
  doctorCell: css`
    text-align: start;
  `,
});

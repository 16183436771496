import { useId } from '@floating-ui/react';
import {
  FormControlLabel,
  FormControlLabelProps,
  Switch,
  SwitchProps,
} from '@mui/material';
import { FieldPath, FieldValues, useController } from 'react-hook-form';

type PropsSelectionFromForm<Form extends FieldValues> = {
  [K in FieldPath<Form>]: {
    name: K;
  };
}[FieldPath<Form>];

type OverwrittenSwitchProps = 'ref' | 'value' | 'disabled' | 'label';

/**
 * The `ref` and `value` props are managed by react-hook-form
 * The
 */
type FilteredSwitchProps = {
  switchProps?: Omit<SwitchProps, OverwrittenSwitchProps>;
} & Omit<FormControlLabelProps, 'control'>;

type FormSwitchProps<Form extends FieldValues> = PropsSelectionFromForm<Form> &
  FilteredSwitchProps;

export const FormSwitch = <Form extends FieldValues>({
  name,
  label,
  switchProps,
  ...props
}: FormSwitchProps<Form>) => {
  const { field } = useController<Form>({
    name,
    disabled: props.disabled,
  });
  const id = useId();
  return (
    <FormControlLabel
      label={label}
      labelPlacement="end"
      {...props}
      control={
        <Switch
          id={id}
          {...switchProps}
          onChange={(event, child) => {
            field.onChange(event);
            switchProps?.onChange?.(event, child);
          }}
          onBlur={event => {
            field.onBlur();
            switchProps?.onBlur?.(event);
          }}
          checked={field.value}
        />
      }
    ></FormControlLabel>
  );
};

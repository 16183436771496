import { useState } from 'react';

import { AttachmentContext } from '@/components/chat/footer/AttachmentContext';
import { FooterForm } from '@/components/chat/footer/FooterForm';
import SidePeekFooter from '@/components/side-peek/SidePeekFooter';

export const ChatFooter = () => {
  const [files, setFiles] = useState<File[]>([]);

  return (
    <AttachmentContext.Provider value={{ files, setFiles }}>
      <SidePeekFooter>
        <FooterForm />
      </SidePeekFooter>
    </AttachmentContext.Provider>
  );
};

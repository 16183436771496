import { useContext, useEffect, useState } from 'react';

import { css } from '@emotion/css';

import {
  ChatFilterContext,
  MessagesByStateContext,
} from '@/components/chat/ChatContext';
import { ChatBody } from '@/components/chat/body/ChatBody';
import { ChatFooter } from '@/components/chat/footer/ChatFooter';
import { ChatHeader } from '@/components/chat/header/ChatHeader';
import { Loader } from '@/components/loading/Loader';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useStyles';
import { Message } from '@/models/ChatModel';
import { Queries } from '@/queries/Queries';

export const ChatContent = () => {
  const selectedFilter = useContext(ChatFilterContext);
  const patientId = usePatientIdFromURL() ?? '';
  const [messages, setMessages] = useState<Message[]>([]);
  const styles = useStyles(makeStyles);

  const messageRequest = Queries.chat.useChat({
    subjectId: patientId,
    visibility: selectedFilter?.filter,
  });

  useEffect(() => {
    const messages = messageRequest.isSuccess ? messageRequest.data : [];
    setMessages(messages);
  }, [messageRequest.data, messageRequest.isSuccess]);

  return (
    <>
      <ChatHeader />
      <MessagesByStateContext.Provider value={{ messages, setMessages }}>
        {messageRequest.isSuccess && <ChatBody />}
        {messageRequest.isLoading && (
          <div className={styles.loader}>
            <Loader size="L" />
          </div>
        )}
        <ChatFooter />
      </MessagesByStateContext.Provider>
    </>
  );
};

const makeStyles = () => ({
  loader: css`
    width: 100%;
    aspect-ratio: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
});

import { DecoderFunction } from 'typescript-json-decoder';

import {
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils';

export type GlycemiaDisplayModeType = 'continuous' | 'logbook';

export type GlycemiaDisplayMode = {
  display_mode?: GlycemiaDisplayModeType;
};

export const glycemiaDisplayModeDecoder: DecoderFunction<GlycemiaDisplayMode> =
  recordWithContext('GlycemiaDisplayMode', {
    display_mode: nullOrUndef(stringUnion('continuous', 'logbook')),
  });

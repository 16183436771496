import { useState } from 'react';

import { css } from '@emotion/css';
import {
  Button,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { UploadButton } from '@/components/button/UploadButton';
import { Tooltip } from '@/components/floating/Tooltip';
import { useStyles } from '@/hooks/useStyles';
import { FileModel } from '@/models/FileModel';
import { Patient } from '@/models/PatientModel';
import { PrescriptionGenerationHandler } from '@/models/PrescriptionGenerationModel';
import { Queries } from '@/queries/Queries';
import { ButtonLoader } from '@/uiKit/ButtonLoader';

type GenerateOrUploadPrescriptionProps = {
  onGeneratePrescription: () => void;
  onPrescriptionReady: PrescriptionGenerationHandler;
  patientId?: string;
  patientInfo: Pick<Patient, 'givenName' | 'familyName'>;
  onBack: () => void;
};

export const GenerateOrUploadPrescription = ({
  onGeneratePrescription,
  onPrescriptionReady,
  patientInfo,
  patientId = undefined,
  onBack,
}: GenerateOrUploadPrescriptionProps) => {
  const { t } = useTranslation();
  const { data } = Queries.practitioner.useCarePlanDirectives({
    patientId: patientId,
  });

  const styles = useStyles(makeStyles);

  const { status } = Queries.practitioner.useCreatePatient(); // TODO check also renew careplan mutation status
  const [prescription, setPrescription] = useState<FileModel>();
  if (!data) {
    return null;
  }
  const periodStart = data.carePlanCreationDataDirectives.periodStart;
  return (
    <>
      <DialogTitle>{t('diabetesForms.prescription')}</DialogTitle>
      <DialogContent className={styles.gap}>
        <div className={styles.row}>
          <Button variant="contained" onClick={onGeneratePrescription}>
            {t('diabetesForms.generatePrescription')}
          </Button>
        </div>
        <div className={styles.row}>
          <Typography variant="body">
            {t('diabetesForms.prescriptionDescription')} : <u>{periodStart}</u>
          </Typography>
          <Tooltip
            content={
              <Typography variant="body" className={styles.tooltip}>
                {t('diabetesForms.prescriptionTooltip')}
              </Typography>
            }
            placement="bottom"
          >
            <Icons.questionCircle />
          </Tooltip>
        </div>
        <div className={styles.row}>
          <UploadButton
            onFileChange={newPrescription => setPrescription(newPrescription)}
            fileType="prescriptions"
            patientInfo={patientInfo}
          />
        </div>
        <div className={styles.footer}>
          <Button variant="outlined" onClick={onBack}>
            {t('button.cancel')}
          </Button>
          <ButtonLoader
            variant="contained"
            onClick={() =>
              prescription && onPrescriptionReady({ prescription })
            }
            disabled={!prescription}
            status={status}
          >
            {t('button.next')}
          </ButtonLoader>
        </div>
      </DialogContent>
    </>
  );
};

const makeStyles = (theme: Theme) => ({
  hidden: css`
    display: none;
  `,
  row: css`
    display: flex;
    justify-content: center;
  `,
  gap: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(12)};
  `,
  footer: css`
    display: flex;
    justify-content: space-between;
    padding: 0 ${theme.spacing(40)};
  `,
  tooltip: css`
    white-space: pre-wrap;
    padding-left: 0.5rem;
  `,
});

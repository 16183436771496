import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';
import { roundWithPrecision } from '@/utils/math.ts';

export type InsulinStatItemProps = {
  title: string;
  percentage?: number;
  percentagePrecision?: number;
  value: number;
  precision?: number;
  unit?: string;
  className?: string;
  legend?: React.ReactElement;
};

export const InsulinStatItem: React.FC<InsulinStatItemProps> = ({
  title,
  value,
  percentage,
  percentagePrecision = 2,
  precision = 2,
  unit,
  legend,
  className,
}) => {
  const styles = useStyles(makeStyles);

  const titleElement = (
    <div className={styles.titleContainer}>
      {legend}
      <Typography variant="body" className={styles.title}>
        {title}
      </Typography>
    </div>
  );

  if (percentage !== undefined) {
    return (
      <div className={cx(styles.container, className)}>
        {titleElement}
        <div className={styles.values}>
          <Typography variant="subtitleLarge" className={styles.value}>
            {`${roundWithPrecision(value, precision)}${unit ?? ''}`}
          </Typography>
          <Typography variant="body">{`${roundWithPrecision(percentage, percentagePrecision)}%`}</Typography>
        </div>
      </div>
    );
  } else {
    return (
      <div className={cx(styles.container, className)}>
        {titleElement}
        <div className={styles.values}>
          <Typography variant="subtitleLarge" className={styles.value}>
            {roundWithPrecision(value, precision)}
          </Typography>
          <Typography variant="body">{` ${unit ?? ''}`}</Typography>
        </div>
      </div>
    );
  }
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
  `,
  titleContainer: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.spacing(2)};
  `,
  title: css`
    flex: 1 1;
    white-space: nowrap;
    overflow: clip;
    text-overflow: ellipsis;
  `,
  values: css`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: ${theme.spacing(2)};
  `,
  value: css`
    margin-top: ${theme.spacing(4)};
    margin-bottom: ${theme.spacing(2)};
  `,
});

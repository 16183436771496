import { useMemo } from 'react';

import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

import { PatientList } from '@/models/PatientModel';
import { MuiTable } from '@/uiKit/Table';

import { doctorNameColumn } from './columns/doctorNameColumn';
import { messageColumnNurse } from './columns/messageColumnNurse';
import { familyNameColumn } from './columns/patientNameColumn';
import { reportedPatientColumnNurse } from './columns/reportedPatientColumnNurse';
import { telemonitoringColumnNurse } from './columns/telemonitoringColumnNurse';

type Props = {
  patients: PatientList[];
};

export const PatientNurseTable = ({ patients }: Props) => {
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      familyNameColumn,
      messageColumnNurse,
      reportedPatientColumnNurse,
      doctorNameColumn,
      telemonitoringColumnNurse,
    ],
    [],
  );

  const table = useReactTable<PatientList>({
    data: patients,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleClick = (patient: PatientList) => {
    navigate(`/patients/${patient.id}`);
  };

  return <MuiTable table={table} handleRowClick={handleClick} />;
};

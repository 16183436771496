import React, { AllHTMLAttributes, PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';

import { IconType } from '@/assets/icons';
import { Row } from '@/components/layout/Flex';
import { useStyles } from '@/hooks/useStyles';

/**
 * @deprecated Use MUI Card instead
 */
export type CardTitleProps = PropsWithChildren<
  {
    className?: string;
  } & AllHTMLAttributes<HTMLDivElement>
>;

/**
 * @deprecated Use MUI Card instead
 */
export const CardTitle: React.FC<CardTitleProps> = ({
  children,
  className,
  ...props
}) => {
  const styles = useStyles(makeStyles);

  return (
    <Row Component="header" className={cx(styles.title, className)} {...props}>
      {children}
    </Row>
  );
};

export type TextCardTitleProps = {
  title: string;
  className?: string;
} & AllHTMLAttributes<HTMLDivElement>;

/**
 * @deprecated Use MUI Card instead
 */
export const TextCardTitle: React.FC<TextCardTitleProps> = ({
  title,
  className,
  ...props
}) => {
  return (
    <CardTitle className={className} {...props}>
      <Typography variant="h6">{title}</Typography>
    </CardTitle>
  );
};

export type IconCardTitleProps = {
  Icon: IconType;
  title: string;
  className?: string;
} & AllHTMLAttributes<HTMLDivElement>;

export const IconCardTitle: React.FC<IconCardTitleProps> = ({
  Icon,
  title,
  className,
  ...props
}) => {
  const styles = useStyles(makeStyles);

  return (
    <CardTitle className={className} {...props}>
      <Icon className={styles.icon} />
      <Typography variant="h6">{title}</Typography>
    </CardTitle>
  );
};

const makeStyles = (theme: Theme) => ({
  title: css`
    align-items: center;
    column-gap: ${theme.spacing(4)};
    padding: ${theme.spacing(8, 12, 4, 12)};
    border-bottom: ${theme.spacing(0.5)} solid ${theme.palette.background.paper};
  `,
  icon: css`
    height: 1em;
    width: 1em;
  `,
});

import React from 'react';

import { css, cx, keyframes } from '@emotion/css';
import { Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';

type LoaderSize = 'S' | 'M' | 'L';

export type LoaderProps = {
  size: LoaderSize;
  thickness?: number;
  className?: string;
};

export const Loader: React.FC<LoaderProps> = ({
  size,
  thickness = 1,
  className,
}) => {
  const styles = useStyles(makeStyles, SizeMatch[size], thickness);

  return (
    <div
      className={cx(styles.loader, className)}
      aria-label="loader"
      aria-busy="true"
      role="progressbar"
    ></div>
  );
};

const SizeMatch: { [K in LoaderSize]: (theme: Theme) => string } = {
  S: theme => theme.spacing(8),
  M: theme => theme.spacing(12),
  L: theme => theme.spacing(20),
};

const makeStyles = (
  theme: Theme,
  size: (theme: Theme) => string,
  thickness: number,
) => ({
  loader: css`
    height: ${size(theme)};
    width: ${size(theme)};
    border: calc(${size(theme)} / 10 * ${thickness}) solid
      ${theme.palette.background.default};
    border-top: calc(${size(theme)} / 10 * ${thickness}) solid
      ${theme.palette.primary.light};
    border-radius: 50%;
    animation: ${spin} 1s cubic-bezier(0.8, 0.3, 0.2, 0.7) infinite;
  `,
});

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }  
`;

import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { Link } from 'react-router-dom';

import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles';

export type LogoProps = {
  to?: string;
};

export const Logo: React.FC<LogoProps> = ({ to = '/' }) => {
  const styles = useStyles(makeStyles);
  return (
    <Link to={to} id="app-logo" className={styles.container}>
      <Icons.logoRoundFull className={styles.img} />
    </Link>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    margin-top: ${theme.spacing(8)};
    margin-bottom: ${theme.spacing(12)};
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
  `,
  img: css`
    color: ${theme.palette.environment};
  `,
});

import { useContext } from 'react';

import { css } from '@emotion/css';

import { AttachmentContext } from '@/components/chat/footer/AttachmentContext';
import { FilePreview } from '@/uiKit/FilePreview';

export const FileListPreview = () => {
  const attachmentContext = useContext(AttachmentContext);
  const handleFileDeletion = (fileIdx: number) => {
    const filesCopy = Array.from(attachmentContext.files);
    filesCopy.splice(fileIdx, 1);
    attachmentContext.setFiles?.(filesCopy);
  };
  const styles = makeStyles();

  return (
    <div className={styles.container}>
      {attachmentContext.files.map((uploadedFile, i) => (
        <FilePreview
          key={i}
          className={styles.content}
          onClick={handleFileDeletion}
          name={uploadedFile.name}
          idx={i}
        />
      ))}
    </div>
  );
};

const makeStyles = () => ({
  container: css`
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
    overflow-x: scroll;
    gap: 0.5rem;
  `,
  content: css`
    flex: 0 1 auto;
  `,
});

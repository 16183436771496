import { DecoderFunction, string } from 'typescript-json-decoder';

import {
  CareTeamParticipant,
  careTeamParticipantDecoder,
} from '@/models/CareTeamParticipantModel';
import { forcedArray, recordWithContext } from '@/utils/decoderUtils';

export type CareTeam = {
  id: string;
  participants: CareTeamParticipant[];
};

export const careTeamDecoder: DecoderFunction<CareTeam> = recordWithContext(
  'CareTeam',
  {
    id: string,
    participants: forcedArray(careTeamParticipantDecoder),
  },
);

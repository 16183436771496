import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { InsulinStatItem } from '@/components/stat/InsulinStatItem';
import { useStyles } from '@/hooks/useStyles';
import { GlobalStats } from '@/models/GlobalStatsModel';
import { largestRemainderRound } from '@/utils/math';

export type InsulinLegendProps = {
  insulin: GlobalStats['insulin'];
  className?: string;
};

export const InsulinLegend: React.FC<InsulinLegendProps> = ({
  insulin,
  className,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const basal = insulin.daily_mean_basal_insulin ?? 0;
  const bolus = insulin.daily_mean_bolus_insulin ?? 0;
  const [ratioBasal, ratioBolus] = largestRemainderRound([basal, bolus]);

  return (
    <div className={cx(styles.container, className)}>
      <InsulinStatItem
        className={styles.item}
        legend={<LegendDot color="basal" />}
        title={`${t('insulin.basalInsulinPerDay')}`}
        percentage={ratioBasal}
        percentagePrecision={0}
        value={insulin.daily_mean_basal_insulin ?? 0}
        unit="U"
        precision={1}
      />
      <InsulinStatItem
        className={styles.item}
        legend={<LegendDot color="bolus" />}
        title={`${t('insulin.bolusInsulinPerDay')}`}
        percentage={ratioBolus}
        percentagePrecision={0}
        value={insulin.daily_mean_bolus_insulin ?? 0}
        unit="U"
        precision={1}
      />
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  item: css``,
});

type LegendDotProps = {
  color: 'basal' | 'bolus';
};

const LegendDot: React.FC<LegendDotProps> = ({ color }) => {
  const styles = useStyles(makeLegendDotStyles, color);

  return <div className={styles.item} />;
};

const makeLegendDotStyles = (theme: Theme, color: 'basal' | 'bolus') => ({
  item: css`
    width: ${theme.spacing(8)};
    height: ${theme.spacing(8)};
    border-radius: 100%;
    border: ${theme.spacing(0.5)} solid ${theme.palette.insulin[color].main};
    background-color: ${theme.palette.insulin[color].contrastText};
  `,
});

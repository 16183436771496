import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { TextCardTitle } from '@/components/card/CardTitle';
import { InsulinChart } from '@/components/data-representations/InsulinChart';
import { Col } from '@/components/layout/Flex';
import { Loader } from '@/components/loading/Loader';
import { useStyles } from '@/hooks/useStyles';
import { InsulinLegend } from '@/pages/patient-monitoring/InsulinStats/InsulinLegend';
import { InsulinOverview } from '@/pages/patient-monitoring/InsulinStats/InsulinOverview';
import { usePatientMonitoringContext } from '@/pages/patient-monitoring/PatientMonitoringState.tsx';
import { Queries } from '@/queries/Queries';

export const InsulinStats: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Card elevation={0}>
      <TextCardTitle title={t('insulin.title')} />
      <CardBody>
        <InsulinStatsBody />
      </CardBody>
    </Card>
  );
};

const InsulinStatsBody: React.FC = () => {
  const styles = useStyles(makeStyles);
  const data = usePatientMonitoringContext(state => ({
    from: state.from,
    to: state.to,
    patientId: state.patient.id,
  }));

  const globalStats = Queries.diabetes.useGlobalStats(data);

  if (!globalStats.isSuccess) {
    return (
      <Col className={styles.loader}>
        <Loader size="L" />
      </Col>
    );
  }
  const insulin = globalStats.data['insulin'];

  return (
    <div className={styles.container}>
      <InsulinOverview insulin={insulin} className={styles.statsOverview} />
      <InsulinChart insulin={insulin} className={styles.statsChart} />
      <InsulinLegend insulin={insulin} className={styles.statsLegend} />
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: clip;
    gap: ${theme.spacing(8)};
    flex-wrap: wrap;
    align-items: stretch;
  `,
  loader: css`
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  `,
  statsOverview: css`
    flex: 0 1;
  `,
  statsChart: css`
    flex: 1 1;
    display: flex;
    flex-direction: row-reverse;
    height: 200px;
    align-self: stretch;
    margin: ${theme.spacing(4)};
  `,
  statsLegend: css`
    flex: 0 1;
  `,
});

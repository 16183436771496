import { stringUnion } from '@/utils/decoderUtils';

export type TeleMonitoringCriteria =
  | 'adult_monitoring_d1'
  | 'adult_discovery_d1'
  | 'teen_monitoring_d1'
  | 'teen_discovery_d1'
  | 'corticotherapie'
  | 'teen_care_gap'
  | 'start_basal_d2'
  | 'monitoring_d2'
  | 'pregnate_d'
  | 'gestational_insulined';

export const teleMonitoringCriteriaDecoder = stringUnion(
  'adult_monitoring_d1',
  'adult_discovery_d1',
  'teen_monitoring_d1',
  'teen_discovery_d1',
  'corticotherapie',
  'teen_care_gap',
  'start_basal_d2',
  'monitoring_d2',
  'pregnate_d',
  'gestational_insulined',
);

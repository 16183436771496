import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { TextCardTitle } from '@/components/card/CardTitle';
import { useStyles } from '@/hooks/useStyles';
import {
  CurrentCarePlan,
  TelemonitoringInfo,
} from '@/pages/patient-settings/CurrentCarePlan';
import { Queries } from '@/queries/Queries';
import { RenewalButton } from '@/uiKit/molecules/RenewalButton';

export type TelemonitoringCardProps = {
  patientId: string;
  className?: string;
};

export const TelemonitoringCard: React.FC<TelemonitoringCardProps> = ({
  patientId,
  className,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  const { data: carePlanData } = Queries.practitioner.useCarePlans(patientId);
  const { data: practitionerData } = Queries.practitioner.usePractitioner({});

  if (!carePlanData || !practitionerData) {
    return null;
  }

  const isNurse = practitionerData.qualification === 'nurse';

  return (
    <Card className={cx(styles.container, className)} elevation={0}>
      <TextCardTitle
        id="tls-title"
        title={t('pages.patientSettings.telemonitoring.title')}
      />
      <CardBody>
        {carePlanData.current_care_plan ? (
          <CurrentCarePlan
            carePlan={carePlanData.current_care_plan}
            patientId={patientId}
          />
        ) : (
          <TelemonitoringInfo patientId={patientId} />
        )}
        {!isNurse ? (
          <div className={styles.alightRight}>
            <RenewalButton patientId={patientId} />
          </div>
        ) : null}
      </CardBody>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(8)};
  `,
  alightRight: css`
    align-self: flex-end;
  `,
});

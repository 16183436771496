import React, { PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { DiabetesParametersType } from '@/models/DiabetesDataModel';

export type ThresholdStep = DiabetesParametersType | 'thresholdNormal';

export type ThresholdStepProps = PropsWithChildren<{
  threshold: ThresholdStep;
  className?: string;
}>;

export const ThresholdStep: React.FC<ThresholdStepProps> = ({
  threshold,
  children,
  className,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  return (
    <div className={cx(styles.container, className)}>
      <div className={cx(styles.box, styles[threshold])}>
        <Typography variant="body">
          {t(`thresholdSettings.${threshold}`)}
        </Typography>
      </div>
      {children}
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css``,
  box: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex: 1 1;
    height: 5rem;
    aspect-ratio: 3;
    border-radius: ${theme.spacing(4)};
    padding: ${theme.spacing(2)};
    overflow: clip;
  `,
  thresholdHypoglycemia: css`
    background-color: ${theme.palette.glycemia.hypoglycemia.contrastText};
    border: 1px solid ${theme.palette.glycemia.hypoglycemia.main};
  `,
  thresholdHyperglycemia: css`
    background-color: ${theme.palette.glycemia.hyperglycemia.contrastText};
    border: 1px solid ${theme.palette.glycemia.hyperglycemia.main};
  `,
  thresholdHypoglycemiaSevere: css`
    background-color: ${theme.palette.glycemia.severeHypoglycemia.contrastText};
    border: 1px solid ${theme.palette.glycemia.severeHypoglycemia.main};
  `,
  thresholdHyperglycemiaSevere: css`
    background-color: ${theme.palette.glycemia.severeHyperglycemia
      .contrastText};
    border: 1px solid ${theme.palette.glycemia.severeHyperglycemia.main};
  `,
  thresholdNormal: css`
    background-color: ${theme.palette.glycemia.normal.contrastText};
    border: 1px solid ${theme.palette.glycemia.normal.main};
  `,
});

import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { DateUtils } from '@/utils/date.ts';

type SelectedPeriodProps = {
  from: DateTime;
  to: DateTime;
};

export const SelectedPeriod: React.FC<SelectedPeriodProps> = ({ from, to }) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  const today = DateTime.now();
  const yesterday = today.minus({ days: 1 });

  const yesterdayLabel = t('periodicity.yesterday');

  const fromTitle =
    DateUtils.compareDates(yesterday, from) == 0
      ? yesterdayLabel
      : from.toLocaleString(DateTime.DATE_SHORT);

  const toTitle =
    DateUtils.compareDates(to, yesterday) == 0
      ? yesterdayLabel
      : to.toLocaleString(DateTime.DATE_SHORT);
  const inputTitle = fromTitle + ' - ' + toTitle;

  return <div className={styles.input}>{inputTitle}</div>;
};

const makeStyles = (theme: Theme) => ({
  input: css`
    height: fit-content;
    padding: ${theme.spacing(4)} ${theme.spacing(8)};
    text-align: center;
    background-color: ${theme.palette.common.white};
    width: 30vh;
  `,
});

import { ReactElement } from 'react';

import { css, cx } from '@emotion/css';
import { Typography } from '@mui/material';
import {
  type FieldPath,
  type FieldPathValue,
  type FieldValues,
  type RegisterOptions,
  type UseControllerReturn,
  type UseFormReturn,
  useController,
} from 'react-hook-form';

import { Tooltip } from '@/components/floating/Tooltip';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useStyles';

export type OptionItem<T extends FieldValues> = {
  id?: string;
  value: FieldPathValue<T, FieldPath<T>>;
  label: string;
  tooltip?: string;
  hidden?: boolean;
};

export type BaseControlledFormInputProps<T extends FieldValues> = {
  name: FieldPath<T>;
  context: UseFormReturn<T>;
  inputProps: Omit<
    RegisterOptions<T, FieldPath<T>>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  options: OptionItem<T>[];
  label?: string;
  className?: string;
  labelStyle?: string;
  radioContainerStyle?: string;
  radioInputStyle?: string;
  radioLabelStyle?: string;
};

export const BaseRadioFormInput = <T extends FieldValues>({
  name,
  context,
  inputProps: { disabled, ...inputProps },
  options,
  label,
  className,
  labelStyle,
  radioContainerStyle,
  radioInputStyle,
  radioLabelStyle,
}: BaseControlledFormInputProps<T>): ReactElement => {
  const inputController = useController({
    name,
    control: context.control,
    rules: inputProps,
    disabled: disabled,
  });

  return (
    <fieldset className={className} {...context.register(name, inputProps)}>
      {label ? (
        <legend>
          <Typography variant="h6" className={labelStyle}>
            {label}
          </Typography>
        </legend>
      ) : null}
      {options.map(item => (
        <BaseRadioOptionFormInput
          key={item.label}
          item={item}
          containerStyle={radioContainerStyle}
          inputStyle={radioInputStyle}
          labelStyle={radioLabelStyle}
          {...inputController}
        />
      ))}
    </fieldset>
  );
};

export type BaseRadioOptionFormInputProps<T extends FieldValues> = {
  containerStyle?: string;
  inputStyle?: string;
  labelStyle?: string;
  item: OptionItem<T>;
} & UseControllerReturn<T>;

export const BaseRadioOptionFormInput = <T extends FieldValues>({
  containerStyle,
  inputStyle,
  labelStyle,
  item,
  field,
}: BaseRadioOptionFormInputProps<T>): ReactElement => {
  const styles = useStyles(makeStyles);
  const option = (
    <div className={cx(containerStyle, item.hidden ? styles.hidden : '')}>
      <input
        type="radio"
        className={inputStyle}
        id={item.id ?? item.value}
        value={item.value}
        checked={item.value === field.value}
        disabled={field.disabled}
        onChange={field.onChange}
        onBlur={field.onBlur}
        hidden={item.hidden}
      />
      <Typo
        type="radio"
        Component="label"
        htmlFor={item.id ?? item.value}
        className={cx(styles.label, labelStyle)}
      >
        {item.label}
      </Typo>
    </div>
  );
  return item.tooltip ? (
    <Tooltip
      placement="bottom-start"
      hover={{ handleClose: null }}
      content={
        <Typo type="paragraphSmall" className={styles.text}>
          {item.tooltip}
        </Typo>
      }
    >
      {option}
    </Tooltip>
  ) : (
    option
  );
};

const makeStyles = () => ({
  hidden: css`
    display: none;
  `,
  label: css`
    user-select: none;
  `,
  text: css`
    white-space: pre-wrap;
  `,
});

import NunitoSansBlack from '@/assets/fonts/NunitoSans-Black.ttf';
import NunitoSansBlackItalic from '@/assets/fonts/NunitoSans-BlackItalic.ttf';
import NunitoSansBold from '@/assets/fonts/NunitoSans-Bold.ttf';
import NunitoSansBoldItalic from '@/assets/fonts/NunitoSans-BoldItalic.ttf';
import NunitoSansExtraBold from '@/assets/fonts/NunitoSans-ExtraBold.ttf';
import NunitoSansExtraBoldItalic from '@/assets/fonts/NunitoSans-ExtraBoldItalic.ttf';
import NunitoSansExtraLight from '@/assets/fonts/NunitoSans-ExtraLight.ttf';
import NunitoSansExtraLightItalic from '@/assets/fonts/NunitoSans-ExtraLightItalic.ttf';
import NunitoSansItalic from '@/assets/fonts/NunitoSans-Italic.ttf';
import NunitoSansLight from '@/assets/fonts/NunitoSans-Light.ttf';
import NunitoSansLightItalic from '@/assets/fonts/NunitoSans-LightItalic.ttf';
import NunitoSansRegular from '@/assets/fonts/NunitoSans-Regular.ttf';
import NunitoSansSemiBold from '@/assets/fonts/NunitoSans-SemiBold.ttf';
import NunitoSansSemiBoldItalic from '@/assets/fonts/NunitoSans-SemiBoldItalic.ttf';
import {
  AdvancedFont,
  DimensionsStructure,
  FontValues,
  FontsStructure,
} from '@/theme/types';

export const Fonts = {
  NunitoSans: {
    Regular: {
      200: NunitoSansExtraLight,
      300: NunitoSansLight,
      400: NunitoSansRegular,
      600: NunitoSansSemiBold,
      700: NunitoSansBold,
      800: NunitoSansExtraBold,
      900: NunitoSansBlack,
    },
    Italic: {
      200: NunitoSansExtraLightItalic,
      300: NunitoSansLightItalic,
      400: NunitoSansItalic,
      600: NunitoSansSemiBoldItalic,
      700: NunitoSansBoldItalic,
      800: NunitoSansExtraBoldItalic,
      900: NunitoSansBlackItalic,
    },
  },
} satisfies FontsStructure;

type FontName = keyof typeof Fonts;
type FontDefinitionType = { [key in FontName]: string };

const generateFontDefinition = (
  fontName: FontName,
  font: FontValues,
  italic: boolean,
) =>
  Object.entries(font)
    .map(
      ([key, value]) =>
        ` @font-face {
        font-family: ${fontName};
        src: url(${value}) format('truetype');
        font-weight: ${key};
        font-style: ${italic ? 'italic' : 'normal'};
        font-stretch: normal;
      }`,
    )
    .join('\n');

const mapFont = (
  fontName: FontName,
  font: FontValues | AdvancedFont,
): string => {
  if ('Regular' in font) {
    return [
      generateFontDefinition(fontName, font.Regular, false),
      generateFontDefinition(fontName, font.Italic, true),
    ].join('\n');
  } else {
    return generateFontDefinition(fontName, font, false);
  }
};

export const FontDefinitions: FontDefinitionType = {
  NunitoSans: mapFont('NunitoSans', Fonts.NunitoSans),
};

export const FontsList: { [key in FontName]: key } = {
  NunitoSans: 'NunitoSans',
};

export const FontSizes = {
  captionSmall: '0.625rem', // 10px
  caption: '0.75rem', // 12px
  buttonSmall: '0.875rem', // TODO 14px
  button: '1rem', // TODO 16px
  bodySmall: '0.875rem', // 14px
  body: '1rem', // 16px
  bodyLarge: '1.1rem', // 20px
  subtitle: '1rem', // TODO 16px
  subtitleLarge: '1.25rem', // TODO 20px
  h6: '1.25rem', // TODO 20px
  h5: '1.25rem', // 20px
  h4: '1.7rem', // 32px
  h3: '2.5rem', // TODO 40px
  h2: '3rem', // TODO 48px
  h1: '3.5rem', // TODO 56px
} satisfies DimensionsStructure;

import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { PatientList } from '@/models/PatientModel';

import { PatientForDoctor } from '../PatientDoctorTable';
import { ReportedPatientCell } from '../cells/ReportedPatientCell';

const columnHelper = createColumnHelper<PatientForDoctor | PatientList>();

export const reportedPatientColumnNurse = columnHelper.accessor(
  'hasNurseNotice',
  {
    cell: info => (
      <ReportedPatientCell
        hasNurseNotice={info.getValue()}
        roleForPatient="nurse"
      />
    ),
    header: () => {
      const { t } = useTranslation();
      return <Typography>{t('pages.patients.toConsult')}</Typography>;
    },
    size: 13,
    maxSize: 13,
  },
);

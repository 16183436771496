import { useId } from '@floating-ui/react';
import {
  FormControl,
  FormControlOwnProps,
  InputLabel,
  Select,
  SelectProps,
} from '@mui/material';
import { InputLabelOwnProps } from '@mui/material/InputLabel/InputLabel';
import { FieldPath, FieldValues, useController } from 'react-hook-form';

type PropsSelectionFromForm<Form extends FieldValues> = {
  [K in FieldPath<Form>]: {
    name: K;
  };
}[FieldPath<Form>];

type CommonSelectProps = 'children' | 'defaultValue';
type OverwrittenSelectProps =
  | 'ref'
  | 'value'
  | 'disabled'
  | 'label'
  | CommonSelectProps;
/**
 * The `ref` and `value` props are managed by react-hook-form
 * The
 */
type FilteredSelectProps = {
  label?: string;
  labelProps?: InputLabelOwnProps;
  selectProps?: Omit<SelectProps, OverwrittenSelectProps>;
} & FormControlOwnProps &
  Pick<SelectProps, CommonSelectProps>;

type FormSelectProps<Form extends FieldValues> = PropsSelectionFromForm<Form> &
  FilteredSelectProps;

export const FormSelect = <Form extends FieldValues>({
  name,
  label,
  labelProps,
  selectProps,
  children,
  defaultValue,
  variant = 'standard',
  ...props
}: FormSelectProps<Form>) => {
  const { field, fieldState } = useController<Form>({
    name,
    disabled: props.disabled,
  });
  const id = useId();
  return (
    <FormControl variant={variant} error={fieldState.invalid} {...props}>
      {label ? (
        <InputLabel
          htmlFor={id}
          sx={theme => ({
            backgroundColor: theme.palette.background.paper,
          })}
          {...labelProps}
        >
          {label}
        </InputLabel>
      ) : null}
      <Select
        id={id}
        defaultValue={defaultValue}
        {...selectProps}
        onChange={(event, child) => {
          field.onChange(event);
          selectProps?.onChange?.(event, child);
        }}
        onBlur={event => {
          field.onBlur();
          selectProps?.onBlur?.(event);
        }}
        value={field.value}
      >
        {children}
      </Select>
    </FormControl>
  );
};

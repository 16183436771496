import React, { PropsWithChildren } from 'react';

import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';

import { FontSizes } from '@/assets/fonts';
import { Icons } from '@/assets/icons';
import { IconButton } from '@/components/button/IconButton';
import { Row } from '@/components/layout/Flex';
import { useStyles } from '@/hooks/useStyles';
import { FileModel } from '@/models/FileModel';

export type FileViewProps = PropsWithChildren<{
  file: FileModel;
  onDelete?: () => void;
}>;

export const FileView: React.FC<FileViewProps> = ({
  file,
  children,
  onDelete = undefined,
}) => {
  const styles = useStyles(makeStyles);

  return (
    <Row align="center" width="100%" className={styles.container}>
      <Typography variant="body">{file.name}</Typography>
      <IconButton
        className={styles.button}
        onClick={() => window.open(file.url, '_blank')}
        iconClassname={styles.icon}
        icon={Icons.download}
        buttonType="plain"
        placement="left"
      />
      {onDelete && (
        <IconButton
          className={styles.button}
          onClick={onDelete}
          iconClassname={styles.icon}
          icon={Icons.trash}
          buttonType="plain"
          placement="left"
        />
      )}
      {children}
    </Row>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    gap: ${theme.spacing(4)};
  `,
  pdf: css`
    margin-top: ${theme.spacing(4)};
  `,
  button: css`
    padding: ${theme.spacing(2)};
    :hover {
      background-color: ${theme.palette.action.hover};
    }
    color: ${theme.palette.primary.main};
  `,
  icon: css`
    height: ${FontSizes.body};
    width: ${FontSizes.body};
    margin-right: 0;
  `,
});

import React from 'react';

import { css } from '@emotion/css';
import { Typography } from '@mui/material';
import { toast } from 'react-toastify';

export const SimpleTextToast: React.FC<{ text: string }> = ({ text }) => (
  <Typography variant="body">{text}</Typography>
);

export const TextToastWithSubtext: React.FC<{
  text: string;
  subtext: string;
}> = ({ text, subtext }) => (
  <div
    className={css`
      display: flex;
      flex-direction: column;
    `}
  >
    <Typography variant="body">{text}</Typography>
    <Typography variant="caption">{subtext}</Typography>
  </div>
);

export const TextToastSwitch: React.FC<{ text: string; subtext?: string }> = ({
  text,
  subtext,
}) => {
  switch (true) {
    case subtext !== undefined:
      return <TextToastWithSubtext text={text} subtext={subtext} />;
    default:
      return <SimpleTextToast text={text} />;
  }
};

export const TextToast = {
  info: (text: string, subtext?: string) =>
    toast.info(<TextToastSwitch text={text} subtext={subtext} />),
  success: (text: string, subtext?: string) =>
    toast.success(<TextToastSwitch text={text} subtext={subtext} />),
  warning: (text: string, subtext?: string) =>
    toast.warning(<TextToastSwitch text={text} subtext={subtext} />),
  error: (text: string, subtext?: string) =>
    toast.error(<TextToastSwitch text={text} subtext={subtext} />),
};

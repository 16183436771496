import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { Loader } from '@/components/loading/Loader';
import { useStyles } from '@/hooks/useStyles';
import { BGMContentrationType } from '@/models/BGMLogbook';
import { Condition } from '@/models/ConditionModel.ts';
import { DiabetesParameters } from '@/models/DiabetesDataModel.ts';
import { usePatientMonitoringContext } from '@/pages/patient-monitoring/PatientMonitoringState.tsx';
import { Queries } from '@/queries/Queries.ts';
import {
  GestationalThresholds,
  gestationalThresholds,
} from '@/utils/gestationalThresholds.ts';

import { GlycemiaBadge } from './GlycemiaBadge';

export type GlycemiaBadgesProps = {
  data: BGMContentrationType[];
  meal: 'breakfast' | 'lunch' | 'dinner';
  timing: 'before' | 'after';
};

export const GlycemiaBadges = ({ data, meal, timing }: GlycemiaBadgesProps) => {
  const styles = useStyles(makeStyles);
  const patientId = usePatientMonitoringContext(state => state.patient.id);
  const condition = Queries.condition.useCondition(patientId);
  const glycemiaParameters = Queries.diabetes.useParameters(patientId);

  return (
    <div className={styles.container}>
      {glycemiaParameters.isSuccess && condition.isSuccess ? (
        data.map((val, i) => (
          <div key={i}>
            <GlycemiaBadge
              glycemiaValue={val}
              thresholds={threshold(
                condition.data,
                meal,
                timing,
                glycemiaParameters.data,
                gestationalThresholds,
              )}
            />
          </div>
        ))
      ) : (
        <Loader size="S" />
      )}
    </div>
  );
};

const threshold = (
  condition: Condition,
  meal: 'breakfast' | 'lunch' | 'dinner',
  timing: 'before' | 'after',
  diabetesParameters: DiabetesParameters,
  gestationalThresholds: GestationalThresholds,
) => {
  if (condition.type !== 'gestational') {
    return diabetesParameters;
  }
  if (meal === 'breakfast' && timing === 'before') {
    return gestationalThresholds.beforeBreakfast;
  }
  if (timing === 'before') {
    return gestationalThresholds.beforeMeal;
  }
  return gestationalThresholds.afterMeal;
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `,
});

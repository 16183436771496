import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { TextCardTitle } from '@/components/card/CardTitle';
import { Loader } from '@/components/loading/Loader';
import { useStyles } from '@/hooks/useStyles';
import {
  DeviceType,
  MedicalDevice as MedicalDeviceModel,
} from '@/models/MedicalDeviceModel';
import { MedicalDevice } from '@/pages/patient-medical-file/MedicalDevice';
import { Queries } from '@/queries/Queries';
import { toDateTime } from '@/utils/date.ts';

const IGNORED_DEVICES = {
  manual: undefined,
  'libre link': undefined,
};

type MedicalDevicesProps = {
  patientId: string;
  className?: string;
};

export const MedicalDeviceList: React.FC<MedicalDevicesProps> = ({
  patientId,
  className,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const medicalDevicesQuery = Queries.diabetes.useMedicalDevices(patientId);

  return (
    <Card elevation={0} className={cx(styles.container, className)}>
      <TextCardTitle title={t('pages.patientMedicalFile.devices')} />
      <CardBody className={styles.content}>
        {medicalDevicesQuery.isSuccess ? (
          dedupeMD(medicalDevicesQuery.data).map(medicalDevice => (
            <MedicalDevice key={medicalDevice.id} device={medicalDevice} />
          ))
        ) : (
          <Loader size="M" />
        )}
      </CardBody>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css``,
  content: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(8)};
  `,
});

const findMDbyType = (type: DeviceType, list: MedicalDeviceModel[]) =>
  list.find(elem => elem.kind === type);

const filterMDbyType = (type: DeviceType, list: MedicalDeviceModel[]) =>
  list.filter(device => device.kind !== type);

const dedupeMD = (medicalDevices: MedicalDeviceModel[]) =>
  medicalDevices
    .filter(device => !(device.name in IGNORED_DEVICES))
    .reduce((devices, md) => {
      const occurence = findMDbyType(md.kind, devices);
      if (occurence) {
        if (toDateTime(md.lastUpload) > toDateTime(occurence.lastUpload)) {
          return [...filterMDbyType(occurence.kind, devices), md];
        }
        return devices;
      }
      return [...devices, md];
    }, [] as MedicalDeviceModel[]);

import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { PatientList } from '@/models/PatientModel';

import { PatientForDoctor } from '../PatientDoctorTable';
import { MessageCellDoctor } from '../cells/MessageCellDoctor';

const columnHelper = createColumnHelper<PatientForDoctor | PatientList>();

export const messageColumnDoctor = columnHelper.accessor('message', {
  cell: info => <MessageCellDoctor {...info.getValue()} />,
  header: () => {
    const { t } = useTranslation();
    return (
      <div>
        <Typography>{t('pages.patients.messages')}</Typography>
      </div>
    );
  },
  size: 18,
  maxSize: 18,
});

Object.defineProperty(String.prototype, 'capitalize', {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
});

Object.defineProperty(String.prototype, 'capitalizeAll', {
  value: function () {
    const splitters = [' ', '-', '_', "'", '\t'];
    let value: string = this.valueOf().toLowerCase();
    for (const splitter of splitters) {
      value = value
        .split(splitter)
        .map(word => word.capitalize())
        .join(splitter);
    }
    return value;
  },
  enumerable: false,
});

type SnakeToCamel<T extends string> = T extends `${infer F}_${infer R}`
  ? `${F}${Capitalize<SnakeToCamel<R>>}`
  : T;

export const snakeToCamel = <T extends string>(str: T): SnakeToCamel<T> => {
  return str.replace(/(?!^)_(.)/g, (_, char) =>
    char.toUpperCase(),
  ) as SnakeToCamel<T>;
};

import React, { useEffect, useMemo } from 'react';

import { css } from '@emotion/css';
import { Input, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import {
  inputStyle,
  textStyle,
} from '@/pages/patient-settings/alert-settings/inputs/commonStyles.ts';

export type HappenedInTheLastXDaysProps = {
  onDaysChange: (days: number) => void;
  beforeText?: string;
  minDays: number;
  maxDays: number;
  days: number;
};

export const HappenedInTheLastXDays: React.FC<HappenedInTheLastXDaysProps> = ({
  onDaysChange,
  beforeText,
  minDays,
  maxDays,
  days,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const [localDays, setLocalDays] = React.useState(days.toString());
  const commonInputStyles = useStyles(inputStyle, localDays);
  const textStyles = useStyles(textStyle);

  useEffect(() => {
    setLocalDays(days.toString());
  }, [days]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const value = parseInt(localDays);
      if (
        !isNaN(value) &&
        value >= minDays &&
        (!maxDays || value <= maxDays) &&
        value !== days
      ) {
        onDaysChange(value);
      }
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [localDays, minDays, maxDays, days, onDaysChange]);

  const isError = useMemo(() => {
    const value = parseInt(localDays);
    return isNaN(value) || value < minDays || (!!maxDays && value > maxDays);
  }, [localDays, minDays, maxDays]);

  return (
    <div className={styles.container}>
      {beforeText && (
        <Typography variant="body" className={textStyles}>
          {beforeText}
        </Typography>
      )}
      <Typography variant="body" className={textStyles}>
        {t('alertConfig.inThe')}
      </Typography>
      <Input
        className={commonInputStyles}
        onChange={e => {
          setLocalDays(e.target.value);
        }}
        error={isError}
        value={localDays}
      />
      <Typography variant="body" className={textStyles}>
        {t('alertConfig.lastDays')}
      </Typography>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.spacing(4)};
    flex-wrap: wrap;
  `,
});

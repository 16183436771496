import { DecoderFunction, field, string } from 'typescript-json-decoder';

import { Gender, genderDecoder } from '@/models/GenderModel';
import { Profession, professionDecoder } from '@/models/ProfessionModel';
import { User, userDecoder } from '@/models/UserModel';
import {
  deepField,
  nullOrUndef,
  recordWithContext,
} from '@/utils/decoderUtils';

/*********************************
 *         Practitioner          *
 *********************************/

export type Practitioner = {
  id: string;
  user: User;
  givenName: string;
  familyName: string;
  contactPhone?: string;
  addressLine?: string;
  addressCity?: string;
  addressPostalCode?: string;
  addressCountry?: string;
  gender?: Gender;
  birthDate?: string;

  qualification: Profession;

  franceRPPS?: string;
  updatedAt: string;
  createdAt: string;
};

export type PractitionerSummary = {
  id: string;
  givenName: string;
  familyName: string;
  contactPhone?: string;
  qualification: string;
};

export const practitionerDecoder: DecoderFunction<Practitioner> =
  recordWithContext('Practitioner', {
    id: deepField('user.id', string),
    user: userDecoder,
    givenName: string,
    familyName: string,
    contactPhone: nullOrUndef(string),
    addressLine: nullOrUndef(string),
    addressCity: nullOrUndef(string),
    addressPostalCode: nullOrUndef(string),
    addressCountry: nullOrUndef(string),
    gender: nullOrUndef(genderDecoder),
    birthDate: nullOrUndef(string),

    qualification: professionDecoder,

    franceRPPS: nullOrUndef(string),
    updatedAt: string,
    createdAt: string,
  });

export const practitionerSummaryDecoder: DecoderFunction<PractitionerSummary> =
  recordWithContext('PractitionerSummary', {
    id: field('user_id', string),
    givenName: string,
    familyName: string,
    contactPhone: nullOrUndef(string),
    qualification: professionDecoder,
  });

export type UpdatePractitioner = {
  givenName?: string;
  familyName?: string;
  contactPhone?: string;
  addressLine?: string;
  addressCity?: string;
  addressPostalCode?: string;
  addressCountry?: string;
  gender?: Gender;
  birthDate?: string;
  qualification?: Profession;
};

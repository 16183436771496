import React, { createContext } from 'react';

type AttachmentType = {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>> | undefined;
};
export const AttachmentContext = createContext<AttachmentType>({
  files: [],
  setFiles: undefined,
});

import React from 'react';

import { css } from '@emotion/css';
import { Card, CardContent, CardHeader, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { ThresholdSettings } from '@/uiKit/molecules/thresholds/ThresholdSettings.tsx';
import { gestationalThresholds } from '@/utils/gestationalThresholds.ts';

export const GestationalThresholdSettings: React.FC = () => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  return (
    <Card className={styles.container}>
      <CardHeader title={t('thresholdSettings.gestationalThresholds')} />
      <CardContent className={styles.content}>
        <ThresholdSettings
          title={t('thresholdSettings.beforeBreakfast')}
          type="gestational"
          diabetesParameters={gestationalThresholds.beforeBreakfast}
          inputProps={() => ({ disabled: true })}
          className={styles.step}
          onSubmit={() => {}}
        />

        <ThresholdSettings
          title={t('thresholdSettings.beforeMeal')}
          type="gestational"
          diabetesParameters={gestationalThresholds.beforeMeal}
          inputProps={() => ({ disabled: true })}
          className={styles.step}
          onSubmit={() => {}}
        />
        <ThresholdSettings
          title={t('thresholdSettings.afterMeal')}
          type="gestational"
          diabetesParameters={gestationalThresholds.afterMeal}
          inputProps={() => ({ disabled: true })}
          className={styles.step}
          onSubmit={() => {}}
        />
      </CardContent>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css``,
  content: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${theme.spacing(8)};
  `,
  step: css`
    flex-grow: 1;
  `,
});

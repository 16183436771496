import React, { useCallback, useEffect, useMemo } from 'react';

import { Card, CardHeader } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { isScrollNearBottom, useScroll } from '@/hooks/useScroll.ts';
import { usePatientMonitoringContext } from '@/pages/patient-monitoring/PatientMonitoringState.tsx';
import { Queries } from '@/queries/Queries.ts';
import { DateUtils } from '@/utils/date.ts';

import { BGMContainerBody } from './BGMContainerBody';

export const BGMContainer = () => {
  const { t } = useTranslation();
  const request = usePatientMonitoringContext(state => state);
  const isToYesterday =
    DateUtils.compareDates(request.to, DateTime.now()) === -1;

  const scroll = useScroll();

  useEffect(() => {
    if (isScrollNearBottom(scroll)) {
      fetchNextPage();
    }
  });

  const bgmRequest = Queries.diabetes.useInfiniteBGMLogbook({
    patientId: request.patient.id,
    from: request.to.minus({ days: 30 }).startOf('week'),
    to: isToYesterday ? request.to.plus({ day: 1 }) : request.to,
  });

  const data = useMemo(
    () => (bgmRequest.isSuccess ? bgmRequest.data.pages.flat() : []),
    [bgmRequest.isSuccess, bgmRequest.data],
  );

  const fetchNextPage = useCallback(() => {
    bgmRequest.fetchNextPage({ cancelRefetch: false });
  }, []);

  return (
    <Card elevation={0}>
      <CardHeader title={t('bgmLogBook.cardTitle')} />
      <MemoizedBGMBody data={data} />
    </Card>
  );
};

const MemoizedBGMBody = React.memo(BGMContainerBody);

import {
  ApiDataSourceSpec,
  apiDataSource,
} from '@/io/datasource/ApiDatasource';
import { ApiErrorResponse } from '@/models/ApiErrorResponse';
import { FileModel, fileDecoder } from '@/models/FileModel';
import {
  GeneratePrescriptionData,
  Prescription,
} from '@/models/PrescriptionGenerationModel';
import { Result } from '@/utils/Result';

/**
 * Generate a prescription
 */
export class PrescriptionRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  generatePrescription = (
    data: GeneratePrescriptionData,
  ): Promise<Result<Prescription, ApiErrorResponse>> => {
    return this.datasource.post(
      'prescriptions/create/',
      (value: unknown) => value as Prescription,
      data,
    );
  };

  signPrescription = (
    data: GeneratePrescriptionData,
  ): Promise<Result<FileModel, ApiErrorResponse>> => {
    return this.datasource.post('prescriptions/sign/', fileDecoder, data);
  };
}

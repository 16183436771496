import React, { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useStyles';

export type ShowApiErrorProps = {
  errorMapping: Record<string, string>;
  className?: string;
  error?: string;
  errorData?: unknown;
};

export const ShowApiError: React.FC<ShowApiErrorProps> = ({
  className,
  errorMapping,
  error,
  errorData,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const errorTranslationData =
    errorData && typeof errorData === 'object'
      ? (errorData as Record<string, unknown>)
      : {};

  const text = useMemo(() => {
    if (error && error in errorMapping) {
      return errorMapping[error];
    } else if (error === undefined) {
      return undefined;
    } else {
      return 'errors.unknownError';
    }
  }, [error, errorMapping]);

  return (
    <div className={cx(className, styles.container)}>
      {text ? <Typo type="error">{t(text, errorTranslationData)}</Typo> : null}
    </div>
  );
};

const makeStyles = () => ({
  container: css`
    height: 2.5em;
  `,
});

import React, { PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

import { FontSizes } from '@/assets/fonts';
import { Icons } from '@/assets/icons';
import { Col, Row } from '@/components/layout/Flex';
import { useStyles } from '@/hooks/useStyles';

export type PageContainersProps = PropsWithChildren<{
  title: string;
  back?: boolean;
  className?: string;
  titleClassName?: string;
}>;

export const PageContainer: React.FC<PageContainersProps> = ({
  title,
  back = false,
  className,
  titleClassName,
  children,
}) => {
  const styles = useStyles(makeStyles);
  const navigate = useNavigate();

  return (
    <Col className={cx(styles.container, className)}>
      <title>{`Steto - ${title}`}</title>
      <Row className={cx(styles.titleRow, titleClassName)}>
        {back ? (
          <Icons.arrowLeft
            className={styles.goBack}
            onClick={() => navigate(-1)}
          />
        ) : null}
        <Typography variant="h4">{title}</Typography>
      </Row>
      {children}
    </Col>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css``,
  titleRow: css`
    align-items: center;
  `,
  goBack: css`
    fill: ${theme.palette.grey[900]};
    width: calc(${theme.spacing(8)} * 2 + ${FontSizes.h4});
    height: calc(${theme.spacing(4)} * 2 + ${FontSizes.h4});
    padding: ${theme.spacing(2, 4)};
    margin-right: ${theme.spacing(8)};
    cursor: pointer;
    &:hover {
      background-color: ${theme.palette.background.paper};
      border-radius: ${theme.spacing(4)};
    }
  `,
});

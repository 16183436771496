import React from 'react';

import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AlertCategoriesTranslations } from '@/components/notifications/ActiveNotifications.tsx';
import { TextToast } from '@/components/toast/TextToast.tsx';
import { useStyles } from '@/hooks/useStyles';
import {
  AlertConfig,
  HyperglycemiaTarAlertParameters,
} from '@/models/AlertModel.ts';
import { HappenedInTheLastXDays } from '@/pages/patient-settings/alert-settings/inputs/HappenedInTheLastXDays.tsx';
import { NumberOfOccurrences } from '@/pages/patient-settings/alert-settings/inputs/NumberOfOccurrences.tsx';
import { SelectType } from '@/pages/patient-settings/alert-settings/inputs/SelectType.tsx';
import { Queries } from '@/queries/Queries.ts';

export type AlertHyperglycemiaTarSettingsProps = {
  patientId: string;
  alertConfig: AlertConfig;
  parameters: HyperglycemiaTarAlertParameters;
};

export const AlertHyperglycemiaTarSettings: React.FC<
  AlertHyperglycemiaTarSettingsProps
> = ({ parameters, patientId, alertConfig }) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const editAlertConfig = Queries.alerts.useEditAlertConfig();
  const updateParameters = (
    value: unknown,
    key: keyof HyperglycemiaTarAlertParameters,
  ) => {
    editAlertConfig.mutate(
      {
        patientId,
        alertConfig: {
          id: alertConfig.id,
          parameters: { ...parameters, [key]: value },
        },
      },
      {
        onSuccess: () =>
          TextToast.success(
            t('alertConfig.conditionsSuccess', {
              alert: t(AlertCategoriesTranslations.hyperglycemia_tar),
            }),
          ),
        onError: () =>
          TextToast.error(
            t('alertConfig.conditionsError', {
              alert: t(AlertCategoriesTranslations.hyperglycemia_tar),
            }),
          ),
      },
    );
  };

  return (
    <div className={styles.container}>
      <NumberOfOccurrences
        textBefore={t('alertConfig.moreThan')}
        textAfter={`%`}
        onCountChange={count => updateParameters(count / 100.0, 'max_tar')}
        minCount={0}
        maxCount={100}
        count={parameters.max_tar * 100}
      />
      <Typography variant="body">
        {t('alertConfig.timeSpentInHyper')}
      </Typography>
      <SelectType
        onSelectChange={type => updateParameters(type, 'type')}
        options={[
          { value: 1, text: t('alertConfig.highOrVeryHigh') },
          { value: 2, text: t('alertConfig.veryHigh') },
        ]}
        selected={parameters.type}
      />
      <HappenedInTheLastXDays
        onDaysChange={days => updateParameters(days, 'n_days')}
        minDays={1}
        maxDays={365}
        days={parameters.n_days}
      />
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `,
});

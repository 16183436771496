import { css } from '@emotion/css';
import { Paper, Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';

type PaperProps = {
  variant?: 'elevation' | 'outlined';
  color?: 'primary' | 'secondary';
  isSquare?: boolean;
  padding?: number;
};

export const InformationPaper = ({
  variant = undefined,
  color = undefined,
  children,
  isSquare = false,
  padding = 8,
}: PaperProps & React.PropsWithChildren) => {
  const styles = useStyles(makeStyles, isSquare, padding);
  return (
    <Paper variant={variant} color={color} className={styles.paper}>
      {children}
    </Paper>
  );
};

const makeStyles = (theme: Theme, isSquare: boolean, padding: number) => ({
  paper: css`
    padding: ${theme.spacing(padding)};
    background-color: ${theme.palette.primary.background};
    border-radius: ${isSquare ? theme.spacing(2) : theme.spacing(8)};
  `,
});

import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { ToastContainer, ToastContainerProps } from 'react-toastify';

import { useStyles } from '@/hooks/useStyles';

export const defaultToastOptions: ToastContainerProps = {
  pauseOnHover: true,
  pauseOnFocusLoss: false,
  closeOnClick: true,
  autoClose: 3000,
  position: 'top-right',
  draggable: true,
  rtl: false,
  role: 'alert',
  stacked: true,
};

export const CustomToastContainer: React.FC = () => {
  const styles = useStyles(makeStyles);
  return <ToastContainer {...defaultToastOptions} className={styles} />;
};

const makeStyles = (theme: Theme) => css`
  --toastify-color-light: ${theme.palette.common.white};
  --toastify-color-dark: ${theme.palette.primary.main};
  --toastify-color-info: ${theme.palette.info.light};
  --toastify-color-success: ${theme.palette.success.light};
  --toastify-color-warning: ${theme.palette.warning.light};
  --toastify-color-error: ${theme.palette.error.light};
  --toastify-color-transparent: ${theme.palette.common.white}B3;

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: var(--toastify-color-light);
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: ${theme.typography.fontFamily};
  --toastify-z-index: ${theme.zIndex.snackbar};

  --toastify-text-color-light: ${theme.palette.text.primary};
  --toastify-text-color-dark: var(--toastify-color-light);

  --toastify-spinner-color: var(--toastify-color-dark);
  --toastify-spinner-color-empty-area: ${theme.palette.grey['100']};
`;

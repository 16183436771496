import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';
import { StyleType } from '@/uiKit/StyleTypes';
import { InputElementProps } from '@/uiKit/input/radio/InputInterface';

export const Radio = ({
  label,
  id,
  disabled = false,
  className,
  ...props
}: InputElementProps & StyleType) => {
  const styles = useStyles(makeStyles);
  return (
    <div className={styles.radio}>
      <input
        type="radio"
        id={id}
        disabled={disabled}
        {...props}
        className={styles.radioInput}
      />
      <Typography
        variant="body"
        component="label"
        htmlFor={id}
        className={cx(styles.radioLabel, className)}
      >
        {label}
      </Typography>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  radio: css`
    & > input:checked + label {
      background: ${theme.palette.primary.background};
      :before {
        justify-content: center;
      }
    }
  `,
  radioInput: css`
    display: none;
  `,
  radioLabel: css`
    white-space: nowrap;
    border-radius: 80px;
    padding: ${theme.spacing(2)} ${theme.spacing(8)};
    cursor: pointer;
    color: ${theme.palette.grey[900]};
    font-variant-numeric: lining-nums tabular-nums;

    /* body/sm-regular */
    font-style: normal;
  `,
});

import { useEffect, useState } from 'react';

export type ScrollState =
  | {
      state: 'initial';
      previous: undefined;
      current: 0;
    }
  | {
      state: 'scrolled';
      previous: number;
      current: number;
    };

export const initialScrollState: ScrollState = {
  state: 'initial',
  previous: undefined,
  current: 0,
};

export const useScroll = () => {
  const [scrollPosition, setScroll] = useState<ScrollState>(initialScrollState);

  useEffect(() => {
    const scrollHandler = () => {
      setScroll(state => ({
        state: 'scrolled',
        previous: state.current,
        current: window.scrollY,
      }));
    };

    window.addEventListener('scroll', scrollHandler, { passive: true });
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return scrollPosition;
};

export const isScrollNearBottom = (scroll: ScrollState, threshold = 0.9) => {
  const scrollingElement = document.scrollingElement;
  if (!scrollingElement) {
    // If there is no scrolling, we are at the bottom
    return true;
  }
  return (
    window.innerHeight + scroll.current >=
    scrollingElement.scrollHeight * threshold
  );
};

import {
  ApiDataSourceSpec,
  apiDataSource,
} from '@/io/datasource/ApiDatasource';
import { ApiErrorResponse } from '@/models/ApiErrorResponse';
import {
  MailPreference,
  MailPreferenceUpdate,
  mailPreferenceDecoder,
  mailPreferenceUpdaterDecoder,
} from '@/models/MailPreferenceModel';
import { Result } from '@/utils/Result';
import { forcedArray } from '@/utils/decoderUtils';

export class MailPreferenceRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  getPreferences = (): Promise<Result<MailPreference[], ApiErrorResponse>> => {
    return this.datasource.get(
      '/mail-preferences',
      forcedArray(mailPreferenceDecoder),
    );
  };

  updatePreferences = (
    ids: MailPreferenceUpdate[],
  ): Promise<Result<MailPreferenceUpdate[], ApiErrorResponse>> => {
    return this.datasource.put(
      '/mail-preferences',
      forcedArray(mailPreferenceUpdaterDecoder),
      ids,
    );
  };
}

import * as React from "react";
const SvgCe = (props) => /* @__PURE__ */ React.createElement("svg", { id: "svg2", width: 1120, height: 800, viewBox: "0 0 1120 800", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("defs", { id: "defs6" }), /* @__PURE__ */ React.createElement("g", { id: "g8", transform: "matrix(1.3333333,0,0,-1.3333333,0,800)" }, /* @__PURE__ */ React.createElement("g", { id: "g10", transform: "scale(0.1)" }, /* @__PURE__ */ React.createElement("path", { d: "M 8400,14.8008 V 921.301 C 8302,907.199 8201.9,900 8100,900 7094.6,900 6254.3,1606.4 6048.3,2550 H 7800 v 900 H 6048.3 c 206,943.6 1046.3,1650 2051.7,1650 101.9,0 202,-7.2 300,-21.2 v 906.4 C 8301.3,5995 8201.3,6000 8100,6000 6443.1,6000 5100,4656.9 5100,3000 5100,1343.1 6443.1,0 8100,0 c 101.3,0 201.3,5 300,14.8008", style: {
  fill: "#231f20",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, id: "path12" }), /* @__PURE__ */ React.createElement("path", { d: "M 0,3000 C 0,1343.1 1343.1,0 3000,0 c 108.1,0 214.9,5.69922 320,16.8008 V 924.199 C 3215.7,908.199 3108.8,900 3000,900 1840.2,900 900,1840.2 900,3000 c 0,1159.8 940.2,2100 2100,2100 108.8,0 215.7,-8.2 320,-24.2 v 907.4 C 3214.9,5994.3 3108.1,6000 3000,6000 1343.1,6000 0,4656.9 0,3000", style: {
  fill: "#231f20",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, id: "path14" }))));
export default SvgCe;

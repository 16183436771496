import { useEffect } from 'react';

import { usePostHog } from 'posthog-js/react';
import { useLocation } from 'react-router';

export default function PostHogPageView() {
  const posthog = usePostHog();

  const location = useLocation();

  useEffect(() => {
    if (posthog) {
      posthog.capture('$pageview', {
        $current_url: window.origin + location.pathname,
      });
    }
  }, [posthog, location.pathname]);

  return null;
}

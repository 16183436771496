import { css } from '@emotion/css';

import { Messages } from '@/components/chat/body/Messages';
import { SidePeekBody } from '@/components/side-peek/SidePeekBody';
import { useStyles } from '@/hooks/useStyles';

export const ChatBody = () => {
  const styles = useStyles(makeStyles);

  return (
    <SidePeekBody className={styles.container}>
      <Messages />
    </SidePeekBody>
  );
};

const makeStyles = () => ({
  container: css`
    flex-grow: 1;
  `,
});

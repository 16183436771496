import React from 'react';

import { InputAdornment, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { ContactData } from '@/pages/patient-medical-file/contact-patient/ContactPatient.schema';
import { relationships } from '@/pages/patient-medical-file/contact-patient/ContactPatientForm';
import { FormSelect } from '@/uiKit/input/FormSelect';
import { FormSwitch } from '@/uiKit/input/FormSwitch';
import { FormTextInput } from '@/uiKit/input/FormTextInput';

export type InputProps = {
  className?: string;
};

export const SelectRelationshipInput: React.FC<InputProps> = ({
  className,
}) => {
  const { t } = useTranslation();

  return (
    <FormSelect<ContactData>
      name="relationshipWithPatient"
      label={t('contact.relationshipWithPatient')}
      classes={{ root: className }}
      required
      selectProps={{ autoWidth: false }}
    >
      {Object.entries(relationships).map(([value, label]) => (
        <MenuItem key={value} value={value}>
          {t(label)}
        </MenuItem>
      ))}
    </FormSelect>
  );
};

export const FirstnameInput: React.FC<InputProps> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <FormTextInput<ContactData>
      name="firstname"
      label={t('profile.firstname')}
      required
      classes={{ root: className }}
    />
  );
};

export const LastnameInput: React.FC<InputProps> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <FormTextInput<ContactData>
      name="lastname"
      label={t('profile.lastname')}
      required
      classes={{ root: className }}
    />
  );
};

export const PhoneInput: React.FC<InputProps> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <FormTextInput<ContactData>
      name="phone"
      label={t('form.phone.label')}
      classes={{ root: className }}
      required
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icons.phone />
          </InputAdornment>
        ),
      }}
      placeholder={'+33'}
    />
  );
};

export const EmailInput: React.FC<InputProps> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <FormTextInput<ContactData>
      name="email"
      label={t('form.email.label')}
      classes={{ root: className }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icons.email />
          </InputAdornment>
        ),
      }}
      placeholder={t('form.email.label')}
    />
  );
};

export const CommentInput: React.FC<InputProps> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <FormTextInput<ContactData>
      name="comment"
      label={t('form.text.comment')}
      classes={{ root: className }}
    />
  );
};

export const EmergencyContactInput: React.FC<InputProps> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <FormSwitch<ContactData>
      name="isEmergencyContact"
      label={t('contact.emergencyContact')}
      classes={{ root: className }}
      switchProps={{ size: 'medium' }}
    />
  );
};

import { ReactElement } from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';
import {
  FieldValues,
  Path,
  RegisterOptions,
  useFormContext,
} from 'react-hook-form';

import CheckIcon from '@/assets/icons/radio_check.svg';
import {
  BaseRadioFormInput,
  OptionItem,
} from '@/components/form/inputs/BaseRadioFormInput';
import { InputError, getError } from '@/components/form/inputs/InputError';
import { Col, Row } from '@/components/layout/Flex';
import { useStyles } from '@/hooks/useStyles';

export type RadioFormInputProps<T extends FieldValues> = {
  options: OptionItem<T>[];
  containerStyle?: string;
  labelStyle?: string;
  label?: string;
  name: Path<T>;
  noReservedErrorSpace?: boolean;
} & RegisterOptions<T>;

export const RadioFormInput = <T extends FieldValues>({
  options,
  containerStyle,
  name,
  label,
  labelStyle,
  noReservedErrorSpace,
  ...inputProps
}: RadioFormInputProps<T>): ReactElement => {
  const context = useFormContext<T>();
  const styles = useStyles(makeStyles, !!getError({ context, name }));
  return (
    <Col grow className={cx(styles.container, containerStyle)}>
      <Row align="center" shrink className={styles.content}>
        <BaseRadioFormInput<T>
          inputProps={inputProps}
          options={options}
          context={context}
          name={name}
          label={label}
          className={styles.input}
          labelStyle={labelStyle}
          radioContainerStyle={styles.radio}
          radioInputStyle={styles.radioInput}
          radioLabelStyle={styles.radioLabel}
        />
      </Row>
      <InputError<T>
        className={styles.error}
        name={name}
        context={context}
        noReservedSpace={noReservedErrorSpace}
      />
    </Col>
  );
};

const makeStyles = (theme: Theme, error: boolean) => ({
  container: css`
    min-width: 0;
  `,
  content: css`
    position: relative;
    display: flex;
    margin-bottom: ${theme.spacing(1)};
  `,
  input: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    min-width: 0;
    flex-wrap: wrap;
  `,
  radio: css({
    // Requires object notation to force `url("${CheckIcon}")` syntax, for proper svg bundling
    margin: theme.spacing(4, 12, 4, 0),
    backgroundColor: theme.palette.common.white,
    '> input:checked + label': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,
      ':before': {
        content: `url("${CheckIcon}")`,
        alignItems: 'flex-end',
        justifyContent: 'center',
        color: theme.palette.primary.main,
      },
    },
  }),
  radioInput: css`
    display: none;
  `,
  radioLabel: css`
    white-space: nowrap;
    border: ${error ? theme.palette.error.main : theme.palette.neutral.light}
      ${theme.spacing(0.5)} solid;
    border-radius: ${theme.spacing(12)};
    padding: ${theme.spacing(2)} ${theme.spacing(4)};
    color: ${theme.palette.primary.main};
    cursor: pointer;
    :before {
      width: 1.8rem;
      display: inline flex;
      content: ' ';
    }
    :after {
      width: 1.8rem;
      display: inline flex;
      content: ' ';
    }
  `,
  error: css`
    height: 2.5em;
    color: ${theme.palette.error.main};
  `,
});

import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';
import { StyleType } from '@/uiKit/StyleTypes';
import {
  OptionGroupProps,
  OptionProps,
} from '@/uiKit/input/radio/InputInterface';
import { Radio } from '@/uiKit/input/radio/Radio';

export const RadioGroup = ({
  label,
  options,
  onChange,
  className,
}: OptionGroupProps & StyleType) => {
  const styles = useStyles(makeStyles);
  return (
    <fieldset className={cx(styles.radioGroup, className)}>
      {label && (
        <legend>
          <Typography variant="subtitle" className={styles.label}>
            {label}
          </Typography>
        </legend>
      )}
      <RenderOptions options={options} onChange={onChange} />
    </fieldset>
  );
};

type RenderOptionsProps = {
  options: OptionProps[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const RenderOptions = ({
  options,
  onChange,
}: RenderOptionsProps & StyleType) => {
  return options.map(({ label, name, disabled, checked, value }) => {
    const optionId = `radio-option-${label.replace(/\s+/g, '')}`;
    return (
      <Radio
        value={value}
        label={label}
        key={optionId}
        id={optionId}
        name={name}
        disabled={disabled}
        defaultChecked={checked}
        onChange={onChange}
      />
    );
  });
};

const makeStyles = (theme: Theme) => ({
  radioGroup: css`
    display: flex;
    padding: ${theme.spacing(2)} ${theme.spacing(8)};
    align-items: center;
    gap: ${theme.spacing(4)};
  `,
  label: css`
    margin-bottom: ${theme.spacing(8)};
  `,
});

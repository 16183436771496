import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { Loader } from '@/components/loading/Loader';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useStyles';
import { DiabetesInfo } from '@/pages/patient-medical-file/DiabetesInfo';
import { Entourage } from '@/pages/patient-medical-file/Entourage';
import { MedicalDeviceList } from '@/pages/patient-medical-file/MedicalDeviceList';
import { PatientProfile } from '@/pages/patient-medical-file/PatientProfile';
import { Queries } from '@/queries/Queries';

export type PatientMedicalFileProps = {
  // Empty
};

export const PatientMedicalFile: React.FC<PatientMedicalFileProps> = ({}) => {
  const styles = useStyles(makeStyles);
  const patientId = usePatientIdFromURL() ?? '';
  const patientQuery = Queries.practitioner.usePatient(patientId, {
    enabled: !!patientId,
  });

  return (
    <div className={styles.container}>
      {patientQuery.isSuccess ? (
        <>
          <PatientProfile patientId={patientId} />
          <MedicalDeviceList patientId={patientId} />
          <DiabetesInfo patientId={patientId} />
          <Entourage patientId={patientId} />
        </>
      ) : (
        <Loader size="L" />
      )}
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${theme.spacing(12)};
  `,
  content: css`
    display: flex;
    flex-direction: row;
    margin-top: ${theme.spacing(12)};
    flex-wrap: wrap-reverse;
    gap: ${theme.spacing(8)};
  `,
});

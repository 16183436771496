import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { Icons } from '@/assets/icons';
import {
  PeriodSelector,
  Periodicities,
  Periodicity,
} from '@/components/period-picker/PeriodPicker/PeriodSelector';
import { useStyles } from '@/hooks/useStyles';

type PeriodPickerProps = {
  current: Periodicity;
  periodicities: Periodicities;
  onChange: (args: Periodicity) => void;
};

export const PeriodPicker: React.FC<PeriodPickerProps> = ({
  current,
  periodicities,
  onChange,
}) => {
  const styles = useStyles(makePeriodSelectorStyles);

  const handleChangePeriodicity = (periodicity: Periodicity) => {
    onChange(periodicity);
  };

  return (
    <div className={styles.container}>
      <PeriodSelector
        currentPeriodicity={current}
        periodicities={periodicities}
        onChangePeriodicity={handleChangePeriodicity}
      />
    </div>
  );
};

const makePeriodSelectorStyles = () => ({
  container: css`
    display: flex;
    align-items: center;
  `,
});

type SelectPeriodProps = {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const SelectPreviousPeriod: React.FC<SelectPeriodProps> = ({
  onClick,
}) => {
  const styles = useStyles(makeSelectPeriodStyles, false);
  return (
    <button className={styles.button} onClick={onClick}>
      <Icons.arrowLeft />
    </button>
  );
};

type SelectNextPeriodProps = SelectPeriodProps & {
  disabled: boolean;
};

export const SelectNextPeriod: React.FC<SelectNextPeriodProps> = ({
  onClick,
  disabled,
}) => {
  const styles = useStyles(makeSelectPeriodStyles, disabled);

  return (
    <button className={styles.button} disabled={disabled} onClick={onClick}>
      <Icons.arrowRight />
    </button>
  );
};

const makeSelectPeriodStyles = (theme: Theme, disabled: boolean) => ({
  button: css`
    background-color: ${theme.palette.common.white};
    padding: ${theme.spacing(6)};
    color: ${disabled ? theme.palette.grey[300] : theme.palette.text.primary};
    display: flex;
    height: fit-content;
  `,
});

import { ConditionRepository } from '@/io/repository/ConditionRepository';
import { makeQueryKey, stripQueryResult } from '@/queries/Queries';

import { createQuery } from './utils/CreateQuery';

export class ConditionQueries {
  constructor(
    private readonly condition: ConditionRepository = new ConditionRepository(),
  ) {}

  /*******************************/
  /*********** QUERIES ***********/
  /*******************************/

  useCondition = createQuery(
    'condition',
    patientId => makeQueryKey(patientId),
    async (patientId: string) =>
      stripQueryResult(await this.condition.getCondition(patientId)),
  );
}

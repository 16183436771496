import React from 'react';

import { useTranslation } from 'react-i18next';

import { Col } from '@/components/layout/Flex';
import { Typo } from '@/components/typography/Text';

export type NotFoundProps = {
  // Empty
};

export const NotFound: React.FC<NotFoundProps> = ({}) => {
  const { t } = useTranslation();
  return (
    <Col width="100%" height="100%" align="center" justify="center">
      <Typo type="title">{t('pages.notFound.title')}</Typo>
    </Col>
  );
};

import { useTheme } from '@mui/material';
import { AxisBottom } from '@visx/axis';
import { GridColumns } from '@visx/grid';
import { ScaleLinear } from 'd3-scale';

import { MarginProps } from '@/components/data-representations/DailyGraph/types';

type DecorationsProps = {
  height: number;
  margin: MarginProps;
  xScale: ScaleLinear<number, number>;
};

export const VerticalGrids = ({ height, margin, xScale }: DecorationsProps) => {
  const theme = useTheme();
  return (
    <GridColumns
      scale={xScale}
      tickValues={[6, 12, 18]}
      height={height - (margin.bottom ?? 0)}
      offset={0}
      stroke={theme.palette.grey[50]}
      strokeWidth={2}
    />
  );
};

export const BottomAxis = ({ height, margin, xScale }: DecorationsProps) => {
  const theme = useTheme();
  return (
    <AxisBottom
      top={height - (margin.bottom ?? 0)}
      hideTicks
      hideAxisLine
      stroke={theme.palette.grey[100]}
      scale={xScale}
      tickLabelProps={() => ({
        dy: '-0.5em',
        dx: '0em',
        fill: theme.palette.grey[400],
        fontFamily: 'NunitoSans',
        fontSize: 14,
        textAnchor: 'start',
      })}
      tickFormat={e => ((e + '').length === 1 ? '0' + e + ' h' : e + ' h')}
    />
  );
};

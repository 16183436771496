import { DecoderFunction, field, string } from 'typescript-json-decoder';

import {
  PractitionerSummary,
  practitionerSummaryDecoder,
} from '@/models/PractitionerModel';
import {
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils';

export type IssueStatus =
  | 'preliminary'
  | 'final'
  | 'entered-in-error'
  | 'mitigated';

export type IssueCategory = 'FULFIL' | 'CAREGAP' | 'COMPLY';

export type IssueSeverity = 'low' | 'medium' | 'high';

export type IssueMitigation = '5';

export type NurseNotice = {
  id: string;
  subjectId: string;
  author: PractitionerSummary;
  mitigationAuthor?: PractitionerSummary;
  status: IssueStatus;
  category: IssueCategory;
  severity: IssueSeverity;
  detail?: string;
  mitigationAction?: IssueMitigation;
  mitigationDate?: string;
  mitigationNote?: string;
  updatedAt: string;
  createdAt: string;
};

export const nurseNoticeDecoder: DecoderFunction<NurseNotice> =
  recordWithContext('NurseNotice', {
    id: string,
    subjectId: field('subject', string),
    author: practitionerSummaryDecoder,
    mitigationAuthor: nullOrUndef(practitionerSummaryDecoder),
    status: stringUnion(
      'preliminary',
      'final',
      'entered-in-error',
      'mitigated',
    ),
    category: stringUnion('FULFIL', 'CAREGAP', 'COMPLY'),
    severity: stringUnion('low', 'medium', 'high'),
    detail: nullOrUndef(string),
    mitigationAction: nullOrUndef(stringUnion('5')),
    mitigationDate: nullOrUndef(string),
    mitigationNote: nullOrUndef(string),
    updatedAt: string,
    createdAt: string,
  });

export type CreateNurseNotice = {
  patientId: string;
  detail?: string;
};

export type DeleteNurseNotice = {
  patientId: string;
  noticeId: string;
};

import { z } from 'zod';

export const addMessageSchema = z.object({
  visibility: z.enum(['practitioners', 'all'], {
    required_error: 'form.required',
  }),
  category: z.enum(['notification', 'alert'], {
    required_error: 'form.required',
  }),
  status: z.enum(['completed'], { required_error: 'form.required' }),
  contentText: z.string().optional(),
  contentAttachment: z
    .array(
      z.object({
        file: z.instanceof(File).optional(),
        name: z.string(),
      }),
    )
    .optional(),
});

export type AddMessageInfoData = z.infer<typeof addMessageSchema>;

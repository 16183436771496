import React, { useContext } from 'react';

import { ChatStatusContext } from '@/components/chat/ChatContext';
import { ChatFilter } from '@/components/chat/header/ChatFilter';
import { SidePeekHeader } from '@/components/side-peek/SidePeekHeader';

export const ChatHeader: React.FC = () => {
  const { setOpen, open } = useContext(ChatStatusContext);
  return (
    <SidePeekHeader
      title={<ChatFilter />}
      exit={() => setOpen?.(!open)}
    ></SidePeekHeader>
  );
};

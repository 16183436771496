import { useState } from 'react';

import { css, cx } from '@emotion/css';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  CellContext,
  Table as TSTable,
  flexRender,
} from '@tanstack/react-table';

import { useStyles } from '@/hooks/useStyles';

export type CustomCellContext<TData, TValue> = CellContext<TData, TValue> & {
  isHovered: boolean;
};
type MuiTableProps<T> = {
  table: TSTable<T>;
  handleRowClick: (row: T) => void;
};

export const MuiTable = <T,>({ table, handleRowClick }: MuiTableProps<T>) => {
  const [hoveredRowId, setHoveredRowId] = useState<string>('');
  const styles = useStyles(makeStyles);

  return (
    <TableContainer className={styles.container}>
      <Table size="medium">
        <TableHead className={styles.header}>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id} className={styles.row}>
              {headerGroup.headers.map(header => (
                <TableCell
                  key={header.id}
                  className={cx(
                    styles.header,
                    css({ width: `${header.column.getSize()}%` }),
                  )}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map(row => (
            <TableRow
              key={row.id}
              hover
              onMouseEnter={() => setHoveredRowId(row.id)}
              onMouseLeave={() => setHoveredRowId('')}
              className={styles.row}
              onClick={() => handleRowClick(row.original)}
            >
              {row.getVisibleCells().map(cell => (
                <TableCell
                  key={cell.id}
                  className={css({
                    width: `${cell.column.getSize()}%`,
                  })}
                >
                  {flexRender(cell.column.columnDef.cell, {
                    ...cell.getContext(),
                    isHovered: hoveredRowId === row.id,
                  })}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const makeStyles = () => ({
  row: css({
    '& > *': {
      borderBottom: 'none',
    },
    cursor: 'pointer',
  }),
  container: css`
    padding-left: 1rem;
    padding-right: 1rem;
  `,
  header: css({
    '& > *': {
      borderBottom: 'none',
    },
    cursor: 'pointer',
    padding: '0',
    marging: '0',
  }),
});

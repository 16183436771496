import React, { ImgHTMLAttributes } from 'react';

import StetoInfo from '@/assets/img/steto-info.png';

export type ImageProps = Omit<
  React.DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >,
  'src' | 'alt'
> & { alt: string };
export const Image: (src: string) => React.FC<ImageProps> = src => {
  const _Image: React.FC<ImageProps> = ({ alt, ...props }) => (
    <img src={src} alt={alt} {...props} />
  );

  return _Image;
};

export const Images = {
  stetoInfo: Image(StetoInfo),
};

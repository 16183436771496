import { Typography } from '@mui/material';
import { FieldErrors, FieldValues, Path, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type FormErrorData<T extends FieldValues> =
  | { error: FieldErrors<T>[Path<T>] }
  | { context: UseFormReturn<T>; name: Path<T> };
export type InputErrorProps<T extends FieldValues> = {
  className?: string;
  noReservedSpace?: boolean;
} & FormErrorData<T>;

export const InputError = <T extends FieldValues>({
  className,
  noReservedSpace = false,
  ...props
}: InputErrorProps<T>): JSX.Element => {
  const { t } = useTranslation();
  const error = getError(props);
  if (!error) {
    return noReservedSpace ? <></> : <div className={className}></div>;
  }
  return (
    <Typography variant="bodySmall" className={className}>
      {t(error)}
    </Typography>
  );
};

export const getError = <T extends FieldValues>(
  errorData: FormErrorData<T>,
): string | undefined => {
  const error =
    'name' in errorData
      ? errorData.context.formState.errors[errorData.name]
      : errorData.error;

  if (error && error.message && typeof error.message === 'string') {
    return error.message;
  }
  return undefined;
};

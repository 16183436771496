import React from 'react';

import { Drawer, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IdentityValidation } from '@/models/identity/IdentityValidationModel';
import { ValidatePatientCard } from '@/pages/patients/ValidatePatientCard';
import { Queries } from '@/queries/Queries';

type ValidateIdentitiesProps = {
  patientId: string;
};

export const ValidateIdentity: React.FC<ValidateIdentitiesProps> = ({
  patientId,
}) => {
  const { t } = useTranslation();
  const { data: patient } = Queries.practitioner.usePatient(patientId);
  const { data: practitioner } = Queries.practitioner.usePractitioner({});

  const validateOrRejectQuery =
    Queries.practitioner.useValidateOrRejectIdentity();

  if (practitioner?.qualification !== 'doctor' || !patient) {
    return null;
  }

  if (patient.identityLastname === undefined) {
    // This excludes the patients created when the notEntered status was not implemented
    return null;
  }

  return (
    <Drawer open={patient.identityStatus === 'notValidated'} anchor="right">
      <div style={{ padding: '20px' }}>
        <Typography variant="h4">{t('identityValidation.title')}</Typography>
        <Typography>{t('identityValidation.subtitle')}</Typography>
      </div>
      <div style={{ padding: '20px' }}>
        <ValidatePatientCard
          submitting={validateOrRejectQuery.isPending}
          key={patient.id}
          identity={patient as IdentityValidation}
          validateOrReject={action =>
            validateOrRejectQuery.mutate({ patientId: patient.id, action })
          }
        />
      </div>
    </Drawer>
  );
};

import React, { ButtonHTMLAttributes, ReactElement } from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography, TypographyOwnProps, useTheme } from '@mui/material';

import { Spinner } from '@/components/Spinner';
import { useStyles } from '@/hooks/useStyles';
import { Status } from '@/utils/ReactQueryUtils';

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'inverted'
  | 'critical'
  | 'link'
  | 'plain'
  | 'tag';

export type ButtonProps = {
  buttonType: ButtonType;
  textType?: TypographyOwnProps['variant'];
  children?: string | ReactElement;
  status?: Status;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'>;

/**
 * @deprecated use MUI Button instead
 */
export const Button: React.FC<ButtonProps> = ({
  status = 'idle',
  disabled,
  textType = 'button',
  children,
  ...props
}) => {
  const theme = useTheme();
  const styles = useStyles(makeStyles);

  switch (status) {
    case 'pending':
      return (
        <_Button {...props} textType={textType} disabled>
          <div className={styles.spinnerOverlay}>
            <div className={styles.overlaidContent}>
              {typeof children === 'string' ? (
                <Typography variant={textType} classes={{ root: styles.text }}>
                  {children}
                </Typography>
              ) : (
                children
              )}
            </div>
            <div className={styles.spinner}>
              <Spinner
                foregroundColor={theme.palette.primary.main}
                backgroundColor={theme.palette.primary.contrastText}
                height={'15px'}
              />
            </div>
          </div>
        </_Button>
      );
    case 'success':
      return (
        <_Button {...props} textType={textType} disabled={disabled}>
          {children}
        </_Button>
      );
    case 'error':
    case 'idle':
      return (
        <_Button {...props} textType={textType} disabled={disabled}>
          {children}
        </_Button>
      );
  }
};

const _Button: React.FC<ButtonProps> = ({
  buttonType,
  textType = 'body',
  children,
  className,
  ...props
}) => {
  const styles = useStyles(makeStyles);
  return (
    <button
      className={cx(styles.common, styles[buttonType], className)}
      {...props}
    >
      {typeof children === 'string' ? (
        <Typography variant={textType} classes={{ root: styles.text }}>
          {children}
        </Typography>
      ) : (
        children
      )}
    </button>
  );
};

const makeStyles = (theme: Theme) => ({
  common: css`
    display: flex;
    justify-items: center;
    align-items: center;
    border-radius: ${theme.spacing(2)};
    padding-left: ${theme.spacing(8)};
    padding-right: ${theme.spacing(8)};
    padding-top: ${theme.spacing(4)};
    padding-bottom: ${theme.spacing(4)};
    &:focus {
      outline: 0;
    }
  `,
  primary: css`
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.common.white};
    &:disabled {
      cursor: default;
      background-color: ${theme.palette.grey[200]};
      color: ${theme.palette.grey[700]};
      //border: 0;
    }
    &:not(:disabled):hover {
      opacity: 0.8;
    }
    &:not(:disabled):focus {
      opacity: 0.8;
    }
  `,
  secondary: css`
    background-color: ${theme.palette.common.white};
    color: ${theme.palette.primary.main};
    border: 1px ${theme.palette.primary.main} solid;
    &:disabled {
      cursor: default;
      background-color: ${theme.palette.primary.contrastText};
      color: ${theme.palette.grey[700]};
      //border: 0;
    }
    &:not(:disabled):hover {
      background-color: ${theme.palette.grey[200]};
    }
    &:not(:disabled):focus {
      background-color: ${theme.palette.grey[200]};
    }
  `,
  link: css`
    color: ${theme.palette.text.primary};
    &:disabled {
      cursor: default;
    }
    &:not(:disabled):hover {
      color: ${theme.palette.primary.main};
    }
    &:not(:disabled):active {
      color: ${theme.palette.error.main};
    }
  `,
  inverted: css`
    background-color: ${theme.palette.common.white};
    color: ${theme.palette.primary.main};
    border-width: 1px ${theme.palette.primary.main} solid;
    &:disabled {
      cursor: default;
      background-color: ${theme.palette.grey[700]};
      color: ${theme.palette.grey[200]};
      //border: 0;
    }
    &:not(:disabled):hover {
      background-color: ${theme.palette.grey[300]};
    }
    &:not(:disabled):focus {
      background-color: ${theme.palette.grey[300]};
    }
  `,
  critical: css`
    background-color: ${theme.palette.common.white};
    color: ${theme.palette.error.main};
    border-width: 1px ${theme.palette.error.main} solid;
    &:disabled {
      cursor: default;
      background-color: ${theme.palette.grey[700]};
      color: ${theme.palette.error.dark};
      //border: 0;
    }
    &:not(:disabled):hover {
      background-color: ${theme.palette.grey[300]};
    }
    &:not(:disabled):focus {
      background-color: ${theme.palette.grey[300]};
    }
  `,
  plain: css``,
  spinnerOverlay: css`
    display: flex;
    position: relative;
  `,
  overlaidContent: css`
    visibility: hidden;
    display: flex;
    justify-items: center;
    align-items: center;
  `,
  spinner: css`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  tag: css`
    background-color: ${theme.palette.primary.main};
    padding-left: ${theme.spacing(4)};
    padding-right: ${theme.spacing(4)};
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(2)};
    color: ${theme.palette.common.white};
    &:disabled {
      cursor: default;
      background-color: ${theme.palette.grey[200]};
      color: ${theme.palette.grey[700]};
      //border: 0;
    }
    &:not(:disabled):hover {
      opacity: 0.8;
    }
    &:not(:disabled):focus {
      opacity: 0.8;
    }
  `,
  text: css`
    color: inherit;
  `,
});

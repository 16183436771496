import { useState } from 'react';

import { css } from '@emotion/css';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
} from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { Form } from '@/components/form/Form';
import { useStyles } from '@/hooks/useStyles';
import { CarePlan } from '@/models/CarePlanModel';
import { FileModel } from '@/models/FileModel';
import { Queries } from '@/queries/Queries';
import { ButtonLoader } from '@/uiKit/ButtonLoader';
import { ControlledFileUpload } from '@/uiKit/molecules/ControlledFileInput';
import { ControlledTextField } from '@/uiKit/molecules/ControlledTextField';

type TherapeuticObjectivesModalProps = {
  carePlan: CarePlan;
  onClose: () => void;
};

type TherapeuticObjectivesFormData = {
  description?: string;
  descriptionAttachment?: FileModel;
};

const TherapeuticObjectivesModal = ({
  carePlan,
  onClose,
}: TherapeuticObjectivesModalProps) => {
  const editObjectives = Queries.practitioner.useEditTherapeuticObjectives();
  const form = useForm<TherapeuticObjectivesFormData>({
    defaultValues: {
      description: carePlan.description,
      descriptionAttachment: carePlan.descriptionAttachment,
    },
  });

  const isMutating = useIsMutating();

  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  const { data: patientInfo } = Queries.practitioner.usePatient(
    carePlan.subject,
  );

  if (!patientInfo) {
    return null;
  }

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('diabetesForms.goalsShort')}</DialogTitle>
      <Form
        onSubmit={data => {
          return editObjectives.mutate(
            {
              carePlanId: carePlan.id,
              data: data,
            },
            {
              onSuccess: onClose,
            },
          );
        }}
        id="EditTheurapeuticObjectivesForm"
        formContext={form}
      >
        <DialogContent className={styles.content}>
          <div className={styles.formContainer}>
            <ControlledTextField
              name="description"
              multiline
              label={t('diabetesForms.goalsShort')}
            />
            <ControlledFileUpload
              name="descriptionAttachment"
              fileType="description"
              patientInfo={patientInfo}
              defaultValue={carePlan.descriptionAttachment}
              hasTrash={true}
            />
          </div>
          <div className={styles.footer}>
            <Button variant="outlined" onClick={onClose}>
              {t('button.cancel')}
            </Button>
            <ButtonLoader
              variant="contained"
              type="submit"
              disabled={isMutating > 0}
              status={editObjectives.status}
            >
              {t('button.next')}
            </ButtonLoader>
          </div>
        </DialogContent>
      </Form>
    </Dialog>
  );
};

type EditTherapeuticObjectivesProps = {
  carePlan: CarePlan;
};

export const EditTherapeuticObjectives = ({
  carePlan,
}: EditTherapeuticObjectivesProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <IconButton onClick={() => setIsModalOpen(true)}>
        <Icons.edit height="0.75em" width="0.75em" color="black" />
      </IconButton>
      {isModalOpen ? (
        <TherapeuticObjectivesModal
          onClose={() => setIsModalOpen(false)}
          carePlan={carePlan}
        />
      ) : null}
    </>
  );
};

const makeStyles = (theme: Theme) => ({
  content: css`
    padding: ${theme.spacing(20)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${theme.spacing(12)};
  `,
  footer: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: ${theme.spacing(12)};
    padding-right: ${theme.spacing(12)};
    margin-top: ${theme.spacing(12)};
  `,
  formContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${theme.spacing(12)};
  `,
});

import { useState } from 'react';

import { css } from '@emotion/css';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useStyles';
import { PatientRenewalDecision } from '@/models/CarePlanModel';
import { CarePlanRenewal } from '@/pages/patient-settings/CarePlanRenewal';
import { Queries } from '@/queries/Queries';
import { userFormattedName } from '@/utils/user.ts';

export const PatientRenewalPopup = () => {
  const id = usePatientIdFromURL() ?? '';
  const { data: carePlanData } = Queries.practitioner.useCarePlans(id, {
    enabled: id !== '',
  });
  const { data: patient } = Queries.practitioner.usePatient(id, {
    enabled: id !== '',
  });
  const { data: careTeam } = Queries.practitioner.useCareTeam(id, {
    enabled: id !== '',
  });

  const nurse = careTeam?.participants
    .filter(p => p.qualification === 'nurse')
    .at(0);

  const profilePicture = nurse ? nurse.profile_picture?.url : null;

  const renewalDecisionMutation = Queries.practitioner.useDecideRenewCarePlan();

  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const [isRenewalPopupOpen, setIsRenewalPopupOpen] = useState(false);

  const handleChoice = (decision: PatientRenewalDecision) => {
    renewalDecisionMutation.mutate({
      patientId: id,
      decision,
    });
  };

  const handleRenew = () => {
    setIsRenewalPopupOpen(true);
  };

  if (!carePlanData || !patient) {
    return null;
  }

  if (carePlanData.display_renew_popup) {
    return (
      <>
        <Dialog open={!isRenewalPopupOpen} maxWidth="sm" fullWidth>
          <DialogTitle>{t('pages.renewPopup.title')}</DialogTitle>
          <DialogContent className={styles.container}>
            <div className={styles.content}>
              <div className={styles.nursePatientLine}>
                {profilePicture ? (
                  <Box
                    className={styles.picture}
                    src={profilePicture}
                    component="img"
                    alt="nurse-picture"
                  />
                ) : (
                  <Typography variant="subtitle">
                    {userFormattedName(nurse?.familyName, nurse?.givenName)}
                  </Typography>
                )}
                <Typography variant="subtitle"> x </Typography>
                <Typography variant="subtitle">
                  {userFormattedName(patient.familyName, patient.givenName)}
                </Typography>
              </div>
              <div>
                <Typography variant="body">
                  {t('pages.renewPopup.body')}
                </Typography>
              </div>
            </div>
            <div className={styles.footer}>
              <Button variant="outlined" onClick={handleRenew}>
                {t('pages.renewPopup.yes')}
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleChoice('do_not_renew')}
              >
                {t('pages.renewPopup.no')}
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleChoice('decide_later')}
              >
                {t('pages.renewPopup.decideLater')}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        {isRenewalPopupOpen && (
          <CarePlanRenewal
            patientId={id}
            onClose={() => setIsRenewalPopupOpen(false)}
          />
        )}
      </>
    );
  }

  return null;
};

const makeStyles = (theme: Theme) => ({
  nursePatientLine: css`
    display: flex;
    gap: ${theme.spacing(8)};
    align-items: center;
    justify-content: center;
  `,
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(12)};
    justify-content: center;
    align-items: center;
  `,
  content: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${theme.spacing(12)};
    padding-left: ${theme.spacing(10)};
    padding-right: ${theme.spacing(10)};
  `,
  footer: css`
    display: flex;
    justify-content: space-between;
    gap: ${theme.spacing(8)};
  `,
  picture: css`
    width: 100px;
    height: 100px;
    border-radius: 50px;
  `,
});

import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography, TypographyOwnProps } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';

type UserPictureProps = {
  givenName?: string;
  familyName?: string;

  picture?: React.ReactElement;
  textType?: TypographyOwnProps['variant'];
  className?: string;
};
export const UserPicture: React.FC<UserPictureProps> = ({
  givenName,
  familyName,
  picture,
  textType = 'body' as TypographyOwnProps['variant'],
  className,
}) => {
  const styles = useStyles(makeStyles);
  const initials =
    givenName && familyName
      ? givenName.slice(0, 1) + familyName.slice(0, 1)
      : '';

  return (
    <div className={cx(styles.container, className)}>
      {picture ? (
        picture
      ) : (
        <Typography
          variant={textType}
          fontWeight="bold"
          textTransform="uppercase"
        >
          {initials}
        </Typography>
      )}
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    width: ${theme.spacing(20)};
    height: ${theme.spacing(20)};
    aspect-ratio: 1;
    background-color: ${theme.palette.primary.background};
    border-radius: 100%;
    justify-content: center;
    align-items: center;
  `,
});

import React, {
  Context,
  PropsWithChildren,
  ReactElement,
  createContext,
  useContext,
  useRef,
} from 'react';

import { StoreApi, useStore } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

export type ZustandContext<State> = Context<StoreApi<State> | null>;

export type ZustandProvider<Data> = React.FC<PropsWithChildren<Data>>;

export type ZustandHook<State> = <T>(
  selector: (state: State) => T,
  memoized?: boolean,
) => T;

export type ZustandStoreCreator<Data, State> = (props: Data) => StoreApi<State>;

/**
 * Create a Zustand context store
 * Returns a tuple with the context, provider and hook
 * Context is the React context that contains the zustand store
 * Provider is a React component that wraps the children with the store
 * Hook is a function that returns the selected state from the store
 * @param name A name for the store
 * @param createStore A function that creates the zustand store
 */
export const makeZustandContextStore = <Data, State>(
  name: string,
  createStore: ZustandStoreCreator<Data, State>,
): [ZustandContext<State>, ZustandProvider<Data>, ZustandHook<State>] => {
  const context = createContext<StoreApi<State> | null>(null);

  const useProvider = ({
    children,
    ...props
  }: PropsWithChildren<Data>): ReactElement => {
    const storeRef = useRef<StoreApi<State>>();
    if (!storeRef.current) {
      storeRef.current = createStore(props as Data);
    }

    return (
      <context.Provider value={storeRef.current}>{children}</context.Provider>
    );
  };
  useProvider.displayName = name;

  const useConsumeStore = <T,>(selector: (state: State) => T): T => {
    const store = useContext(context);
    if (!store) {
      throw new Error(`Missing ${name}Context.Provider in the tree`);
    }

    return useStore(store, useShallow(selector));
  };

  return [context, useProvider, useConsumeStore];
};

import {
  ApiDataSourceSpec,
  apiDataSource,
} from '@/io/datasource/ApiDatasource';
import {
  AlertConfig,
  AlertSummary,
  CreateAlertConfig,
  EditAlertConfig,
  alertConfigDecoder,
  alertSummaryDecoder,
} from '@/models/AlertModel';
import { ApiErrorResponse } from '@/models/ApiErrorResponse';
import { Result } from '@/utils/Result';
import { forcedArray } from '@/utils/decoderUtils';

export class AlertsRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  getAlertConfigList = (
    patientId: string,
  ): Promise<Result<AlertConfig[], ApiErrorResponse>> => {
    return this.datasource.get(
      `/alert-configs/${patientId}/`,
      forcedArray(alertConfigDecoder),
    );
  };

  getAlertList = (
    patientId: string,
  ): Promise<Result<AlertSummary[], ApiErrorResponse>> => {
    return this.datasource.get(
      `/practitioners/patients/${patientId}/alerts/`,
      forcedArray(alertSummaryDecoder),
    );
  };

  createAlertConfig = (
    patientId: string,
    alertConfig: CreateAlertConfig,
  ): Promise<Result<AlertConfig, ApiErrorResponse>> => {
    return this.datasource.post(
      `/alert-configs/${patientId}/`,
      alertConfigDecoder,
      alertConfig,
    );
  };

  editAlertConfig = (
    patientId: string,
    { id: alertConfigId, ...alertConfig }: EditAlertConfig,
  ): Promise<Result<AlertConfig, ApiErrorResponse>> => {
    return this.datasource.patch(
      `/alert-configs/${patientId}/${alertConfigId}/`,
      alertConfigDecoder,
      alertConfig,
    );
  };

  deleteAlertConfig = (
    patientId: string,
    alertConfigId: string,
  ): Promise<Result<AlertConfig, ApiErrorResponse>> => {
    return this.datasource.delete(
      `/alert-configs/${patientId}/${alertConfigId}/`,
      alertConfigDecoder,
    );
  };

  dismissAlert = (
    patientId: string,
    alertId: string,
  ): Promise<Result<AlertSummary, ApiErrorResponse>> => {
    return this.datasource.delete(
      `/alerts/${patientId}/${alertId}/`,
      alertSummaryDecoder,
    );
  };
}

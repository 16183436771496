import { css } from '@emotion/css';
import { Chip, ChipProps, Theme } from '@mui/material';

import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles';

type ChipSelectProps = ChipProps & {
  selected: boolean;
  clickable?: boolean;
};

export const ChipSelect = ({ ...props }: ChipSelectProps) => {
  const selected = props.selected;
  const styles = useStyles(makeStyles, selected);
  return (
    <Chip
      icon={selected ? <Icons.check height="1em" width="1em" /> : undefined}
      clickable={props.clickable}
      color="primary"
      variant="outlined"
      {...props}
      className={styles.chip}
    />
  );
};

const makeStyles = (theme: Theme, selected: boolean) => ({
  chip: css`
    padding-left: ${theme.spacing(6)};
    padding-right: ${theme.spacing(6)};
    background-color: ${selected
      ? theme.palette.background.default
      : theme.palette.common.white};
  `,
});

const ratio = (part: number, total: number) => (part / total) * 100;

export const sum = (values: number[]) => values.reduce((s, v) => s + v, 0);

// Algorithm to round up multiple values to 100
// https://en.wikipedia.org/wiki/Largest_remainder_method
export function largestRemainderRound(values: number[]) {
  if (values.every(value => value === 0)) return values;

  const total = sum(values);
  const ratios = values.map(value => ratio(value, total) || 0);
  const integerPart = ratios.map(value => Math.trunc(value));
  const decimalPart = ratios.map(value => value - Math.trunc(value));
  let remainder = 100 - sum(integerPart);

  while (remainder > 0) {
    const max = Math.max(...decimalPart);
    const maxIndex = decimalPart.indexOf(max);
    integerPart[maxIndex] += 1;
    decimalPart[maxIndex] = 0;
    remainder -= 1;
  }
  return integerPart;
}

export function toFixedIfNotZero(value: number | undefined) {
  if (value === undefined) {
    return 0;
  }
  const rounded = parseFloat(value.toFixed(2));
  const roundedInt = Math.trunc(rounded);
  return rounded === roundedInt ? roundedInt : rounded;
}

export const roundWithPrecision = (value: number, precision: number) => {
  const factor = Math.pow(10, precision);
  return Math.round(value * factor) / factor;
};

import {
  ApiDataSourceSpec,
  apiDataSource,
} from '@/io/datasource/ApiDatasource';
import { ApiErrorResponse } from '@/models/ApiErrorResponse';
import {
  Practitioner,
  UpdatePractitioner,
  practitionerDecoder,
} from '@/models/PractitionerModel';
import { Result } from '@/utils/Result';

/**
 * For health pro only
 */
export class PractitionerRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  getPractitioner = (): Promise<Result<Practitioner, ApiErrorResponse>> => {
    return this.datasource.get('/practitioners/me/', practitionerDecoder);
  };

  updatePractitioner = (
    data: UpdatePractitioner,
  ): Promise<Result<Practitioner, ApiErrorResponse>> => {
    return this.datasource.patch(
      '/practitioners/me/',
      practitionerDecoder,
      data,
    );
  };
}

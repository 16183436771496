import React, { useLayoutEffect } from 'react';

import { ScreenSizesType, getScreenSize } from '@/theme/responsive';

export function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState<{
    width?: number;
    height?: number;
    screenSize: keyof ScreenSizesType;
  }>({
    width: undefined,
    height: undefined,
    screenSize: 'LG',
  });

  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        screenSize: getScreenSize(window.innerWidth),
      });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

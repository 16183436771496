import { useMemo } from 'react';

import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

import { PatientList } from '@/models/PatientModel';
import { MuiTable } from '@/uiKit/Table';

import { messageColumnDoctor } from './columns/messageColumnDoctor';
import { familyNameColumn } from './columns/patientNameColumn';
import { reportedPatientColumnDoctor } from './columns/reportedPatientColumnDoctor';
import { telemonitoringColumnDoctor } from './columns/telemonitoringColumnDoctor';

export type PatientForDoctor = Pick<
  PatientList,
  | 'id'
  | 'familyName'
  | 'givenName'
  | 'message'
  | 'hasNurseNotice'
  | 'telemonitoring_tags'
  | 'renewal_suggestion'
>;

type Props = {
  patients: PatientForDoctor[];
};

export const PatientDoctorTable = ({ patients }: Props) => {
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      familyNameColumn,
      messageColumnDoctor,
      reportedPatientColumnDoctor,
      telemonitoringColumnDoctor,
    ],
    [],
  );

  const table = useReactTable<PatientForDoctor>({
    data: patients,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleClick = (patient: PatientForDoctor) => {
    navigate(`/patients/${patient.id}`);
  };

  return <MuiTable table={table} handleRowClick={handleClick} />;
};

import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';

import { Icons } from '@/assets/icons';
import { Col, Row } from '@/components/layout/Flex';
import { useStyles } from '@/hooks/useStyles';

export type SideSheetHeaderProps = {
  title: string | React.ReactElement;
  subtitle?: string | React.ReactElement;
  exit?: () => void;
  className?: string;
};

/**
 * @deprecated Use MUI card header instead
 */
export const SideSheetHeader: React.FC<SideSheetHeaderProps> = ({
  exit,
  title,
  subtitle,
  className,
}) => {
  const styles = useStyles(makeStyles);

  return (
    <Row shrink className={cx(styles.header, className)}>
      <Col grow justify="center">
        {typeof title === 'string' ? (
          <Typography variant="h6">{title}</Typography>
        ) : (
          title
        )}
        {typeof subtitle === 'string' ? (
          <Typography variant="subtitleLarge">{subtitle}</Typography>
        ) : (
          subtitle
        )}
      </Col>
      {exit ? (
        <Icons.closeIcon
          className="w-10 h-10 cursor-pointer center"
          onClick={exit}
        />
      ) : null}
    </Row>
  );
};

const makeStyles = (theme: Theme) => ({
  header: css`
    padding: ${theme.spacing(12)} ${theme.spacing(12)};
    padding-bottom: ${theme.spacing(20)};
  `,
});

import React, { ReactElement } from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';

import { Tooltip } from '@/components/floating/Tooltip.tsx';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useStyles';
import Chip from '@/uiKit/Chip';

export type DetailCommentProps = {
  icon: ReactElement;
  commentText: string;
  tooltipText: string;
  className?: string;
};

export const DetailComment: React.FC<DetailCommentProps> = ({
  icon,
  commentText,
  tooltipText,
  className,
}) => {
  const styles = useStyles(makeStyles);

  return (
    <div className={styles.container}>
      <Tooltip
        placement="top"
        content={tooltipText}
        animation={{ duration: 50 }}
        hover={{ handleClose: null }}
      >
        <Chip className={cx(styles.chip, className)}>{icon}</Chip>
      </Tooltip>

      <div className={styles.text}>
        <Typo type="paragraph">{commentText}</Typo>
      </div>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    gap: ${theme.spacing(2)};
    padding-top: ${theme.spacing(2)};
  `,
  chip: css`
    cursor: auto;
    height: ${theme.spacing(12)};
    width: ${theme.spacing(12)};
    padding: 0;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
  `,
  text: css`
    text-align: left;
  `,
});

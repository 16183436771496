import { useEffect } from 'react';

import * as amplitude from '@amplitude/analytics-browser';

export const AmplitudeInit = () => {
  useEffect(() => {
    amplitude.init('994e3222c2b71201928efbf907d12da0', {
      autocapture: true,
      serverZone: 'EU',
    });
  }, []);
  return null;
};

import { useId } from '@floating-ui/react';
import { TextField, TextFieldProps } from '@mui/material';
import { FieldPath, FieldValues, useController } from 'react-hook-form';

type PropsSelectionFromForm<Form extends FieldValues> = {
  [K in FieldPath<Form>]: {
    name: K;
  };
}[FieldPath<Form>];

type OverwrittenInputProps = 'ref' | 'value';

/**
 * The `ref` and `value` props are managed by react-hook-form
 * The
 */
type FilteredInputProps = Omit<TextFieldProps, OverwrittenInputProps>;

type FormInputProps<Form extends FieldValues> = PropsSelectionFromForm<Form> &
  FilteredInputProps;

export const FormTextInput = <Form extends FieldValues>({
  name,
  variant = 'standard',
  ...props
}: FormInputProps<Form>) => {
  const { field, fieldState } = useController<Form>({
    name,
    disabled: props.disabled,
  });

  const id = useId();
  return (
    <TextField
      id={id}
      variant={variant}
      {...props}
      onChange={event => {
        field.onChange(event);
        props?.onChange?.(event);
      }}
      onBlur={event => {
        field.onBlur();
        props?.onBlur?.(event);
      }}
      value={field.value ?? ''}
      error={fieldState.invalid}
    />
  );
};

/**
 * AGPStatsModel
 *
 * Partial object of the agp stats
 * TODO find out what the real object looks like
 */
export type AGPStats = Record<
  number,
  {
    '5': number;
    '25': number;
    '50': number;
    '75': number;
    '95': number;
  }
>;

export const agpStatsDecoder = (value: unknown): AGPStats => value as AGPStats;

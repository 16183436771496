import React, { useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Input, InputAdornment, InputProps, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Schema, z } from 'zod';

import { useStyles } from '@/hooks/useStyles';
import {
  DiabetesParameters,
  DiabetesParametersType,
} from '@/models/DiabetesDataModel';

export type ThresholdInputProps = {
  diabetesParameters: DiabetesParameters;
  threshold: DiabetesParametersType;
  onSubmit: (value: number) => void;
  className?: string;
  inputProps?: InputProps;
};

export const ThresholdInput: React.FC<ThresholdInputProps> = ({
  diabetesParameters,
  threshold,
  onSubmit,
  className,
  inputProps,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>(
    diabetesParameters[threshold].toString(),
  );

  const [parsedValue, error] = useMemo(() => {
    const data =
      thresholdValueSchemas(diabetesParameters)[threshold].safeParse(value);
    if (data.success) {
      return [data.data, false];
    } else {
      const parsedValue = value ? parseFloat(value) : 0;
      if (!Number.isNaN(parsedValue)) {
        return [parsedValue, true];
      }
    }
    return [0, true];
  }, [value, diabetesParameters, threshold]);

  const styles = useStyles(makeStyles, parsedValue, error);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!error && parsedValue !== diabetesParameters[threshold]) {
        onSubmit(parsedValue);
      }
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [error, parsedValue, onSubmit, diabetesParameters, threshold]);

  return (
    <Input
      onChange={event => setValue(event.target.value)}
      type="text"
      error={error}
      classes={{ root: cx(styles.root, className), input: styles.input }}
      disableUnderline
      value={value}
      endAdornment={
        <InputAdornment position="end" className={styles.adornment}>
          {t('thresholdSettings.units')}
        </InputAdornment>
      }
      {...inputProps}
    />
  );
};

const thresholdValueSchemas = (
  diabetesParameters: DiabetesParameters,
): Record<DiabetesParametersType, Schema<number>> => ({
  thresholdHyperglycemiaSevere: z.coerce
    .number()
    .gt(diabetesParameters.thresholdHyperglycemia),
  thresholdHyperglycemia: z.coerce
    .number()
    .gt(diabetesParameters.thresholdHypoglycemia)
    .lt(diabetesParameters.thresholdHyperglycemiaSevere),
  thresholdHypoglycemia: z.coerce
    .number()
    .gt(diabetesParameters.thresholdHypoglycemiaSevere)
    .lt(diabetesParameters.thresholdHyperglycemia),
  thresholdHypoglycemiaSevere: z.coerce
    .number()
    .lt(diabetesParameters.thresholdHypoglycemia),
});

const makeStyles = (theme: Theme, value: number, error: boolean) => ({
  root: css``,
  input: css`
    border-bottom: ${error
        ? theme.palette.error.main
        : theme.palette.text.primary}
      ${theme.spacing(error ? 1 : 0.5)} solid;
    color: ${error ? theme.palette.error.main : theme.palette.text.primary};
    padding: 0;
    text-align: end;
    width: ${value.toString().length + 0.1}ch;
  `,
  adornment: css`
    .MuiTypography-root {
      color: ${error ? theme.palette.error.main : theme.palette.text.primary};
    }
  `,
});

import React, { useContext } from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { FontSizes } from '@/assets/fonts';
import { Icons } from '@/assets/icons';
import {
  ChatFilterContext,
  ChatStatusContext,
} from '@/components/chat/ChatContext';
import { Tooltip } from '@/components/floating/Tooltip';
import { Row } from '@/components/layout/Flex';
import { Typo } from '@/components/typography/Text';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useStyles';
import { Queries } from '@/queries/Queries';

export type PatientNavigationIconsProps = {
  patientId: string;
};

export const PatientNavigationIcons: React.FC<PatientNavigationIconsProps> = ({
  patientId,
}) => {
  const currentPractitioner = Queries.practitioner.usePractitioner({});
  const isNurse = currentPractitioner.data?.qualification === 'nurse';

  return (
    <Row align="center">
      {isNurse && <PhoneIcon patientId={patientId} />}
      <ChatIcon />
    </Row>
  );
};

const PhoneIcon: React.FC<PatientNavigationIconsProps> = ({ patientId }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  const patientInformationData = Queries.practitioner.usePatient(patientId);
  const patient = patientInformationData.data;

  return (
    <Tooltip
      className={styles.container}
      floaterClassName={styles.phoneTooltip}
      content={
        patient?.contactPhone ? (
          <Typo
            type="paragraph"
            className={styles.phoneAction}
            Component="a"
            href={`tel://${patient.contactPhone}`}
          >
            {patient.contactPhone}
          </Typo>
        ) : (
          <Typo type="paragraph" className={styles.phoneNone}>
            {t('patientInfo.noPhoneNumber')}
          </Typo>
        )
      }
      placement="bottom-end"
    >
      <Icons.phoneOutline className={styles.phoneIcon} />
    </Tooltip>
  );
};

const ChatIcon = () => {
  const { setOpen, open } = useContext(ChatStatusContext);
  const patientId = usePatientIdFromURL();
  const patient = Queries.practitioner.usePatient(patientId ?? '');
  const styles = useStyles(makeStyles);
  const selectedFilter = useContext(ChatFilterContext);
  const queryClient = useQueryClient();

  const { data: messagesCount } =
    Queries.practitioner.useGetPatientMessageCount(patientId ?? '');
  const currentPractitioner = Queries.practitioner.usePractitioner({});
  const isNurse = currentPractitioner.data?.qualification === 'nurse';

  if (!messagesCount || !patient) {
    return null;
  }

  const count = isNurse
    ? messagesCount.sent_by_practitioner_to_all +
      messagesCount.sent_by_patient +
      messagesCount.sent_by_practitioner_to_practitioner
    : messagesCount.sent_by_practitioner_to_practitioner +
      messagesCount.sent_by_dg_patient;

  return (
    <div className={styles.container}>
      <Icons.letter
        className={styles.letterIcon}
        onClick={() => {
          setOpen?.(!open);
          Queries.chat.useChat.invalidate(queryClient, {
            subjectId: patientId,
            visibility: selectedFilter?.filter,
          });
        }}
      />

      {patient && count > 0 && <span className={styles.badge}>{count}</span>}
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    margin-left: ${theme.spacing(12)};
    position: relative;
    :hover {
      svg {
        opacity: 0.7;
      }
    }
  `,
  phoneIcon: css`
    color: ${theme.palette.primary.main};
    width: ${FontSizes.h4};
    height: ${FontSizes.h4};
  `,
  bellWrapper: css`
    position: relative;
    cursor: pointer;
  `,
  notificationBubble: css`
    position: absolute;
    border-radius: 100px;
    text-align: center;
    width: ${FontSizes.bodySmall};
    height: ${FontSizes.bodySmall};
    transform: translate(100%, -200%);
    background-color: ${theme.palette.error.main};
    color: ${theme.palette.grey[100]};
  `,
  phoneTooltip: css`
    padding: 0;
    cursor: pointer;
  `,
  phoneNone: css`
    padding: ${theme.spacing(4)} ${theme.spacing(8)};
  `,
  letterIcon: css`
    cursor: pointer;
    width: ${FontSizes.h4};
    height: ${FontSizes.h4};
    color: ${theme.palette.primary.main};
  `,
  phoneAction: css`
    display: flex;
    padding: ${theme.spacing(4)} ${theme.spacing(8)};
    cursor: pointer;
    &:hover {
      background-color: ${theme.palette.action.hover};
    }
  `,
  badge: css`
    /* circle shape, size and position */
    position: absolute;
    right: -0.7em;
    top: -0.7em;
    width: ${theme.spacing(8)};
    height: ${theme.spacing(8)};
    border-radius: 0.8em; /* or 50%, explained below. */
    border: 0.05em solid red;
    background-color: red;

    /* number size and position */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7em;
    color: white;
  `,
});

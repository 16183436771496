import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { Contact } from '@/models/ContactModel';
import { ContactData } from '@/pages/patient-medical-file/contact-patient/ContactPatient.schema';
import { ContactPatientForm } from '@/pages/patient-medical-file/contact-patient/ContactPatientForm';
import { ContactView } from '@/pages/patient-medical-file/contact-patient/ContactView';
import { Queries } from '@/queries/Queries';

export type ContactMode =
  | { mode: 'view' }
  | { mode: 'edit'; contact: Contact }
  | { mode: 'create' };

export type ContactPatientProps = {
  patientId: string;
  mode: ContactMode;
  setMode: React.Dispatch<React.SetStateAction<ContactMode>>;
};

export const ContactPatient: React.FC<ContactPatientProps> = ({
  patientId,
  mode,
  setMode,
}) => {
  const styles = useStyles(makeStyles);
  const createContact = Queries.practitioner.useCreateContact();
  const updateContact = Queries.practitioner.useUpdateContact();
  const deleteContact = Queries.practitioner.useDeleteContact();

  const { data: contacts } = Queries.practitioner.useListContacts(patientId);

  const { t } = useTranslation();

  const setEditing = (contact: Contact) => setMode({ mode: 'edit', contact });
  const setViewing = () => setMode({ mode: 'view' });
  const onEdit = (editedContact: Contact, data: ContactData) => {
    updateContact.mutate(
      {
        patientId: patientId,
        contactId: editedContact.id,
        data: {
          ...data,
        },
      },
      {
        onSuccess: setViewing,
      },
    );
  };
  const onCreate = (data: ContactData) => {
    createContact.mutate(
      { patientId: patientId, data },
      {
        onSuccess: setViewing,
      },
    );
  };
  const onDelete = (contact: Contact) => {
    deleteContact.mutate({ patientId: patientId, contactId: contact.id });
  };

  const isDeleting = deleteContact.isPending;

  return (
    <div className={styles.container}>
      <div className={styles.viewList}>
        {contacts?.map(contact => {
          switch (mode.mode) {
            case 'view':
              return (
                <ContactView
                  key={contact.id}
                  contact={contact}
                  setEditing={isDeleting ? undefined : setEditing}
                  onDelete={isDeleting ? undefined : onDelete}
                  className={styles.contact}
                />
              );
            case 'edit': {
              if (mode.contact.id !== contact.id) {
                return (
                  <ContactView
                    key={contact.id}
                    contact={contact}
                    className={styles.contact}
                  />
                );
              }
              return (
                <ContactPatientForm
                  key={mode.contact.id}
                  isSubmitting={false}
                  initialValues={mode.contact}
                  onSubmit={data => onEdit(mode.contact, data)}
                  cancel={setViewing}
                  submitText={t('contact.editRelationship')}
                />
              );
            }
            case 'create':
              return (
                <ContactView
                  key={contact.id}
                  contact={contact}
                  className={styles.contact}
                />
              );
          }
        })}
      </div>
      {mode.mode === 'create' ? (
        <ContactPatientForm
          key="create"
          isSubmitting={createContact.isPending}
          onSubmit={onCreate}
          cancel={setViewing}
          submitText={t('contact.addRelationship')}
        />
      ) : null}
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(16)};
  `,
  viewList: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${theme.spacing(16)};
  `,
  contact: css`
    width: ${theme.spacing(128)};
  `,
});

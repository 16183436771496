import { css } from '@emotion/css';
import { Box, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { useKeycloak } from '@/keycloak';

export const ImpersonatingBanner = () => {
  const keycloak = useKeycloak();
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  if (!keycloak.impersonating) {
    return null;
  }

  return (
    <Box className={styles.banner}>
      <Typography>{t('impersonationWarning')}</Typography>
    </Box>
  );
};
const makeStyles = (theme: Theme) => ({
  banner: css`
    background-color: ${theme.palette.error.main};
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 1200;
    padding: ${theme.spacing(5)};
  `,
});

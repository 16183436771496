import { css, cx } from '@emotion/css';
import { Select, SelectProps, Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';

export const DropDownSelect = (props: SelectProps) => {
  const styles = useStyles(makeStyles);
  return <Select {...props} className={cx(styles.select, props.className)} />;
};

const makeStyles = (theme: Theme) => ({
  select: css`
    background-color: #f0f0f0;
    border: none;
    border-radius: 24px;
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    &:hover .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    & .MuiSelect-select {
      padding: ${theme.spacing(2)} ${theme.spacing(8)};
    }
  `,
});

import { css } from '@emotion/css';

import { useStyles } from '@/hooks/useStyles';
import { MailPreferenceUpdate } from '@/models/MailPreferenceModel';
import { MailPreferenceItem } from '@/pages/account/preferences/MailPreferenceItem';
import { Queries } from '@/queries/Queries';

export const MailPreferenceBody = () => {
  const styles = useStyles(makeStyles);
  const mailPrefRequest = Queries.mailPreference.useMailPreference({});
  const updatePreference = Queries.mailPreference.useUpdatePreference();

  const mailData = mailPrefRequest.isSuccess ? mailPrefRequest.data : [];

  const updateMailPreference = (data: MailPreferenceUpdate) => {
    updatePreference.mutate([data]);
  };
  return (
    <div className={styles.container}>
      {mailData.map(mail => (
        <MailPreferenceItem
          onAddMailToUpdate={updateMailPreference}
          mail={mail}
          key={mail.id}
        ></MailPreferenceItem>
      ))}
    </div>
  );
};
const makeStyles = () => ({
  container: css`
    display: flex;
    flex-wrap: wrap;
  `,
});

import { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { DateTime } from 'luxon';
import 'react-calendar/dist/Calendar.css';
import { useSearchParams } from 'react-router-dom';

import { Row } from '@/components/layout/Flex';
import {
  PeriodPicker,
  SelectNextPeriod,
  SelectPreviousPeriod,
} from '@/components/period-picker/PeriodPicker/PeriodPicker';
import { Periodicity } from '@/components/period-picker/PeriodPicker/PeriodSelector.tsx';
import { useStyles } from '@/hooks/useStyles';
import { periodicities } from '@/pages/patient-monitoring/PatientMonitoringPeriod.ts';
import { usePatientMonitoringContext } from '@/pages/patient-monitoring/PatientMonitoringState.tsx';
import { SelectedPeriod } from '@/pages/patient-monitoring/SelectedPeriod';
import { DateUtils, toDateTime } from '@/utils/date';

export default function HeaderMonitoring() {
  const state = usePatientMonitoringContext(s => s);

  const [nextPeriodDisable, setNextPeriodDisable] = useState(true);

  const styles = useStyles(makeStyles);

  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    const endDate = state.to;
    if (
      DateUtils.compareDates(endDate, DateTime.now().minus({ days: 1 })) >= 0
    ) {
      setNextPeriodDisable(true);
    } else {
      setNextPeriodDisable(false);
    }
  }, [state.to]);

  const onChangePeriodicity = (newPeriod: Periodicity) => {
    const endDate = toDateTime(state.to);
    const newStart = endDate.minus({ days: newPeriod.durationInDays - 1 });

    const isoStart = newStart.toISODate();
    const isoEnd = endDate.toISODate();
    if (isoStart && isoEnd) {
      setSearchParams({ from: isoStart, to: isoEnd });
    }
  };

  const goToPreviousPeriodHandler = () => {
    const previousStart = toDateTime(state.from);
    const previousEnd = toDateTime(state.to);
    const newStart = previousStart.minus({
      days: state.periodicity.durationInDays,
    });

    const newEnd = previousEnd.minus({
      days: state.periodicity.durationInDays,
    });

    const isoStart = newStart.toISODate();
    const isoEnd = newEnd.toISODate();
    if (isoStart && isoEnd) {
      setSearchParams({ from: isoStart, to: isoEnd });
    }
  };

  const goToNextPeriodHandler = () => {
    const previousStart = toDateTime(state.from);
    const previousEnd = toDateTime(state.to);
    let newStart;
    let newEnd = previousEnd.plus({ days: state.periodicity.durationInDays });
    const today = DateTime.now();
    if (DateUtils.compareDates(newEnd, today) >= 0) {
      newEnd = today;
      newStart = today.minus({ days: state.periodicity.durationInDays });
    } else {
      newStart = previousStart.plus({ days: state.periodicity.durationInDays });
    }

    const isoStart = newStart.toISODate();
    const isoEnd = newEnd.toISODate();
    if (isoStart && isoEnd) {
      setSearchParams({ from: isoStart, to: isoEnd });
    }
  };

  return (
    <Row className={styles.container}>
      <Row className={styles.picker}>
        <Row>
          <SelectPreviousPeriod onClick={goToPreviousPeriodHandler} />
          <SelectedPeriod from={state.from} to={state.to} />
          <SelectNextPeriod
            onClick={goToNextPeriodHandler}
            disabled={nextPeriodDisable}
          />
        </Row>

        <PeriodPicker
          key={state.periodicity.index}
          periodicities={periodicities}
          current={state.periodicity}
          onChange={onChangePeriodicity}
        />
      </Row>
    </Row>
  );
}

const makeStyles = (theme: Theme) => ({
  container: css`
    width: 100%;
    gap: ${theme.spacing(4)};
  `,
  picker: css`
    flex-grow: 1;
    align-items: stretch;
    gap: ${theme.spacing(4)};
    flex-wrap: wrap;
  `,
});

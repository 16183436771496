import { css } from '@emotion/css';
import { Drawer, Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';

type TelemonitoringDrawerProps = {
  close: () => void;
  header: React.ReactNode;
  children: React.ReactNode;
  isOpen?: boolean;
};

export const TelemonitoringDrawer = ({
  isOpen = true,
  close,
  header,
  children,
}: TelemonitoringDrawerProps) => {
  const styles = useStyles(makeStyles);
  return (
    <Drawer anchor="right" onClose={close} open={isOpen}>
      <div className={styles.container}>
        <div className={styles.drawerHeader}>{header}</div>
        {children}
      </div>
    </Drawer>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    width: 600px;
    padding-left: ${theme.spacing(32)};
    padding-right: ${theme.spacing(32)};
    padding-top: ${theme.spacing(8)};
  `,
  drawerHeader: css`
    display: flex;
    justify-content: center;
    padding: ${theme.spacing(2)};
    text-align: center;
  `,
});

import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles';
import { Tag } from '@/uiKit/tag/Tag';

type MessageCellType = {
  sent_by_patient: number;
  sent_by_practitioner_to_all: number;
  sent_by_practitioner_to_practitioner: number;
};

export const MessageCellNurse = ({
  sent_by_patient,
  sent_by_practitioner_to_all,
  sent_by_practitioner_to_practitioner,
}: MessageCellType) => {
  const styles = useStyles(makeStyles);
  const messageCount =
    sent_by_patient +
    sent_by_practitioner_to_all +
    sent_by_practitioner_to_practitioner;

  if (messageCount <= 0) {
    return null;
  }
  const tagContent =
    sent_by_patient &&
    !(sent_by_practitioner_to_all + sent_by_practitioner_to_practitioner) ? (
      <div className={styles.messageTagIcon}>
        <Icons.letter height="1em" width="1em" />
        <div>{messageCount}</div>
      </div>
    ) : (
      <div className={styles.messageTagIcon}>
        <Icons.letter height="1em" width="1em" />
        <Icons.stethoscope height="1em" width="1em" />
        <div>{messageCount}</div>
      </div>
    );

  return (
    <div className={styles.messageTagIcon}>
      <Tag color="blue" label={tagContent} />
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  messageTagIcon: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  `,
});

import React from 'react';

import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { TextCardTitle } from '@/components/card/CardTitle';
import { Col, Row } from '@/components/layout/Flex';
import { Loader } from '@/components/loading/Loader';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useStyles';
import { Practitioner } from '@/models/PractitionerModel';
import { ProfessionsTranslations } from '@/models/ProfessionModel';
import { Queries } from '@/queries/Queries';
import { userFormattedName } from '@/utils/user.ts';

export const GeneralInformation: React.FC = () => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  return (
    <Card className={styles.container} elevation={0}>
      <TextCardTitle
        id="security-title"
        title={t('pages.account.generalInformation.title')}
      />
      <CardBody>
        <GeneralInformationContent />
      </CardBody>
    </Card>
  );
};

export const GeneralInformationContent: React.FC = () => {
  const practitioner = Queries.practitioner.usePractitioner({});
  const { t } = useTranslation();

  switch (practitioner.status) {
    case 'success':
      return <PractitionerInformation practitioner={practitioner.data} />;
    case 'pending':
      return <Loader size="S" />;
    case 'error':
      return <Typo type="error">{t('errors.unknownError')}</Typo>;
  }
};

type InformationProps = {
  practitioner: Practitioner;
};

const PractitionerInformation: React.FC<InformationProps> = ({
  practitioner,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  return (
    <Row align="center" className={styles.infoDisplay}>
      <Col>
        <Typography variant="bodyLarge">
          {userFormattedName(practitioner?.familyName, practitioner?.givenName)}
        </Typography>
        <Typo type="supplement">{practitioner.user.email}</Typo>
        <Typo type="supplement">
          {t(ProfessionsTranslations[practitioner.qualification])}
        </Typo>
      </Col>
    </Row>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css``,
  infoDisplay: css`
    column-gap: ${theme.spacing(12)};
  `,
});

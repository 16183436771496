import { css } from '@emotion/css';
import { FormControlLabel, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import {
  MailPreference,
  MailPreferenceUpdate,
} from '@/models/MailPreferenceModel';

type MailPreferenceItemType = {
  mail: MailPreference;
  onAddMailToUpdate: (data: MailPreferenceUpdate) => void;
};
export const MailPreferenceItem = ({
  mail,
  onAddMailToUpdate,
}: MailPreferenceItemType) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  const clickHandler = () => {
    onAddMailToUpdate({ id: mail.id });
  };

  const label = t(`pages.account.preferences.${mail.label}`);

  return (
    <div className={styles.item}>
      <FormControlLabel
        control={
          <Switch
            onChange={clickHandler}
            checked={mail.enabled}
            color="success"
          />
        }
        label={label}
      />
    </div>
  );
};

const makeStyles = () => ({
  item: css`
    width: 33.33%;
    box-sizing: border-box;
    color: black;
  `,
});

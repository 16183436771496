import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';

import { FontSizes } from '@/assets/fonts';
import { IconType } from '@/assets/icons';
import { Button, ButtonProps } from '@/components/button/Button';
import { useStyles } from '@/hooks/useStyles';

type IconPlacement = 'left' | 'right';
export type IconButtonProps = ButtonProps & {
  icon: IconType;
  placement: IconPlacement;
  iconClassname?: string;
};

/**
 * @deprecated use MUI Button instead
 */
export const IconButton: React.FC<IconButtonProps> = ({
  icon: Icon,
  buttonType,
  textType = 'button',
  placement,
  iconClassname,
  children,
  ...props
}) => {
  const styles = useStyles(makeStyles);

  return (
    <Button buttonType={buttonType} {...props}>
      <>
        {placement === 'left' ? (
          <Icon
            className={cx(
              styles.icon,
              {
                [styles.iconLeft]: children !== undefined && children !== null,
              },
              iconClassname,
            )}
          />
        ) : null}
        {typeof children === 'string' ? (
          <Typography variant={textType} className={styles.text}>
            {children}
          </Typography>
        ) : (
          children
        )}
        {placement === 'right' ? (
          <Icon className={cx(styles.icon, styles.iconRight, iconClassname)} />
        ) : null}
      </>
    </Button>
  );
};

const makeStyles = (theme: Theme) => ({
  text: css`
    color: inherit;
  `,
  icon: css`
    width: ${FontSizes.body};
    height: ${FontSizes.body};
  `,
  iconLeft: css`
    margin-right: ${theme.spacing(8)};
  `,
  iconRight: css`
    margin-left: ${theme.spacing(8)};
  `,
  plain: css``,
});

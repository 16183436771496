import { useEffect, useRef, useState } from 'react';

import { useTheme } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';

import { Spinner } from '@/components/Spinner';
import { ThemeDim } from '@/theme/types';
import { Status } from '@/utils/ReactQueryUtils';

interface LoadingButtonProps extends ButtonProps {
  status?: Status;
}

export const ButtonLoader = ({
  status,
  children,
  ...props
}: LoadingButtonProps) => {
  const theme = useTheme();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [buttonHeight, setButtonHeight] = useState('auto');

  useEffect(() => {
    if (buttonRef.current) {
      setButtonHeight(`${buttonRef.current.clientHeight}px`);
    }
  }, []);
  const spinnerHeight = `calc(${buttonHeight} - 24px)`;
  return (
    <Button
      {...props}
      ref={buttonRef}
      disabled={status === 'pending' ? true : props.disabled}
      startIcon={
        status === 'pending' ? (
          <Spinner
            foregroundColor={theme.palette.primary.main}
            backgroundColor={theme.palette.primary.contrastText}
            height={spinnerHeight as ThemeDim}
          />
        ) : (
          props.startIcon
        )
      }
    >
      {children}
    </Button>
  );
};

import { useMemo } from 'react';

import { css } from '@emotion/css';
import { Button, DialogContent, DialogTitle, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { PrescriptionGenerationHandler } from '@/models/PrescriptionGenerationModel';
import { Queries } from '@/queries/Queries';
import { ButtonLoader } from '@/uiKit/ButtonLoader';

import { PrescriptionGenerationData } from './GeneratePrescriptionReducer';

type PrescriptionSignatureProps = {
  prescription: Blob;
  onPrescriptionSigned: PrescriptionGenerationHandler;
  prescriptionData: PrescriptionGenerationData;
  onBack: () => void;
};

export const PrescriptionSignature = ({
  onPrescriptionSigned,
  prescription,
  prescriptionData,
  onBack,
}: PrescriptionSignatureProps) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  const signPrescriptionMutation = Queries.prescription.useSignPrescription();
  const { status: telemonitoringStatus } =
    Queries.practitioner.useCreatePatient(); // TODO check also renew careplan mutation status

  const pdf = useMemo(() => {
    return URL.createObjectURL(
      new Blob([prescription], { type: 'application/pdf' }),
    );
  }, [prescription]);

  return (
    <>
      <DialogTitle>{t('diabetesForms.prescriptionGeneration')}</DialogTitle>
      <DialogContent className={styles.gap}>
        <div className={styles.row}>
          <iframe src={pdf} width="1000" height="500" />
        </div>
        <div className={styles.footer}>
          <Button variant="outlined" onClick={onBack}>
            {t('button.cancel')}
          </Button>
          <ButtonLoader
            variant="contained"
            onClick={() => {
              signPrescriptionMutation.mutate(prescriptionData, {
                onSuccess: data => {
                  onPrescriptionSigned({
                    prescription: data,
                    patientWeight: prescriptionData.weight,
                    patientBirthDate: prescriptionData.birthDate,
                  });
                },
              });
            }}
            status={
              signPrescriptionMutation.status === 'success'
                ? telemonitoringStatus
                : signPrescriptionMutation.status
            }
          >
            {t('prescription.sign')}
          </ButtonLoader>
        </div>
      </DialogContent>
    </>
  );
};

const makeStyles = (theme: Theme) => ({
  row: css`
    display: flex;
    align-items: center;
    & > :first-of-type {
      margin-right: ${theme.spacing(12)};
    }
  `,
  gap: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(12)};
  `,
  footer: css`
    display: flex;
    justify-content: space-between;
    padding: 0 ${theme.spacing(40)};
  `,
  weightWidth: css`
    width: 48px;
    text-align: right;
  `,
  birthDateWidth: css`
    width: 100px;
  `,
});

import { Dispatch, useReducer } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { TelemonitoringDrawer } from '@/components/sidesheet/TelemonitoringDrawer';
import { Queries } from '@/queries/Queries';

import { PatientInformation } from './PatientInformation';
import {
  TelemonitoringFormData,
  TelemonitoringInformation,
} from './TelemonitoringInformation';
import {
  AddPatientAction,
  AddPatientFormData,
  addPatientReducer,
} from './TelemonitoringReducer';
import { PrescriptionModal } from './prescriptionGeneration/PrescriptionModal';

type CreatePatientProps = {
  close: () => void;
};

type ContainerProps = {
  close: () => void;
  addPatientData: AddPatientFormData;
  dispatch: Dispatch<AddPatientAction>;
};

type TeleMonitoringStepProps = {
  addPatientData: AddPatientFormData & {
    step: 'CarePlan' | 'Prescription';
  };
  dispatch: Dispatch<AddPatientAction>;
};

const TelemonitoringStep = ({
  addPatientData,
  dispatch,
}: TeleMonitoringStepProps) => {
  // this step is in a seperate component to restrict the addPatientData type
  const navigate = useNavigate();
  const createPatientMutation = Queries.practitioner.useCreatePatient();
  const { data: carePlanDirectives } =
    Queries.practitioner.useCarePlanDirectives({});

  if (!carePlanDirectives) {
    return null;
  }

  const handleSubmit = (data: TelemonitoringFormData) => {
    // If the patient has gestational diabetes and doesn't need insulin, we can create the patient directly without a prescription
    if (
      data.condition.diabetesType === 'gestational' &&
      data.condition.insulinType === 'noInsulin'
    ) {
      createPatientMutation.mutate(
        {
          patient: addPatientData.patientInfo,
          condition: data.condition,
        },
        {
          onSuccess: data => {
            navigate(`/patients/${data.id}`);
          },
        },
      );
    } else {
      dispatch({
        type: 'TO_PRESCRIPTION',
        carePlan: data.carePlan,
        patientInfo: addPatientData.patientInfo,
        condition: data.condition,
      });
    }
  };

  return (
    <>
      <TelemonitoringInformation
        onBack={data => {
          dispatch({
            type: 'BACK_TO_PATIENT_INFO',
            carePlan: data.carePlan,
            condition: data.condition,
            patientInfo: addPatientData.patientInfo,
          });
        }}
        onSubmit={handleSubmit}
        carePlanFormData={addPatientData.carePlan}
        conditionFormData={addPatientData.condition}
        patientInfo={addPatientData.patientInfo}
      />
      {addPatientData.step == 'Prescription' ? (
        <PrescriptionModal
          carePlanCreationDataDirectives={
            carePlanDirectives.carePlanCreationDataDirectives
          }
          telemonitoringTier={
            carePlanDirectives.telemonitoringTierMap[
              addPatientData.carePlan.telemonitoringCriteria
            ]
          }
          patientInfo={addPatientData.patientInfo}
          duration={addPatientData.carePlan.duration}
          termDate={addPatientData.condition.termDate}
          onPrescriptionReady={({
            prescription,
            patientWeight,
            patientBirthDate,
          }) => {
            createPatientMutation.mutate(
              {
                patient: {
                  birthDate: patientBirthDate,
                  weight: patientWeight,
                  ...addPatientData.patientInfo,
                },
                care_plan: {
                  ...addPatientData.carePlan,
                  prescription: prescription,
                },
                condition: addPatientData.condition,
              },
              {
                onSuccess: data => {
                  navigate(`/patients/${data.id}`);
                },
              },
            );
          }}
          onClose={() =>
            dispatch({
              type: 'BACK_TO_CAREPLAN',
              ...addPatientData,
            })
          }
        />
      ) : null}
    </>
  );
};

const Container = ({ close, addPatientData, dispatch }: ContainerProps) => {
  switch (addPatientData.step) {
    case 'PatientInfo':
      return (
        <PatientInformation
          close={close}
          patientInfo={addPatientData.patientInfo}
          onSubmit={data => {
            dispatch({
              type: 'TO_CAREPLAN',
              patientInfo: data,
              carePlan: addPatientData.carePlan,
            });
          }}
        />
      );
    case 'CarePlan':
    case 'Prescription':
      return (
        <TelemonitoringStep
          addPatientData={addPatientData}
          dispatch={dispatch}
        />
      );
    default:
      return null;
  }
};

export const CreatePatient = ({ close }: CreatePatientProps) => {
  const { t } = useTranslation();

  const [addPatientData, dispatch] = useReducer(addPatientReducer, {
    step: 'PatientInfo',
  });

  return (
    <TelemonitoringDrawer
      close={close}
      header={
        <Typography variant="h5">{t('pages.patients.addPatient')}</Typography>
      }
    >
      <Container
        close={close}
        addPatientData={addPatientData}
        dispatch={dispatch}
      />
    </TelemonitoringDrawer>
  );
};

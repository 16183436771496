import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { FontSizes } from '@/assets/fonts';
import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles';
import { Tag } from '@/uiKit/tag/Tag';

type ReportedPatientCellType = {
  hasNurseNotice: boolean;
  roleForPatient: string;
};
export const ReportedPatientCell = ({
  hasNurseNotice,
  roleForPatient,
}: ReportedPatientCellType) => {
  const styles = useStyles(makeStyles);
  return (
    <div>
      {hasNurseNotice ? (
        <div>
          {roleForPatient === 'doctor' ? (
            <Tag
              color="blue"
              icon={<Icons.stethoscope className={styles.icon} />}
              className={styles.signaledTag}
            />
          ) : (
            <Tag
              color="neutral"
              icon={<Icons.forward className={styles.icon} />}
              className={styles.signaledTag}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  icon: css`
    width: ${FontSizes.bodySmall};
    height: ${FontSizes.bodySmall};
  `,
  signaledTag: css`
    width: ${theme.spacing(16)};
    height: ${theme.spacing(16)};
  `,
});

import {
  NetworkMode,
  QueryClient,
  UseMutationOptions,
  useMutation,
  useMutationState,
  useQueryClient,
} from '@tanstack/react-query';

import { ApiErrorResponse } from '@/models/ApiErrorResponse';

/**
 * Context passed to the onMutate, onSuccess, onError and onSettled callbacks
 * QueryClient, so it can be used to invalidate queries, get query data, etc.
 */
export type MutationContext = {
  queryClient: QueryClient;
};

/**
 * Options for the createMutation function
 * Selection of some options from useMutation
 * https://tanstack.com/query/latest/docs/framework/react/reference/useMutation
 */
export type CreateMutationOptions<RequestData, ResponseData> = {
  gcTime?: number;
  networkMode?: NetworkMode;
  retry?:
    | boolean
    | number
    | ((failureCount: number, error: ApiErrorResponse) => boolean);
  retryDelay?:
    | number
    | ((retryAttempt: number, error: ApiErrorResponse) => number);
  scope?: {
    id: string;
  };
  onMutate?: (
    variables: RequestData,
    context: MutationContext,
  ) => Promise<void> | void;
  onSuccess?: (
    data: ResponseData,
    variables: RequestData,
    context: MutationContext,
  ) => Promise<unknown> | unknown;
  onError?: (
    error: ApiErrorResponse,
    variables: RequestData,
    context: MutationContext,
  ) => Promise<unknown> | unknown;
  onSettled?: (
    data: ResponseData | undefined,
    error: ApiErrorResponse | null,
    variables: RequestData,
    context: MutationContext,
  ) => Promise<unknown> | unknown;
};

/**
 * Same as the options of useMutation but with some fields removed
 * @see UseMutationOptions
 *
 * mutationFn: Already defined in the createMutation function and should not be modified
 * networkMode: Depends on the data source and should not be modified by the UI
 * meta: Do not use if possible (untyped)
 * queryClient: Should use the default queryClient from the context (remove from omit only if you know what you are doing)
 *
 */
type FilteredUseMutationOptions<RequestData, ResponseData> = Omit<
  UseMutationOptions<ResponseData, ApiErrorResponse, RequestData>,
  'mutationFn' | 'networkMode' | 'throwOnError' | 'meta' | 'queryClient'
>;

/**
 * Creates a new mutation
 * Returns a useMutation hook with an already defined behavior (the mutationFn and networkMode are already set) and some default options
 *
 * Usage:
 * - First call createMutation with a function outside the component tree. You can also add other options like retry, gcTime, etc.
 * ```ts
 * type EditPatientData = {
 *    patientId: string;
 *    givenName: string;
 *    familyName: string;
 * };
 *
 * export const useEditPatient = createMutation({
 *    mutationFn: async (data: EditPatientData) => {
 *        // Your API / Storage call here
 *        return await editPatient(data);
 *    },
 *    onSuccess: (data) => { // Data is the response from the mutationFn (a Patient in this case)
 *        TextToast.success('Patient edited');
 *    }
 * });
 * ```
 *
 * - Then use the hook in your component
 * ```tsx
 * const editPatient = useEditPatient({ // The options (optional), same as useMutation with some fields removed (see FilteredUseMutationOptions)
 *    onSuccess: (data) => {}, // Redefining success does not override the one defined in createMutation. Same for onMutate, onError and onSettled
 *    // Other options get merged and can override the ones defined in createMutation
 * });
 * editPatient.mutate({ patientId: '123', givenName: 'John', familyName: 'Doe' }, {
 *    onSuccess: (data) => {} // This success will be called after the one defined in createMutation and useMutation
 * });
 * ```
 *
 */
export const createMutation = <RequestData, ResponseData>(
  mutationName: string,
  mutationFn: (data: RequestData) => Promise<ResponseData>,
  createMutationOptions?: CreateMutationOptions<RequestData, ResponseData>,
) => {
  const useMutationQuery = (
    options?: FilteredUseMutationOptions<RequestData, ResponseData>,
  ) => {
    const queryClient = useQueryClient();
    const {
      onMutate: createOnMutate,
      onSuccess: createOnSuccess,
      onError: createOnError,
      onSettled: createOnSettled,
      ...restCreateMutationOptions
    } = createMutationOptions || {};
    const {
      onMutate: optionsOnMutate,
      onSuccess: optionsOnSuccess,
      onError: optionsOnError,
      onSettled: optionsOnSettled,
      ...restOptions
    } = options || {};

    const useMutationQuery = useMutation<
      ResponseData,
      ApiErrorResponse,
      RequestData
    >(
      {
        mutationKey: [mutationName],
        mutationFn,
        onMutate: async variables => {
          createOnMutate && (await createOnMutate(variables, { queryClient }));
          optionsOnMutate && (await optionsOnMutate(variables));
        },
        onSuccess: async (data, variables) => {
          createOnSuccess &&
            (await createOnSuccess(data, variables, { queryClient }));
          optionsOnSuccess &&
            (await optionsOnSuccess(data, variables, { queryClient }));
        },
        onError: async (error, variables) => {
          createOnError &&
            (await createOnError(error, variables, { queryClient }));
          optionsOnError &&
            (await optionsOnError(error, variables, { queryClient }));
        },
        onSettled: async (data, error, variables) => {
          createOnSettled &&
            (await createOnSettled(data, error, variables, { queryClient }));
          optionsOnSettled &&
            (await optionsOnSettled(data, error, variables, { queryClient }));
        },
        ...restCreateMutationOptions,
        ...restOptions,
      },
      queryClient,
    );

    return useMutationQuery;
  };

  useMutationQuery.getMutationKey = () => [mutationName];
  useMutationQuery.mutationName = mutationName;
  useMutationQuery.useMutationState = function useThisMutationState() {
    return useMutationState({
      filters: { mutationKey: [mutationName], exact: true },
    });
  };

  return useMutationQuery;
};

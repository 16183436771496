import {
  DecoderFunction,
  array,
  boolean,
  intersection,
  string,
} from 'typescript-json-decoder';

import { FileModel, fileDecoder } from '@/models/FileModel';
import {
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils';

import {
  TeleMonitoringCriteria,
  teleMonitoringCriteriaDecoder,
} from './TelemonitoringCriteriaModel';

export type DiabetesType = 'type1' | 'type2' | 'gestational';
export type InsulinTreatmentType = 'mono' | 'multi' | 'noInsulin';
export type TelemonitoringTier = 'base' | 'level1' | 'level2';
export type RenewalSuggestion = 'expiring' | 'add_insulin';
export type TelemonitoringTags =
  | 'active'
  | 'expiring_soon'
  | 'expired'
  | 'expired_recently'
  | 'gestational_no_insulin'
  | 'no_glycemia_data';

export type CannotRenewReason =
  | 'has_upcoming'
  | 'has_careplan_until_term'
  | 'monitored_too_long';

type DescriptionAttachmentFile = FileModel;

type PrescriptionFile = FileModel;

export type CarePlan = {
  id: string;
  periodStart: string;
  periodEnd: string;
  description?: string;
  descriptionAttachment?: DescriptionAttachmentFile;
  telemonitoringTier: TelemonitoringTier;
  telemonitoringCriteria?: TeleMonitoringCriteria;
  prescription?: PrescriptionFile;
  subject: string;
};

export type PatientRenewalDecision = 'do_not_renew' | 'decide_later';

const descriptionAttachmentDecoder: DecoderFunction<DescriptionAttachmentFile> =
  intersection(fileDecoder, recordWithContext('DescriptionAttachment', {}));

const prescriptionDecoder: DecoderFunction<PrescriptionFile> = intersection(
  fileDecoder,
  recordWithContext('PrescriptionAttachment', {}),
);

export const renewalSuggestionDecoder = nullOrUndef(
  stringUnion('expiring', 'add_insulin'),
);
export const telemonitoringTagsDecoder = array(
  stringUnion(
    'active',
    'expiring_soon',
    'expired',
    'expired_recently',
    'gestational_no_insulin',
    'no_glycemia_data',
  ),
);

export const cannotRenewReasonDecoder = nullOrUndef(
  stringUnion('has_upcoming', 'has_careplan_until_term', 'monitored_too_long'),
);

export const carePlanDecoder: DecoderFunction<CarePlan> = recordWithContext(
  'CarePlan',
  {
    id: string,
    periodStart: string,
    periodEnd: string,
    description: nullOrUndef(string),
    descriptionAttachment: nullOrUndef(descriptionAttachmentDecoder),
    telemonitoringCriteria: nullOrUndef(teleMonitoringCriteriaDecoder),
    telemonitoringTier: stringUnion('base', 'level1', 'level2'),
    prescription: nullOrUndef(prescriptionDecoder),
    subject: string,
  },
);

export type CarePlans = {
  current_care_plan?: CarePlan;
  can_renew_care_plan: boolean;
  cannot_renew_reason?: CannotRenewReason;
  renewal_suggestion?: RenewalSuggestion;
  telemonitoring_tags: TelemonitoringTags[];
  display_renew_popup: boolean;
  has_doctor_decided_not_to_renew: boolean;
};

export const carePlansDecoder: DecoderFunction<CarePlans> = recordWithContext(
  'CarePlan',
  {
    current_care_plan: nullOrUndef(carePlanDecoder),
    can_renew_care_plan: boolean,
    cannot_renew_reason: cannotRenewReasonDecoder,
    renewal_suggestion: renewalSuggestionDecoder,
    telemonitoring_tags: telemonitoringTagsDecoder,
    display_renew_popup: boolean,
    has_doctor_decided_not_to_renew: boolean,
  },
);

import { DecoderFunction, string } from 'typescript-json-decoder';

import { recordWithContext } from '@/utils/decoderUtils';

export type FileModel = {
  url: string;
  name: string;
  path: string;
};

export const fileDecoder: DecoderFunction<FileModel> = recordWithContext(
  'File',
  {
    url: string,
    name: string,
    path: string,
  },
);

export type FileToUpload = {
  file?: Blob;
  name: string;
};

import React from 'react';

import { css } from '@emotion/css';
import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { AlertConfig } from '@/models/AlertModel';
import { AlertSettingsRow } from '@/pages/patient-settings/alert-settings/AlertSettingsRow';

export type AlertSettingsTableProps = {
  patientId: string;
  alertConfigList: AlertConfig[];
};

export const AlertSettingsTable: React.FC<AlertSettingsTableProps> = ({
  patientId,
  alertConfigList,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <TableHead className={styles.tableHeader}>
          <TableRow className={styles.row}>
            <TableCell className={styles.typeColumn}>
              <Typography variant="h6">{t('alertConfig.type')}</Typography>
            </TableCell>
            <TableCell className={styles.priorityColumn}>
              <Typography variant="h6">{t('alertConfig.priority')}</Typography>
            </TableCell>
            <TableCell className={styles.conditionsColumn}>
              <Typography variant="h6">
                {t('alertConfig.conditions')}
              </Typography>
            </TableCell>
            <TableCell className={styles.actionColumn}></TableCell>
          </TableRow>
        </TableHead>
        <tbody>
          {alertConfigList.map(alertConfig => (
            <AlertSettingsRow
              key={alertConfig.id}
              patientId={patientId}
              alertConfig={alertConfig}
              className={styles.row}
              classes={{
                typeCol: styles.typeColumn,
                priorityCol: styles.priorityColumn,
                conditionsCol: styles.conditionsColumn,
                actionCol: styles.actionColumn,
              }}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const makeStyles = () => ({
  container: css``,
  table: css`
    width: 100%;
  `,
  tableHeader: css`
    width: 100%;
  `,
  row: css`
    display: flex;
    width: 100%;
  `,
  typeColumn: css`
    max-width: 200px;
    min-width: 100px;
    flex: 1 1 200px;
    display: flex;
    align-items: center;
  `,
  priorityColumn: css`
    max-width: 200px;
    min-width: 100px;
    flex: 1 1 200px;
    display: flex;
    align-items: center;
  `,
  conditionsColumn: css`
    min-width: 200px;
    flex: 3 1;
    display: flex;
    align-items: center;
  `,
  actionColumn: css`
    min-width: 50px;
    flex: 0 0 50px;
    display: flex;
    align-items: center;
  `,
});

import React, { PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';

export type TagListProps = PropsWithChildren<{
  className?: string;
}>;

export const TagList: React.FC<TagListProps> = ({ children, className }) => {
  const styles = useStyles(makeStyles);

  return <ul className={cx(styles.container, className)}>{children}</ul>;
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${theme.spacing(4)};
  `,
});

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { InformationPaper } from '../atoms/InformationPaper';

export const GestationalFormWarning = () => {
  const { t } = useTranslation();
  return (
    <InformationPaper>
      <Typography sx={{ fontWeight: 'bold' }}>
        {t('diabetesForms.nonTelemonitoredPatient')}
        {' : '}
      </Typography>
      <Typography>{t('diabetesForms.noInsulinMonitoringHelpText')}</Typography>
    </InformationPaper>
  );
};

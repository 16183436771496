import { QueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import { DiabetesRepository } from '@/io/repository/DiabetesRepository';
import {
  BulkAddGlycemia,
  EditDiabetesParameters,
} from '@/models/DiabetesDataModel';
import { makeQueryKey, stripQueryResult } from '@/queries/Queries';
import { createInfiniteQuery } from '@/queries/utils/CreateInfiniteQuery';
import { createMutation } from '@/queries/utils/CreateMutation';
import { createQuery } from '@/queries/utils/CreateQuery';
import { DateUtils } from '@/utils/date.ts';

type StatsRequest = {
  patientId: string;
  from: DateTime<true>;
  to: DateTime<true>;
};

export class DiabetesQueries {
  constructor(
    private readonly diabetes: DiabetesRepository = new DiabetesRepository(),
  ) {}

  /*******************************/
  /*********** QUERIES ***********/
  /*******************************/
  useInfiniteBGMLogbook = createInfiniteQuery(
    'infinite-bgm-logbook',
    ({ patientId, from, to }) =>
      makeQueryKey(patientId, from?.toISO(), to?.toISO()),
    async ({ patientId, from, to }: StatsRequest) =>
      stripQueryResult(await this.diabetes.getBGMLogbook(patientId, from, to)),
    (_, __, lastPageParam) => {
      const diff = DateUtils.compareDates(lastPageParam.to, lastPageParam.from);

      return {
        patientId: lastPageParam.patientId,
        from: lastPageParam.from.minus({ days: diff }).startOf('week'),
        to: lastPageParam.from.minus({ days: 1 }),
      };
    },
  );

  useDataviz = createQuery(
    'dataviz',
    ({ patientId, from, to }) =>
      makeQueryKey(patientId, from?.toISODate(), to?.toISODate()),
    async ({ patientId, from, to }: StatsRequest) =>
      stripQueryResult(await this.diabetes.getDataViz(patientId, from, to)),
    {
      staleTime: 0,
    },
  );

  useInfiniteDataviz = createInfiniteQuery(
    'infinite-dataviz',
    ({ patientId, from, to }) =>
      makeQueryKey(patientId, from?.toISODate(), to?.toISODate()),
    async ({ patientId, from, to }: StatsRequest) =>
      stripQueryResult(await this.diabetes.getDataViz(patientId, from, to)),
    /**
     * Get next time range
     * Get the difference between the last page "from" and "to" dates
     * Return a new range with the same length but starting one day before the "from" date
     */
    (_, __, lastPageParam) => {
      const from = lastPageParam.from;
      const to = lastPageParam.to;

      let intervalBetweenDates = DateUtils.compareDates(to, from);

      // If the last page is not the current day, we need to add one more day
      if (DateUtils.compareDates(to, DateTime.now()) < 0) {
        intervalBetweenDates += 1;
      }

      return {
        patientId: lastPageParam.patientId,
        from: from.minus({ days: intervalBetweenDates }),
        to: from.minus({ days: 1 }),
      };
    },
    {
      getPreviousPageParam: (_, __, firstPageParam) => {
        const from = firstPageParam.from;
        const to = firstPageParam.to;

        // No more pages if the first page reaches the present
        if (DateUtils.compareDates(to, DateTime.now()) >= 0) {
          return undefined;
        }

        const intervalBetweenDates = DateUtils.compareDates(to, from) + 1;

        let newTo = to.plus({ days: intervalBetweenDates });

        // If the new "to" date is in the future, we need to set it to today
        if (
          DateUtils.compareDates(newTo, DateTime.now().minus({ days: 1 })) >= 0
        ) {
          newTo = DateTime.now();
        }

        return {
          patientId: firstPageParam.patientId,
          from: to.plus({ days: 1 }),
          to: newTo,
        };
      },
      maxPages: 100,
    },
  );

  useParameters = createQuery(
    'parameters',
    patientId => [patientId],
    async (patientId: string) =>
      stripQueryResult(await this.diabetes.getParameters(patientId)),
  );

  useMedicalDevices = createQuery(
    'medical-devices',
    patientId => [patientId],
    async (patientId: string) =>
      stripQueryResult(await this.diabetes.getMedicalDevices(patientId)),
  );

  useTargetDurationStats = createQuery(
    'target-duration-stats',
    ({ patientId, from, to }) =>
      makeQueryKey(patientId, from?.toISODate(), to?.toISODate()),
    async ({ patientId, from, to }: StatsRequest) =>
      stripQueryResult(
        await this.diabetes.getTargetDurationStats(patientId, from, to),
      ),
  );

  useGlobalStats = createQuery(
    'global-stats',
    ({ patientId, from, to }) =>
      makeQueryKey(patientId, from?.toISODate(), to?.toISODate()),
    async ({ patientId, from, to }: StatsRequest) =>
      stripQueryResult(await this.diabetes.getGlobalStats(patientId, from, to)),
  );

  useAGPStats = createQuery(
    'agp-stats',
    ({ patientId, from, to }) =>
      makeQueryKey(patientId, from?.toISODate(), to?.toISODate()),
    async ({ patientId, from, to }: StatsRequest) =>
      stripQueryResult(await this.diabetes.getAGPStats(patientId, from, to)),
  );

  /*******************************/
  /*********** MUTATIONS *********/
  /*******************************/

  useBulkAddGlycemia = createMutation(
    'bulk-add-glycemia',
    async (data: BulkAddGlycemia) =>
      stripQueryResult(await this.diabetes.bulkAddGlycemia(data)),
  );

  useEditParameters = createMutation(
    'edit-parameters',
    async (data: EditDiabetesParameters) =>
      stripQueryResult(await this.diabetes.editParameters(data)),
    {
      onSuccess: (parameters, { patientId }, { queryClient }) => {
        this.useParameters.manualUpdate(
          queryClient,
          parameters.id,
          () => parameters,
        );
        this.invalidateAllStats(queryClient, patientId);
      },
    },
  );

  useUploadFile = createMutation(
    'upload-file',
    async ({
      patientId,
      file,
      timezone,
    }: {
      patientId: string;
      file: File;
      timezone?: string;
    }) =>
      stripQueryResult(
        await this.diabetes.uploadFile(patientId, file, timezone),
      ),
  );

  /*******************************/
  /************ HELPERS **********/
  /*******************************/

  invalidateAllStats = (queryClient: QueryClient, patientId: string) => {
    this.useDataviz.invalidate(queryClient, { patientId });
    this.useInfiniteDataviz.invalidate(queryClient, { patientId });
    this.useTargetDurationStats.invalidate(queryClient, { patientId });
    this.useGlobalStats.invalidate(queryClient, { patientId });
    this.useAGPStats.invalidate(queryClient, { patientId });
  };
}

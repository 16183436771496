import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';

import { FontSizes } from '@/assets/fonts';
import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles';
import { AlertCriticity } from '@/models/AlertModel';

export type AlertIconNotificationProps = {
  criticity: AlertCriticity;
};

export const AlertIconNotification: React.FC<AlertIconNotificationProps> = ({
  criticity,
}) => {
  const styles = useStyles(makeStyles);

  switch (criticity) {
    case 'low':
      return (
        <Icons.alertCircle className={cx(styles.icon, styles[criticity])} />
      );
    case 'medium':
      return (
        <Icons.alertTriangle className={cx(styles.icon, styles[criticity])} />
      );
    case 'high':
      return (
        <Icons.alertTriangle className={cx(styles.icon, styles[criticity])} />
      );
  }
};

const makeStyles = (theme: Theme) => ({
  icon: css`
    width: ${FontSizes.bodyLarge};
    height: ${FontSizes.bodyLarge};
  `,

  low: css`
    fill: ${theme.palette.warning.main};
  `,
  medium: css`
    fill: ${theme.palette.warning.main};
  `,
  high: css`
    fill: ${theme.palette.error.main};
  `,
});

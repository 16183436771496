import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { Col } from '@/components/layout/Flex';
import { Loader } from '@/components/loading/Loader';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useStyles';
import { TelemonitoringCard } from '@/pages/patient-settings/TelemonitoringCard';
import { AlertSettings } from '@/pages/patient-settings/alert-settings/AlertSettings';
import { Queries } from '@/queries/Queries';

import { AllThresholdsSettings } from './AllThresholdsSettings';
import { GestationalThresholdSettings } from './GestationalThresholdSettings';

export default function PatientSettings() {
  const styles = useStyles(makeStyles);
  const patientId = usePatientIdFromURL();
  const patientQuery = Queries.practitioner.usePatient(patientId ?? '');
  const conditionsQuery = Queries.condition.useCondition(patientId ?? '');
  const diabetesParametersQuery = Queries.diabetes.useParameters(
    patientId ?? '',
  );

  if (
    !patientQuery.data ||
    !conditionsQuery.data ||
    !diabetesParametersQuery.data
  ) {
    return (
      <Col align="center" justify="center">
        <Loader size="L" />
      </Col>
    );
  }

  const patient = patientQuery.data;
  const condition = conditionsQuery.data;
  const diabetesParameters = diabetesParametersQuery.data;

  return (
    <div className={styles.container}>
      <TelemonitoringCard patientId={patient.id} />
      {condition.type === 'gestational' && <GestationalThresholdSettings />}
      <div className={styles.content}>
        <AllThresholdsSettings
          condition={condition}
          patient={patient}
          diabetesParameters={diabetesParameters}
        />
        <AlertSettings />
      </div>
    </div>
  );
}

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${theme.spacing(12)};
  `,
  content: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${theme.spacing(8)};
  `,
});

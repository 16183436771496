import React, { useState } from 'react';

import { css } from '@emotion/css';
import { Button, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { Loader } from '@/components/loading/Loader';
import { useStyles } from '@/hooks/useStyles';
import { PageContainer } from '@/pages/PageContainer';
import { HealthProLayout } from '@/pages/layout/HealthProLayout';
import { PatientsSearch } from '@/pages/patients/PatientsSearch';
import { Queries } from '@/queries/Queries';

import { PatientTable } from './patientTable/PatientTable';
import { CreatePatient } from './telemonitoringForms/CreatePatient';

export const PatientsPage: React.FC = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const [openCreatePatient, setOpenCreatePatient] = useState<boolean>(false);

  const practitioner = Queries.practitioner.usePractitioner({});
  const styles = useStyles(makeStyles);

  return (
    <HealthProLayout>
      <PageContainer
        title={t('pages.patients.title')}
        className={styles.content}
      >
        <Card elevation={0}>
          <CardBody className={styles.body}>
            {practitioner.data ? (
              <>
                <PatientsSearch search={search} setSearch={setSearch} />
                <PatientTable
                  practitioner={practitioner.data}
                  search={search}
                />
                {practitioner.data.qualification === 'doctor' ? (
                  <Button
                    className={styles.container}
                    variant="contained"
                    startIcon={<Icons.userPlus />}
                    onClick={() => setOpenCreatePatient(true)}
                  >
                    {t('pages.patients.addPatient')}
                  </Button>
                ) : null}
              </>
            ) : (
              <Loader size="L" />
            )}
          </CardBody>
        </Card>
        {openCreatePatient ? (
          <CreatePatient close={() => setOpenCreatePatient(false)} />
        ) : null}
      </PageContainer>
    </HealthProLayout>
  );
};

const makeStyles = (theme: Theme) => ({
  content: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(12)};
  `,
  body: css`
    padding: ${theme.spacing(8, 0)};
    gap: ${theme.spacing(12)};
  `,
  padding: css`
    padding-right: ${theme.spacing(2)};
  `,
  container: css`
    width: fit-content;
    margin: 0 ${theme.spacing(12)};
    position: sticky;
    bottom: 1px;
    align-self: flex-end;
  `,
});

import { Controller, useFormContext } from 'react-hook-form';

import { UploadButton } from '@/components/button/UploadButton';
import { FileModel } from '@/models/FileModel';
import { Patient } from '@/models/PatientModel';
import { UploadedFileType } from '@/models/UploadFileModel';

type ControlledFileUploadProps = {
  name: string;
  fileType: UploadedFileType;
  patientInfo: Pick<Patient, 'givenName' | 'familyName'>;
  defaultValue?: FileModel;
  hasTrash?: boolean;
  displayFilename?: boolean;
};

export const ControlledFileUpload = ({
  name,
  fileType,
  patientInfo,
  hasTrash = false,
  defaultValue,
  displayFilename,
}: ControlledFileUploadProps) => {
  const { control, setValue } = useFormContext();
  const onFileChange = (file?: FileModel) => {
    setValue(name, file);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <div>
          <UploadButton
            onFileChange={onFileChange}
            fileType={fileType}
            patientInfo={patientInfo}
            defaultValue={defaultValue}
            hasTrash={hasTrash}
            displayFilename={displayFilename}
          />
        </div>
      )}
    />
  );
};

import { css } from '@emotion/css';

import { ShowApiError } from '@/components/error/ShowApiError';
import { Loader } from '@/components/loading/Loader';
import { useStyles } from '@/hooks/useStyles';
import { Practitioner } from '@/models/PractitionerModel';
import { Queries } from '@/queries/Queries';

import { PatientTableData } from './PatientTableData';

export type PatientsTableProps = {
  search: string;
  practitioner: Practitioner;
};

export const PatientTable: React.FC<PatientsTableProps> = ({
  search,
  practitioner,
}) => {
  const styles = useStyles(makeStyles);
  const patients = Queries.practitioner.usePatientsList({});
  const isNurse = practitioner.qualification === 'nurse';

  switch (patients.status) {
    case 'success':
      return (
        <PatientTableData
          isNurse={isNurse}
          search={search}
          patients={patients.data}
        />
      );
    case 'error':
      return <ShowApiError errorMapping={{}} error={patients.error.error} />;
    case 'pending':
      return (
        <div className={styles.loader}>
          <Loader size="L" />
        </div>
      );
  }
};

const makeStyles = () => ({
  loader: css`
    width: 100%;
    aspect-ratio: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
});

import React, { PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';

import { Col } from '@/components/layout/Flex';
import { useStyles } from '@/hooks/useStyles';

/**
 * @deprecated Use MUI Card instead
 */
export type CardBodyProps = PropsWithChildren<{
  className?: string;
  htmlRef?: React.ForwardedRef<HTMLDivElement>;
}>;

/**
 * @deprecated Use MUI Card instead
 */
export const CardBody: React.FC<CardBodyProps> = ({
  children,
  className,
  htmlRef,
}) => {
  const styles = useStyles(makeStyles);

  return (
    <Col htmlRef={htmlRef} className={cx(styles.container, className)}>
      {children}
    </Col>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    padding: ${theme.spacing(4, 12, 12, 12)};
    width: 100%;
    color: white;
    word-wrap: break-word;
  `,
});

import React, { FormHTMLAttributes } from 'react';

import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form';

export type FormProps<T extends FieldValues> = {
  id?: string;
  className?: string;
  onSubmit: SubmitHandler<T>;
  onError?: SubmitErrorHandler<T>;
  children: React.ReactNode;
  formProps?: Omit<
    FormHTMLAttributes<HTMLFormElement>,
    'children' | 'id' | 'className' | 'onSubmit'
  >;
  formContext: UseFormReturn<T>;
};

export const Form = <T extends FieldValues>({
  id,
  className,
  onSubmit,
  onError,
  children,
  formProps,
  formContext,
}: FormProps<T>): React.ReactElement => {
  return (
    <FormProvider {...formContext}>
      <form
        id={id}
        className={className}
        target="_blank"
        onSubmit={event => formContext.handleSubmit(onSubmit, onError)(event)}
        {...formProps}
      >
        {children}
      </form>
    </FormProvider>
  );
};

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { useHover } from '@uidotdev/usehooks';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { FontSizes } from '@/assets/fonts';
import { Icons } from '@/assets/icons';
import { IconButton } from '@/components/button/IconButton';
import { CardBody } from '@/components/card/CardBody';
import { CardTitle } from '@/components/card/CardTitle';
import { BaseTooltip } from '@/components/floating/BaseTooltip';
import { useStyles } from '@/hooks/useStyles';
import { Message } from '@/models/ChatModel';
import { Queries } from '@/queries/Queries';
import { toDateTime } from '@/utils/date.ts';
import { userFormattedName } from '@/utils/user.ts';

type MessageContentType = {
  message: Message;
};

export const MessageContent = ({ message }: MessageContentType) => {
  const currentUser = Queries.practitioner.usePractitioner({});
  const styles = useStyles(makeStyle);
  const { t } = useTranslation();
  const [ref, hovering] = useHover();
  const isSender = message.sender.id === currentUser.data?.id;
  const updateMessage = Queries.chat.useUpdateMessage();

  const isForStaffOnly = message.visibility === 'practitioners';

  const timeSent = toDateTime(message.createdAt).toLocaleString(
    DateTime.TIME_SIMPLE,
  );

  const fileLabel = (fileName: string) => {
    if (fileName.length > 35) {
      return `${fileName.slice(0, 35)}...`;
    }
    return fileName;
  };

  const deleteHandler = () => {
    updateMessage.mutate({
      patientId: message.subject,
      messageId: message.id,
      status: 'stopped',
    });
  };

  const senderName = () => {
    if (message.sender.userType === 'admin') {
      return 'admin';
    } else if (isSender) {
      return t('messaging.me');
    }
    return userFormattedName(
      message.sender.givenName,
      message.sender.familyName,
    );
  };
  return (
    <div className={styles.container} ref={ref}>
      {isSender && (
        <div className={styles.delete}>
          {hovering && <Icons.trash onClick={deleteHandler}></Icons.trash>}
        </div>
      )}

      <CardBody
        className={cx(
          isForStaffOnly ? styles.staffContent : '',
          isSender ? styles.contentSent : styles.contentReceived,
        )}
      >
        <CardTitle className={styles.header}>
          {senderName()}
          <div className={styles.time}>{timeSent}</div>
        </CardTitle>

        {message.contentText && (
          <Typography variant="body" className={styles.contentText}>
            {message.contentText}
          </Typography>
        )}
        {message.attachment?.map((attachment, i) => (
          <BaseTooltip
            type="hover"
            placement="top"
            content={attachment.name}
            role="tooltip"
            key={i}
          >
            <IconButton
              placement="left"
              buttonType="link"
              icon={Icons.download}
              textType="body"
              iconClassname={styles.downloadIcon}
              onClick={() => window.open(attachment.url, '_blank')}
              className={styles.downloadSection}
            >
              {fileLabel(attachment.name)}
            </IconButton>
          </BaseTooltip>
        ))}
      </CardBody>
    </div>
  );
};

const makeStyle = (theme: Theme) => ({
  staffContent: css`
    background: ${theme.palette.green.contrastText};
  `,
  container: css`
    display: flex;
    row-gap: 10px;
    white-space: pre-line;
  `,
  delete: css`
    margin-left: auto;
    margin-right: ${theme.spacing(4)};
    align-self: center;
    cursor: pointer;
  `,
  contentReceived: css`
    border-radius: ${theme.spacing(0, 4, 4, 4)};
    color: ${theme.palette.text.primary};
    display: flex;
    width: 85%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    padding-right: ${theme.spacing(4)};
    padding: ${theme.spacing(4)};
  `,
  contentSent: css`
    border-radius: ${theme.spacing(4, 4, 0, 4)};
    color: ${theme.palette.text.primary};
    width: 85%;
    gap: ${theme.spacing(4)};
    padding: ${theme.spacing(4)};
  `,
  time: css`
    color: ${theme.palette.text.secondary};
    /* caption/xs */
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.2px;
    align-self: center;
  `,
  header: css`
    padding: ${theme.spacing(1)};
    color: ${theme.palette.text.primary};
    font-variant-numeric: lining-nums tabular-nums;
    /* body/sm-bold */
    font-size: ${FontSizes.body};
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
    border-bottom: transparent;
  `,
  downloadIcon: css`
    border-bottom: rgba(0, 0, 0, 0.5);
  `,
  downloadSection: css`
    padding: 0;
  `,
  contentText: css`
    max-width: 100%;
  `,
});

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@/components/floating/Tooltip';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useStyles';

export const HeaderWithTooltip = ({
  tootlipContent,
  titleLocaleString,
}: {
  tootlipContent: string;
  titleLocaleString: string;
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  const headerTypoType = 'paragraph';
  return (
    <Tooltip
      placement="top"
      content={t(`${tootlipContent}`)}
      hover={{ handleClose: null }}
    >
      <Typo type={headerTypoType} className={styles.header}>
        {t(`${titleLocaleString}`)}
      </Typo>
    </Tooltip>
  );
};

const makeStyles = () => ({
  header: css`
    color: #5029be;
    text-align: center;
  `,
});

import { createContext } from 'react';

import { ChatFilterParametersType, Message } from '@/models/ChatModel';

type ChatStatusType = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>> | undefined;
};

export const ChatStatusContext = createContext<ChatStatusType>({
  open: false,
  setOpen: undefined,
});

type ChatFilterType = {
  filter: ChatFilterParametersType;
  setFilter:
    | React.Dispatch<React.SetStateAction<ChatFilterParametersType>>
    | undefined;
};

export const ChatFilterContext = createContext<ChatFilterType>({
  filter: 'all',
  setFilter: undefined,
});

type MessageByStateContextType = {
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>> | undefined;
};

export const MessagesByStateContext = createContext<MessageByStateContextType>({
  messages: [],
  setMessages: undefined,
});

import { MailPreferenceRepository } from '@/io/repository/MailPreferenceRepository';
import { MailPreferenceUpdate } from '@/models/MailPreferenceModel';
import { stripQueryResult } from '@/queries/Queries';
import { createMutation } from '@/queries/utils/CreateMutation';
import { createQuery } from '@/queries/utils/CreateQuery';

export class MailPreferenceQueries {
  constructor(
    private readonly mailPreference: MailPreferenceRepository = new MailPreferenceRepository(),
  ) {}

  /*******************************/
  /*********** QUERIES ***********/
  /*******************************/

  useMailPreference = createQuery(
    'mail-preference',
    ({}) => [],
    async () => stripQueryResult(await this.mailPreference.getPreferences()),
  );
  /*******************************/
  /********** MUTATIONS **********/
  /*******************************/

  useUpdatePreference = createMutation(
    'update-mail-preference',
    async (ids: MailPreferenceUpdate[]) =>
      stripQueryResult(await this.mailPreference.updatePreferences(ids)),
    {
      onSuccess: (_, __, { queryClient }) => {
        this.useMailPreference.invalidate(queryClient, {});
      },
    },
  );
}

import React, { useState } from 'react';

import { ChatContent } from '@/components/chat/ChatContent';
import { ChatFilterContext } from '@/components/chat/ChatContext';
import { SidePeek } from '@/components/side-peek/SidePeek';
import { ChatFilterParametersType } from '@/models/ChatModel';

export const ChatContainer: React.FC = () => {
  const [filter, setFilter] = useState<ChatFilterParametersType>('all');
  return (
    <SidePeek>
      <ChatFilterContext.Provider value={{ filter, setFilter }}>
        <ChatContent />
      </ChatFilterContext.Provider>
    </SidePeek>
  );
};

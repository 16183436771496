import { record, string } from 'typescript-json-decoder';

export type ApiErrorResponse = {
  statusCode: number;
  error: string;
  data?: unknown;
};

export const apiErrorResponseDecoder =
  (statusCode: number) => (value: unknown) => {
    const decoded = record({
      error: string,
      data: (dataValue: unknown) => dataValue,
    })(value);
    return {
      statusCode,
      error: decoded.error,
      data: decoded.data,
    };
  };

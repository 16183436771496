import { ObservationsRepository } from '@/io/repository/ObservationsRepository';
import {
  CreateNurseNotice,
  DeleteNurseNotice,
} from '@/models/NurseNoticeModel';
import { PractitionerQueries } from '@/queries/PractitionerQueries';
import { stripQueryResult } from '@/queries/Queries';
import { createMutation } from '@/queries/utils/CreateMutation';

export class ObservationsQueries {
  constructor(
    private readonly practitioners: PractitionerQueries = new PractitionerQueries(),
    private readonly observations: ObservationsRepository = new ObservationsRepository(),
  ) {}

  /*******************************/
  /********** MUTATIONS **********/
  /*******************************/

  useCreateNotice = createMutation(
    'create-notice',
    async (data: CreateNurseNotice) =>
      stripQueryResult(await this.observations.createNotice(data)),
    {
      onSuccess: (notice, _, { queryClient }) => {
        this.practitioners.usePatientsList.invalidate(queryClient, {});
        this.practitioners.useNurseNotice.invalidate(
          queryClient,
          notice.subjectId,
        );
      },
    },
  );

  useDeleteNotice = createMutation(
    'delete-notice',
    async (data: DeleteNurseNotice) =>
      stripQueryResult(await this.observations.deleteNotice(data)),
    {
      onSuccess: (_, requestData, { queryClient }) => {
        this.practitioners.usePatientsList.invalidate(queryClient, {});
        this.practitioners.useNurseNotice.invalidate(
          queryClient,
          requestData.patientId,
        );
      },
    },
  );

  useMitigateNotice = createMutation(
    'mitigate-notice',
    async (data: DeleteNurseNotice) =>
      stripQueryResult(await this.observations.mitigateNotice(data)),
    {
      onSuccess: (notice, _, { queryClient }) => {
        this.practitioners.usePatientsList.invalidate(queryClient, {});
        this.practitioners.useNurseNotice.invalidate(
          queryClient,
          notice.subjectId,
        );
      },
    },
  );
}

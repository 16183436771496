import { css } from '@emotion/css';
import { Button, Theme, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';

import { Form } from '@/components/form/Form';
import { useStyles } from '@/hooks/useStyles';
import { PatientInfoData } from '@/models/CreatePatientModel';
import { ControlledTextField } from '@/uiKit/molecules/ControlledTextField';

type PatientInformationProps = {
  close: () => void;
  onSubmit: (data: PatientInfoData) => void;
  patientInfo?: PatientInfoData;
};
export const PatientInformation = ({
  close,
  onSubmit,
  patientInfo,
}: PatientInformationProps) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  const context = useForm<PatientInfoData>(
    patientInfo
      ? {
          defaultValues: {
            givenName: patientInfo.givenName || '',
            familyName: patientInfo.familyName || '',
            email: patientInfo.email || '',
            phone: patientInfo.phone || '',
          },
        }
      : undefined,
  );

  return (
    <div className={styles.marginTop}>
      <Typography variant="subtitle">
        {t('pages.patientMonitoring.patient.title')}
      </Typography>
      <Form
        onSubmit={onSubmit}
        id="PatientInformationForm"
        formContext={context}
      >
        <div className={styles.formRow}>
          <div className={styles.aside}>
            <ControlledTextField
              required
              name="givenName"
              label={t('profile.firstname')}
              variant="standard"
            />
            <ControlledTextField
              required
              name="familyName"
              label={t('profile.lastname')}
              variant="standard"
            />
          </div>
        </div>
        <div className={styles.formRow}>
          <ControlledTextField
            required
            name="email"
            rules={{
              required: t('form.email.required'),
              validate: (value: string) =>
                isEmail(value) ? null : t('form.email.invalid'),
            }}
            label={t('form.email.label')}
            variant="standard"
          />
        </div>
        <div className={styles.formRow}>
          <ControlledTextField
            required
            name="phone"
            rules={{
              required: t('form.phone.required'),
              validate: (value: string) =>
                !isMobilePhone(value) ? t('form.phone.invalid') : null,
            }}
            label={t('form.phone.label')}
            variant="standard"
          />
        </div>
        <div className={styles.footer}>
          <Button onClick={close}>{t('button.cancel')}</Button>
          <Button type="submit" variant="contained" color="primary">
            {t('button.next')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  formRow: css`
    display: flex;
    padding-bottom: ${theme.spacing(10)};
    width: 100%;
  `,
  footer: css`
    display: flex;
    justify-content: right;
    padding-top: ${theme.spacing(10)};
    padding-bottom: ${theme.spacing(10)};
    gap: ${theme.spacing(20)};
    width: 100%;
  `,
  aside: css`
    justify-content: space-between;
    display: flex;
    gap: ${theme.spacing(20)};
    width: 100%;
  `,
  marginTop: css`
    margin-top: ${theme.spacing(20)};
  `,
});

import React from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  AlertConfig,
  HyperglycemiaAlertParameters,
  HyperglycemiaTarAlertParameters,
  HypoglycemiaAlertParameters,
  HypoglycemiaTbrAlertParameters,
  MeasurementAlertParameters,
  TransmissionAlertParameters,
} from '@/models/AlertModel.ts';
import { AlertHyperglycemiaSettings } from '@/pages/patient-settings/alert-settings/conditions/AlertHyperglycemiaSettings.tsx';
import { AlertHyperglycemiaTarSettings } from '@/pages/patient-settings/alert-settings/conditions/AlertHyperglycemiaTarSettings.tsx';
import { AlertHypoglycemiaSettings } from '@/pages/patient-settings/alert-settings/conditions/AlertHypoglycemiaSettings';
import { AlertHypoglycemiaTbrSettings } from '@/pages/patient-settings/alert-settings/conditions/AlertHypoglycemiaTbrSettings.tsx';
import { AlertMeasurementSettings } from '@/pages/patient-settings/alert-settings/conditions/AlertMeasurementSettings.tsx';
import { AlertTransmissionSettings } from '@/pages/patient-settings/alert-settings/conditions/AlertTransmissionSettings.tsx';

export type AlertSettingsConditionsProps = {
  patientId: string;
  alertConfig: AlertConfig;
};

export const AlertSettingsConditions: React.FC<
  AlertSettingsConditionsProps
> = ({ alertConfig, patientId }) => {
  const { t } = useTranslation();

  switch (alertConfig.category) {
    case 'hypoglycemia':
      return (
        <AlertHypoglycemiaSettings
          patientId={patientId}
          alertConfig={alertConfig}
          parameters={alertConfig.parameters as HypoglycemiaAlertParameters}
        />
      );
    case 'hyperglycemia':
      return (
        <AlertHyperglycemiaSettings
          patientId={patientId}
          alertConfig={alertConfig}
          parameters={alertConfig.parameters as HyperglycemiaAlertParameters}
        />
      );
    case 'hyperglycemia_tar':
      return (
        <AlertHyperglycemiaTarSettings
          patientId={patientId}
          alertConfig={alertConfig}
          parameters={alertConfig.parameters as HyperglycemiaTarAlertParameters}
        />
      );
    case 'hypoglycemia_tbr':
      return (
        <AlertHypoglycemiaTbrSettings
          patientId={patientId}
          alertConfig={alertConfig}
          parameters={alertConfig.parameters as HypoglycemiaTbrAlertParameters}
        />
      );
    case 'measurement':
      return (
        <AlertMeasurementSettings
          patientId={patientId}
          alertConfig={alertConfig}
          parameters={alertConfig.parameters as MeasurementAlertParameters}
        />
      );
    case 'transmission':
      return (
        <AlertTransmissionSettings
          patientId={patientId}
          alertConfig={alertConfig}
          parameters={alertConfig.parameters as TransmissionAlertParameters}
        />
      );
    default:
      return (
        <Typography variant="body">
          {t('alertConfig.notificationTriggeredAsSoonAsEventIsNoted')}
        </Typography>
      );
  }
};

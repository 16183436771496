import { PostHogProvider } from 'posthog-js/react';

import { useKeycloak } from '@/keycloak';

export const TrackingWrapper = ({ children }: React.PropsWithChildren) => {
  const keycloak = useKeycloak();
  const enableTracking = !keycloak.impersonating;

  return (
    <PostHogProvider
      apiKey={'phc_GPFpM2rVdF5tPT0OfqjbD1pGHalzyGZbMaBZwQ2VYj3'}
      options={{
        api_host: 'https://eu.i.posthog.com',
        autocapture: enableTracking,
        enable_heatmaps: enableTracking,
        disable_session_recording: !enableTracking,
        capture_pageview: false,
      }}
    >
      {children}
    </PostHogProvider>
  );
};

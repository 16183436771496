import React from 'react';

import { css, cx } from '@emotion/css';
import { InputProps, Theme, Typography } from '@mui/material';

import { useStyles } from '@/hooks/useStyles.ts';
import {
  DiabetesParameters,
  DiabetesParametersType,
} from '@/models/DiabetesDataModel.ts';
import { ThresholdStep } from '@/uiKit/molecules/thresholds/ThresholdStep.tsx';

import {
  ThresholdInputMapping,
  ThresholdStepList,
  allThresholdInputMapping,
  allThresholds,
  gestationalThresholdInputMapping,
  gestationalThresholds,
} from './ThresholdStepList';

export type ThresholdType = 'all' | 'gestational';

export type ThresholdSettingsProps = {
  title?: string;
  type: ThresholdType;
  inputProps?: (threshold: ThresholdStep) => InputProps;
  diabetesParameters: DiabetesParameters;
  onSubmit: (threshold: DiabetesParametersType, value: number) => void;
  className?: string;
};

export const ThresholdSettings: React.FC<ThresholdSettingsProps> = ({
  title,
  type,
  diabetesParameters,
  onSubmit,
  inputProps,
  className,
}) => {
  const styles = useStyles(makeStyles);

  return (
    <div className={cx(styles.container, className)}>
      {title ? (
        <Typography variant="subtitle" className={styles.title}>
          {title}
        </Typography>
      ) : undefined}
      <div className={styles.content}>
        <ThresholdStepList
          diabetesParameters={diabetesParameters}
          {...thresholdsInfo(type)}
          inputProps={inputProps}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
};

type ThresholdInfo = {
  thresholds: ThresholdStep[];
  thresholdInputMapping: ThresholdInputMapping;
};

const thresholdsInfo = (type: ThresholdType): ThresholdInfo => {
  switch (type) {
    case 'all':
      return {
        thresholds: allThresholds,
        thresholdInputMapping: allThresholdInputMapping,
      };
    case 'gestational':
      return {
        thresholds: gestationalThresholds,
        thresholdInputMapping: gestationalThresholdInputMapping,
      };
  }
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
  `,
  content: css`
    display: flex;
  `,
  title: css`
    padding-bottom: ${theme.spacing(4)};
  `,
});

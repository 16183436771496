import React, { PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';

import { Col } from '@/components/layout/Flex';
import { useStyles } from '@/hooks/useStyles';

/**
 * @deprecated Use MUI Card instead
 */
export type CardProps = PropsWithChildren<{
  className?: string;
  elevation: 0 | 2;
}>;

/**
 * @deprecated Use MUI Card instead
 */
export const Card: React.FC<CardProps> = ({
  children,
  className,
  elevation,
}) => {
  const styles = useStyles(makeStyles);

  return (
    <Col
      Component="section"
      className={cx(
        styles.container,
        styles[`elevation${elevation}`],
        className,
      )}
    >
      {children}
    </Col>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    background-color: ${theme.palette.common.white};
    border-radius: ${theme.spacing(2)};
  `,
  elevation0: css``,
  elevation2: css`
    box-shadow: 3px 3px 5px lightgrey;
  `,
});

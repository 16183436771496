import React, { useEffect } from 'react';

import { css } from '@emotion/css';
import { MenuItem, Select, Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';

export type SelectTypeProps<T extends number | string> = {
  onSelectChange: (value: T) => void;
  options: {
    value: T;
    text: string;
  }[];
  selected: T;
};

export const SelectType = <T extends number | string>({
  onSelectChange,
  options,
  selected,
}: SelectTypeProps<T>): React.ReactElement => {
  const styles = useStyles(makeStyles);
  const [localSelected, setLocalSelected] = React.useState<T>(selected);

  useEffect(() => {
    setLocalSelected(selected);
  }, [selected]);

  useEffect(() => {
    if (localSelected !== selected) {
      onSelectChange(localSelected);
    }
  }, [localSelected, onSelectChange]);

  return (
    <div className={styles.container}>
      <Select
        variant="standard"
        value={localSelected}
        onChange={e => setLocalSelected(e.target.value as T)}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.spacing(4)};
    .MuiInputBase-input {
      padding: ${theme.spacing(1.5, 2)};
      margin: 0;
      width: ${theme.spacing(96)};
    }
  `,
});

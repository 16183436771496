import React, { useMemo } from 'react';

import { css } from '@emotion/css';
import { Table, TableContainer, Theme } from '@mui/material';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { CardBody } from '@/components/card/CardBody';
import { Loader } from '@/components/loading/Loader';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useStyles';
import { BGMData } from '@/models/BGMLogbook';
import { BackToTop } from '@/uiKit/molecules/BackToTop.tsx';

import { BGMTableBody } from './TableBody/BGMTableBody';
import { PrettyComment } from './TableBody/Comments/PrettyComment';
import { GlycemiaBadges } from './TableBody/GlycemiaBadges';
import { Insulin } from './TableBody/Insulin';
import { PrettyDate } from './TableBody/PrettyDate';
import { BGMTableHeader } from './TableHeader/BGMTableHeader';
import { HeaderWithTooltip } from './TableHeader/HeaderWithTooltip';

const columnHelper = createColumnHelper<BGMData>();
const duplicateHeader = (
  groupHeader: 'breakfast' | 'lunch' | 'dinner',
  className: string,
  t: TFunction<'translation', undefined>,
) => {
  const headerTypoType = 'paragraph';

  return columnHelper.group({
    id: groupHeader,
    header: () => (
      <Typo type={headerTypoType} className={className}>
        {t(`bgmLogBook.${groupHeader}`)}
      </Typo>
    ),
    columns: [
      columnHelper.accessor(`${groupHeader}.bloodSugarBefore`, {
        header: () => (
          <HeaderWithTooltip
            tootlipContent="bgmLogBook.glycemiaBefore"
            titleLocaleString="bgmLogBook.glycemiaBeforeHeader"
          />
        ),
        cell: info => (
          <GlycemiaBadges
            data={info.getValue()}
            meal={groupHeader}
            timing="before"
          />
        ),
        size: 50,
      }),
      columnHelper.accessor(`${groupHeader}.fastInsulin`, {
        header: () => (
          <HeaderWithTooltip
            tootlipContent="bgmLogBook.fastInsulin"
            titleLocaleString="bgmLogBook.fastInsulinHeader"
          />
        ),
        cell: info => <Insulin data={info.getValue()} />,
        size: 50,
      }),
      columnHelper.accessor(`${groupHeader}.bloodSugarAfter`, {
        header: () => (
          <HeaderWithTooltip
            tootlipContent="bgmLogBook.glycemiaAfter"
            titleLocaleString="bgmLogBook.glycemiaAfterHeader"
          />
        ),
        cell: info => (
          <GlycemiaBadges
            data={info.getValue()}
            meal={groupHeader}
            timing="after"
          />
        ),
        size: 50,
      }),
      columnHelper.accessor(`${groupHeader}.slowInsulin`, {
        header: () => (
          <HeaderWithTooltip
            tootlipContent="bgmLogBook.slowInsulin"
            titleLocaleString="bgmLogBook.slowInsulinHeader"
          />
        ),
        cell: info => <Insulin data={info.getValue()} />,
        size: 50,
      }),
    ],
  });
};

type BGMContainerBodyProps = {
  data: BGMData[];
};

export const BGMContainerBody: React.FC<BGMContainerBodyProps> = ({ data }) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const headerTypoType = 'paragraph';

  const columns = useMemo(
    () => [
      columnHelper.accessor('date', {
        header: () => (
          <Typo type={headerTypoType} className={styles.headerItem}>
            {t('bgmLogBook.date')}
          </Typo>
        ),
        cell: info => <PrettyDate date={info.getValue()} />,
        size: 90,
      }),
      duplicateHeader('breakfast', styles.headerItem, t),
      duplicateHeader('lunch', styles.headerItem, t),
      duplicateHeader('dinner', styles.headerItem, t),
      columnHelper.group({
        id: 'night',
        header: () => (
          <Typo type={headerTypoType} className={styles.headerItem}>
            {t('bgmLogBook.nightTime')}
          </Typo>
        ),
        columns: [
          columnHelper.accessor('night.bloodSugar', {
            header: () => (
              <Typo type={headerTypoType} className={styles.headerItem}>
                {t('bgmLogBook.glycemia')}
              </Typo>
            ),
            cell: info => (
              <GlycemiaBadges
                data={info.getValue()}
                meal="lunch"
                timing="after"
              />
            ),
            size: 100,
          }),
          columnHelper.accessor('night.slowInsulin', {
            header: () => (
              <HeaderWithTooltip
                tootlipContent="bgmLogBook.slowInsulin"
                titleLocaleString="bgmLogBook.slowInsulinHeader"
              />
            ),
            cell: info => <Insulin data={info.getValue()} />,
            size: 50,
          }),
        ],
      }),
      columnHelper.accessor('comments', {
        header: () => (
          <Typo type={headerTypoType} className={styles.headerItem}>
            {t('bgmLogBook.comment')}
          </Typo>
        ),
        cell: info => <PrettyComment comment={info.getValue()} />,
        size: 300,
      }),
    ],
    [styles.headerItem, t],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <CardBody>
      <BackToTop />
      <TableContainer className={styles.container}>
        <Table
          aria-label="a dense table"
          className={styles.table}
          size="small"
          stickyHeader
        >
          <BGMTableHeader table={table} className={styles.header} />
          <BGMTableBody table={table} />
        </Table>
      </TableContainer>
      <Loader size="L" className={styles.loader} />
    </CardBody>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    border-radius: ${theme.spacing(6)};
    overflow: clip;
  `,
  scrollBar: css`
    height: 40vh;
  `,
  table: css`
    width: 100%;
    overflow: clip;
  `,
  header: css`
    top: 0;
    position: sticky;
  `,
  headerItem: css`
    color: ${theme.palette.violet.main};
  `,
  loader: css`
    align-self: center;
    margin: ${theme.spacing(4, 0)};
  `,
});

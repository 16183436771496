import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';

import { IconType } from '@/assets/icons.ts';
import { useStyles } from '@/hooks/useStyles';
import { ThemeColor } from '@/theme/types.ts';

export type CircledIconProps = {
  className?: string;
  color?: ThemeColor;
  backgroundColor?: ThemeColor;
  Icon: IconType;
  classes?: {
    container?: string;
    icon?: string;
  };
};

export const CircledIcon: React.FC<CircledIconProps> = ({
  color,
  backgroundColor,
  Icon,
  className,
  classes,
}) => {
  const styles = useStyles(makeStyles, color, backgroundColor);

  return (
    <div className={cx(styles.container, className, classes?.container)}>
      <Icon className={cx(styles.icon, classes?.icon)} />
    </div>
  );
};

const makeStyles = (
  theme: Theme,
  color?: ThemeColor,
  backgroundColor?: ThemeColor,
) => ({
  container: css`
    padding: ${theme.spacing(4)};
    border-radius: 50%;
    background-color: ${backgroundColor};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  icon: css`
    color: ${color};
  `,
});

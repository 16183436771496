import { DecoderFunction, boolean, number } from 'typescript-json-decoder';

import { recordWithContext, stringUnion } from '@/utils/decoderUtils';
import { snakeToCamel } from '@/utils/strings';

export type MailLabelType =
  | 'nursePing'
  | 'nurseToDoctor'
  | 'patientToNurse'
  | 'doctorToNurse'
  | 'dgNoInsulinPatientToDoctor';

export type MailPreference = {
  id: number;
  enabled: boolean;
  label: MailLabelType;
};

export const mailPreferenceDecoder: DecoderFunction<MailPreference> =
  recordWithContext('MailPreference', {
    id: number,
    enabled: boolean,
    label: (value: unknown) => {
      const result = stringUnion(
        'nurse_ping',
        'nurse_to_doctor',
        'patient_to_nurse',
        'doctor_to_nurse',
        'dg_no_insulin_patient_to_doctor',
      )(value);
      return snakeToCamel(result);
    },
  });

export type MailPreferenceUpdate = {
  id: number;
};

export const mailPreferenceUpdaterDecoder: DecoderFunction<MailPreferenceUpdate> =
  recordWithContext('MailPreferenceUpdate', {
    id: number,
  });

import { useMemo } from 'react';

import { PatientList } from '@/models/PatientModel';

import { PatientDoctorTable } from './PatientDoctorTable';
import { PatientNurseTable } from './PatientNurseTable';

export type PatientDataProps = {
  search: string;
  isNurse: boolean;
  patients: PatientList[];
};

export const PatientTableData = ({
  isNurse,
  search,
  patients,
}: PatientDataProps) => {
  const filteredPatients = useMemo(
    () =>
      // TODO Better search
      search && patients
        ? patients.filter(patient =>
            [
              patient.familyName?.toLowerCase(),
              patient.givenName?.toLowerCase(),
              patient.familyName?.toLowerCase(),
            ]
              .join(' ')
              .includes(search.toLowerCase()),
          )
        : patients,
    [patients, search],
  );
  return isNurse ? (
    <PatientNurseTable patients={filteredPatients} />
  ) : (
    <PatientDoctorTable patients={filteredPatients} />
  );
};

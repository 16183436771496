import React from 'react';

import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { AxisLeft } from '@visx/axis';
import { curveMonotoneX } from '@visx/curve';
import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { Circle, Line, LinePath } from '@visx/shape';
import { ScaleLinear } from 'd3-scale';
import { DateTime } from 'luxon';

import { Badge } from '@/components/data-representations/DailyGraph/Badge';
import { DataTooltip } from '@/components/data-representations/DailyGraph/DataTooltip';
import { GlycemiaDataPoint } from '@/components/data-representations/DailyGraph/types.ts';
import { Tooltip } from '@/components/floating/Tooltip';
import { DiabetesParameters } from '@/models/DiabetesDataModel';
import { toFixedIfNotZero } from '@/utils/math.ts';

export const glycemiaCircleRadius = 8;

type GlycemiaAxisProps = {
  parameters: DiabetesParameters;
  yScaleGlucose: ScaleLinear<number, number>;
  left?: number;
};

export const GlycemiaAxis = ({
  parameters,
  yScaleGlucose,
  left = 0,
}: GlycemiaAxisProps) => {
  const theme = useTheme();

  return (
    <AxisLeft
      hideAxisLine
      left={left}
      scale={yScaleGlucose}
      tickValues={[
        parameters.thresholdHypoglycemia,
        parameters.thresholdHyperglycemia,
      ]}
      hideTicks
      tickLabelProps={() => ({
        dx: '0.25em',
        dy: '0.25em',
        fill: theme.palette.glycemia.normal.main,
        fontSize: 14,
        textAnchor: 'end',
      })}
    />
  );
};

type GlycemiaThresholdsProps = {
  parameters: DiabetesParameters;
  yScaleGlucose: ScaleLinear<number, number>;
  xScale: ScaleLinear<number, number>;
  left?: number;
};

export const GlycemiaThresholds = ({
  parameters,
  xScale,
  yScaleGlucose,
  left = 0,
}: GlycemiaThresholdsProps) => {
  const data = {
    height:
      parameters && yScaleGlucose
        ? yScaleGlucose(parameters.thresholdHypoglycemia) -
          yScaleGlucose(parameters.thresholdHyperglycemia)
        : 0,
  };
  return (
    <>
      <Group top={yScaleGlucose(parameters.thresholdHyperglycemia)} left={left}>
        <rect
          width={xScale(24)}
          height={data.height}
          fill="rgba(0, 209, 133, 0.1)"
        />
      </Group>
    </>
  );
};

export type GlycemiaDataPointsProps = {
  parameters: DiabetesParameters;
  glycemiaReader: GlycemiaDataPoint[];
  glycemiaManual: GlycemiaDataPoint[];
  xScale: ScaleLinear<number, number>;
  yScaleGlucose: ScaleLinear<number, number>;
  getHour: (date: string | DateTime) => number;
  left?: number;
  className?: string;
  glycemiaRadius?: number;
  small: boolean;
};

export const GlycemiaDataPoints = ({
  parameters,
  glycemiaReader,
  glycemiaManual,
  xScale,
  yScaleGlucose,
  getHour,
  left = 0,
  className = '',
  glycemiaRadius = glycemiaCircleRadius,
  small,
}: GlycemiaDataPointsProps) => {
  const theme = useTheme();
  // TEMPORARY MOCK, WAITING NOTIFICATIONS
  //ugly hack
  const glycemiaType = [
    ...Array(glycemiaReader.length).fill('Lecteur'),
    ...Array(glycemiaManual.length).fill('Manuel'),
  ];
  const glycemia = [...glycemiaReader, ...glycemiaManual];

  const data = glycemia.map(datum => ({
    cy: yScaleGlucose(datum.value),
  }));
  return (
    <Group>
      {data.map(({ cy }, i) => {
        const cx = xScale(getHour(glycemia[i].date));
        const dataPoint = (
          <Group className={className} key={i} left={left}>
            {small ? (
              <circle
                r={glycemiaRadius}
                cy={cy}
                cx={glycemiaRadius + cx} // begin circle at cx (not middle circle at cx)
                fill={
                  glycemia[i].value > parameters.thresholdHyperglycemiaSevere
                    ? theme.palette.glycemia.severeHyperglycemia.light
                    : glycemia[i].value > parameters.thresholdHyperglycemia
                      ? theme.palette.glycemia.hyperglycemia.light
                      : glycemia[i].value <
                          parameters.thresholdHypoglycemiaSevere
                        ? theme.palette.glycemia.severeHypoglycemia.light
                        : glycemia[i].value < parameters.thresholdHypoglycemia
                          ? theme.palette.glycemia.hypoglycemia.light
                          : theme.palette.glycemia.normal.light
                }
              />
            ) : (
              <Badge
                key={i}
                text={`${Math.round(glycemia[i].value * 100) / 100}`}
                Icon={null}
                top={cy - 12}
                left={cx}
                width={40}
                color={
                  glycemia[i].value > parameters.thresholdHyperglycemiaSevere
                    ? theme.palette.glycemia.severeHyperglycemia
                    : glycemia[i].value > parameters.thresholdHyperglycemia
                      ? theme.palette.glycemia.hyperglycemia
                      : glycemia[i].value <
                          parameters.thresholdHypoglycemiaSevere
                        ? theme.palette.glycemia.severeHypoglycemia
                        : glycemia[i].value < parameters.thresholdHypoglycemia
                          ? theme.palette.glycemia.hypoglycemia
                          : theme.palette.glycemia.normal
                }
              />
            )}
          </Group>
        );

        return (
          <Tooltip
            key={i}
            svg
            placement="top"
            content={
              <DataTooltip
                title={`${toFixedIfNotZero(glycemia[i].value)} mg/dL`}
                content={'Glucose'}
                date={glycemia[i].date}
                dataInputMethod={glycemiaType[i]}
              />
            }
          >
            {dataPoint}
          </Tooltip>
        );
      })}
    </Group>
  );
};

type GlycemiaCurveProps = {
  parameters: DiabetesParameters;
  glycemiaGroups: GlycemiaDataPoint[][];
  xScale: ScaleLinear<number, number>;
  yScaleGlucose: ScaleLinear<number, number>;
  getHour: (date: string | DateTime) => number;
  marginLeft?: number;
};

const GlycemiaCurve = ({
  parameters,
  glycemiaGroups,
  xScale,
  yScaleGlucose,
  getHour,
  marginLeft = 0,
}: GlycemiaCurveProps) => {
  const theme = useTheme();
  const range = yScaleGlucose.range();

  const offset_severe_hyper =
    (yScaleGlucose(parameters.thresholdHyperglycemiaSevere) - range[0]) /
    (range[1] - range[0]);
  const offset_hyper =
    (yScaleGlucose(parameters.thresholdHyperglycemia) - range[0]) /
    (range[1] - range[0]);
  const offset_hypo =
    (yScaleGlucose(parameters.thresholdHypoglycemia) - range[0]) /
    (range[1] - range[0]);
  const offset_severe_hypo =
    (yScaleGlucose(parameters.thresholdHypoglycemiaSevere) - range[0]) /
    (range[1] - range[0]);

  const colors = {
    severeHyper: theme.palette.glycemia.severeHyperglycemia.light,
    hyper: theme.palette.glycemia.hyperglycemia.light,
    normal: theme.palette.glycemia.normal.light,
    hypo: theme.palette.glycemia.hypoglycemia.light,
    severeHypo: theme.palette.glycemia.severeHypoglycemia.light,
  };

  const valueToColor = (value: number) => {
    if (value > parameters.thresholdHyperglycemiaSevere) {
      return colors.severeHyper;
    } else if (value > parameters.thresholdHyperglycemia) {
      return colors.hyper;
    } else if (value < parameters.thresholdHypoglycemiaSevere) {
      return colors.severeHypo;
    } else if (value < parameters.thresholdHypoglycemia) {
      return colors.hypo;
    } else {
      return colors.normal;
    }
  };

  const id = Math.random().toString(36).substring(2);
  return (
    <Group>
      <defs>
        <linearGradient
          id={`linear-gradient-glycemia-${id}`}
          x1={0}
          x2={0}
          y1={range[0]}
          y2={range[1]}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={offset_severe_hyper} stopColor={colors.severeHyper} />
          <stop offset={offset_severe_hyper} stopColor={colors.hyper} />
          <stop offset={offset_hyper} stopColor={colors.hyper} />
          <stop offset={offset_hyper} stopColor={colors.normal} />
          <stop offset={offset_hypo} stopColor={colors.normal} />
          <stop offset={offset_hypo} stopColor={colors.hypo} />
          <stop offset={offset_severe_hypo} stopColor={colors.hypo} />
          <stop offset={offset_severe_hypo} stopColor={colors.severeHypo} />
        </linearGradient>
      </defs>
      {glycemiaGroups.map((glycemiaGroup, idx) => (
        <GlycemiaCurveLine
          glycemiaGroup={glycemiaGroup}
          xScale={xScale}
          yScaleGlucose={yScaleGlucose}
          getHour={getHour}
          id={id}
          key={idx}
          marginLeft={marginLeft}
          valueToColor={valueToColor}
        />
      ))}
    </Group>
  );
};

const findNearestPoint = (
  xScale: ScaleLinear<number, number>,
  x: number,
  glycemiaGroup: GlycemiaDataPoint[],
  getHour: (date: string | DateTime) => number,
  marginLeft: number,
) => {
  // TODO get x from event
  const hourFromX = Math.max(0, xScale.invert(x - marginLeft)); // x0 must be greater than 0
  const dataHours = glycemiaGroup.map(d => getHour(d.date));
  let index = dataHours.findIndex(hour => hourFromX < hour);

  //edge case
  index = index === 0 ? 1 : index;
  index = index === -1 ? dataHours.length - 1 : index;

  const prevPoint = glycemiaGroup[index - 1];
  const nextPoint = glycemiaGroup[index];

  let nearestPoint = prevPoint;
  if (nextPoint && nextPoint.date && prevPoint) {
    nearestPoint =
      hourFromX - getHour(prevPoint.date) > getHour(nextPoint.date) - hourFromX
        ? nextPoint
        : prevPoint;
  }
  return nearestPoint ?? nextPoint;
};

type GlycemiaCurveLineProps = {
  id: string;
  glycemiaGroup: GlycemiaDataPoint[];
  xScale: ScaleLinear<number, number>;
  yScaleGlucose: ScaleLinear<number, number>;
  getHour: (date: string | DateTime) => number;
  marginLeft: number;
  valueToColor: (value: number) => string;
};

const GlycemiaCurveLine: React.FC<GlycemiaCurveLineProps> = ({
  id,
  glycemiaGroup,
  xScale,
  yScaleGlucose,
  getHour,
  marginLeft,
  valueToColor,
}) => {
  const theme = useTheme();

  const [xMousePosition, setXMousePosition] = React.useState<
    number | undefined
  >(undefined);
  const nearestDataPoint =
    xMousePosition !== undefined
      ? findNearestPoint(
          xScale,
          xMousePosition,
          glycemiaGroup,
          getHour,
          marginLeft,
        )
      : undefined;
  const xStart = xScale(Math.min(...glycemiaGroup.map(d => getHour(d.date))));
  const xEnd = xScale(Math.max(...glycemiaGroup.map(d => getHour(d.date))));
  const yEnd = yScaleGlucose.range()[1];

  const nearestDataPointX = nearestDataPoint
    ? xScale(getHour(nearestDataPoint.date))
    : undefined;
  const nearestDataPointY = nearestDataPoint
    ? yScaleGlucose(nearestDataPoint.value)
    : undefined;

  const line = (
    <Group
      pointerEvents="all"
      className={css`
        .mouse-info {
          visibility: hidden;
        }
        :hover {
          .mouse-info {
            visibility: visible;
          }
        }
      `}
    >
      <rect
        height={yEnd}
        width={xEnd - xStart}
        y={0}
        x={xStart}
        fill="transparent"
      />
      <LinePath
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth={2}
        curve={curveMonotoneX}
        data={glycemiaGroup}
        x={dataPoint => xScale(getHour(dataPoint.date))}
        y={dataPoint => yScaleGlucose(dataPoint.value)}
        stroke={`url(#linear-gradient-glycemia-${id})`}
      />
      {nearestDataPoint ? (
        <>
          <Line
            className="mouse-info"
            from={{ x: nearestDataPointX, y: 0 }}
            to={{ x: nearestDataPointX, y: yEnd }}
            fill={theme.palette.grey[300]}
            stroke={theme.palette.grey[300]}
          />

          <Circle
            className={cx(
              'mouse-info',
              css`
                color: ${valueToColor(nearestDataPoint.value)};
                fill: currentColor;
                stroke: currentColor;
              `,
            )}
            cx={nearestDataPointX}
            cy={nearestDataPointY}
            r={4}
          />
        </>
      ) : null}
    </Group>
  );

  return (
    <Tooltip
      placement="top"
      svg
      clientPoint={{
        enabled: true,
        x: (nearestDataPointX ?? 0) - xStart,
        y: 0,
      }}
      relativeClientPoint
      offset={0}
      onMouseMove={e => {
        setXMousePosition(localPoint(e)?.x ?? 0);
      }}
      content={
        nearestDataPoint ? (
          <DataTooltip
            title={`${toFixedIfNotZero(nearestDataPoint.value)} mg/dL`}
            content={'Glucose'}
            date={nearestDataPoint.date}
            dataInputMethod={'Capteur'}
          />
        ) : null
      }
    >
      {line}
    </Tooltip>
  );
};

type GlycemiaElementsProps = {
  parameters?: DiabetesParameters;
  xScale: ScaleLinear<number, number, never>;
  yScaleGlucose?: ScaleLinear<number, number>;
  glycemiaManual: GlycemiaDataPoint[];
  glycemiaReader: GlycemiaDataPoint[];
  glycemiaSensor: GlycemiaDataPoint[][];
  getHour: (date: string | DateTime) => number;
  marginLeft?: number;
  datapointsProps?: {
    [P in keyof GlycemiaDataPointsProps]?: GlycemiaDataPointsProps[P];
  };
  small: boolean;
};

export const GlycemiaElements = ({
  parameters,
  xScale,
  yScaleGlucose,
  glycemiaManual,
  glycemiaReader,
  glycemiaSensor,
  getHour,
  marginLeft = 0,
  datapointsProps = {} as GlycemiaDataPointsProps,
  small,
}: GlycemiaElementsProps) => {
  return (
    <React.Fragment>
      {parameters && yScaleGlucose && (
        <GlycemiaAxis
          {...{
            parameters,
            yScaleGlucose,
          }}
        />
      )}

      {parameters && yScaleGlucose && (
        <GlycemiaThresholds
          {...{
            parameters,
            xScale,
            yScaleGlucose,
          }}
        />
      )}

      {parameters && glycemiaSensor && yScaleGlucose && (
        <GlycemiaCurve
          {...{
            parameters,
            glycemiaGroups: glycemiaSensor,
            xScale,
            yScaleGlucose,
            getHour,
            marginLeft,
          }}
        />
      )}

      {parameters && glycemiaManual && glycemiaReader && yScaleGlucose && (
        <GlycemiaDataPoints
          {...{
            ...datapointsProps,
            parameters,
            glycemiaManual,
            glycemiaReader,
            xScale,
            yScaleGlucose,
            getHour,
            small,
          }}
        />
      )}
    </React.Fragment>
  );
};

import { DiabetesParameters } from '@/models/DiabetesDataModel.ts';

export type GestationalThresholds = {
  beforeBreakfast: DiabetesParameters;
  beforeMeal: DiabetesParameters;
  afterMeal: DiabetesParameters;
};

export const gestationalThresholds = {
  beforeBreakfast: {
    id: 'before breakfast',
    thresholdHyperglycemiaSevere: 105,
    thresholdHyperglycemia: 94,
    thresholdHypoglycemia: 62,
    thresholdHypoglycemiaSevere: 0,
  },
  beforeMeal: {
    id: 'before meal',
    thresholdHyperglycemiaSevere: 105,
    thresholdHyperglycemia: 94,
    thresholdHypoglycemia: 62,
    thresholdHypoglycemiaSevere: 0,
  },
  afterMeal: {
    id: 'after meal',
    thresholdHyperglycemiaSevere: 130,
    thresholdHyperglycemia: 119,
    thresholdHypoglycemia: 62,
    thresholdHypoglycemiaSevere: 0,
  },
};

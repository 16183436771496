import { PrescriptionRepository } from '@/io/repository/PrescriptionRepository';
import { GeneratePrescriptionData } from '@/models/PrescriptionGenerationModel';
import { stripQueryResult } from '@/queries/Queries.ts';
import { createMutation } from '@/queries/utils/CreateMutation.ts';

export class PrescriptionQueries {
  constructor(
    private readonly prescription: PrescriptionRepository = new PrescriptionRepository(),
  ) {}

  /*******************************/
  /*********** Mutation ***********/
  /*******************************/

  useGeneratePrescription = createMutation(
    'generate-prescription',
    async (data: GeneratePrescriptionData) =>
      stripQueryResult(await this.prescription.generatePrescription(data)),
  );

  useSignPrescription = createMutation(
    'sign-prescription',
    async (data: GeneratePrescriptionData) =>
      stripQueryResult(await this.prescription.signPrescription(data)),
  );
}
